import React from 'react'
import { BarChart } from "@mui/x-charts/BarChart";
import { Link } from 'react-router-dom';
import { Button } from '@mui/material';

export const CertifiedYouthsBarCharts = ({maleCount, femaleCount}) => {
    if (maleCount === 0 && femaleCount === 0) {
        return (
          <div>
            Nothing to display, proceed to enter data in{" "}
            <Link to="/beneficiaries-data">
              <Button sx={{ textTransform: "capitalize" }}>here</Button>
            </Link>
          </div>
        );
      }
      return (
        <BarChart
          series={[
            {
              data: [maleCount],
              color: "#CFF0B5",
              label: "Male",
              barWidth: 0.2,
            },
            {
              data: [femaleCount],
              color: "#BC5645",
              label: "Female",
              barWidth: 0.2,
            },
          ]}
          height={300}
          width={250}
          xAxis={[{ data: ["Gender"], scaleType: "band" }]}
          margin={{ top: 70, bottom: 30, left: 60, right: 10 }}
          barLabel="value"
          borderRadius={10}
        />
      );
}

export const EnrolledTraineeInFedTCBarCharts = ({maleCount, femaleCount}) => {
    if (maleCount === 0 && femaleCount === 0) {
        return (
          <div>
            Nothing to display, proceed to enter data in{" "}
            <Link to="/beneficiaries-data">
              <Button sx={{ textTransform: "capitalize" }}>here</Button>
            </Link>
          </div>
        );
      }
      return (
        <BarChart
          series={[
            {
              data: [maleCount],
              color: "#CFF0B5",
              label: "Male",
              barWidth: 0.2,
            },
            {
              data: [femaleCount],
              color: "#BC5645",
              label: "Female",
              barWidth: 0.2,
            },
          ]}
          height={300}
          width={250}
          xAxis={[{ data: ["Gender"], scaleType: "band" }]}
          margin={{ top: 70, bottom: 30, left: 60, right: 10 }}
          barLabel="value"
          borderRadius={10}
        />
      );
}
export const EnrolledTraineeInStateTCBarCharts = ({maleCount, femaleCount}) => {
    if (maleCount === 0 && femaleCount === 0) {
        return (
          <div>
            Nothing to display, proceed to enter data in{" "}
            <Link to="/beneficiaries-data">
              <Button sx={{ textTransform: "capitalize" }}>here</Button>
            </Link>
          </div>
        );
      }
      return (
        <BarChart
          series={[
            {
              data: [maleCount],
              color: "#CFF0B5",
              label: "Male",
              barWidth: 0.2,
            },
            {
              data: [femaleCount],
              color: "#BC5645",
              label: "Female",
              barWidth: 0.2,
            },
          ]}
          height={300}
          width={250}
          xAxis={[{ data: ["Gender"], scaleType: "band" }]}
          margin={{ top: 70, bottom: 30, left: 60, right: 10 }}
          barLabel="value"
          borderRadius={10}
        />
      );
}
export const GraduatedTraineeInFedTCBarCharts = ({maleCount, femaleCount}) => {
    if (maleCount === 0 && femaleCount === 0) {
        return (
          <div>
            Nothing to display, proceed to enter data in{" "}
            <Link to="/beneficiaries-data">
              <Button sx={{ textTransform: "capitalize" }}>here</Button>
            </Link>
          </div>
        );
      }
      return (
        <BarChart
          series={[
            {
              data: [maleCount],
              color: "#CFF0B5",
              label: "Male",
              barWidth: 0.2,
            },
            {
              data: [femaleCount],
              color: "#BC5645",
              label: "Female",
              barWidth: 0.2,
            },
          ]}
          height={300}
          width={250}
          xAxis={[{ data: ["Gender"], scaleType: "band" }]}
          margin={{ top: 70, bottom: 30, left: 60, right: 10 }}
          barLabel="value"
          borderRadius={10}
        />
      );
}
export const GraduatedTraineeInStateTCBarCharts = ({maleCount, femaleCount}) => {
    if (maleCount === 0 && femaleCount === 0) {
        return (
          <div>
            Nothing to display, proceed to enter data in{" "}
            <Link to="/beneficiaries-data">
              <Button sx={{ textTransform: "capitalize" }}>here</Button>
            </Link>
          </div>
        );
      }
      return (
        <BarChart
          series={[
            {
              data: [maleCount],
              color: "#CFF0B5",
              label: "Male",
              barWidth: 0.2,
            },
            {
              data: [femaleCount],
              color: "#BC5645",
              label: "Female",
              barWidth: 0.2,
            },
          ]}
          height={300}
          width={250}
          xAxis={[{ data: ["Gender"], scaleType: "band" }]}
          margin={{ top: 70, bottom: 30, left: 60, right: 10 }}
          barLabel="value"
          borderRadius={10}
        />
      );
}
export const McpsAssessors = ({maleCount, femaleCount}) => {
    if (maleCount === 0 && femaleCount === 0) {
        return (
          <div>
            Nothing to display, proceed to enter data in{" "}
            <Link to="/beneficiaries-data">
              <Button sx={{ textTransform: "capitalize" }}>here</Button>
            </Link>
          </div>
        );
      }
      return (
        <BarChart
          series={[
            {
              data: [maleCount],
              color: "#CFF0B5",
              label: "Male",
              barWidth: 0.2,
            },
            {
              data: [femaleCount],
              color: "#BC5645",
              label: "Female",
              barWidth: 0.2,
            },
          ]}
          height={300}
          width={250}
          xAxis={[{ data: ["Gender"], scaleType: "band" }]}
          margin={{ top: 70, bottom: 30, left: 60, right: 10 }}
          barLabel="value"
          borderRadius={10}
        />
      );
}
export const McpsVerifiers = ({maleCount, femaleCount}) => {
    if (maleCount === 0 && femaleCount === 0) {
        return (
          <div>
            Nothing to display, proceed to enter data in{" "}
            <Link to="/beneficiaries-data">
              <Button sx={{ textTransform: "capitalize" }}>here</Button>
            </Link>
          </div>
        );
      }
      return (
        <BarChart
          series={[
            {
              data: [maleCount],
              color: "#CFF0B5",
              label: "Male",
              barWidth: 0.2,
            },
            {
              data: [femaleCount],
              color: "#BC5645",
              label: "Female",
              barWidth: 0.2,
            },
          ]}
          height={300}
          width={250}
          xAxis={[{ data: ["Gender"], scaleType: "band" }]}
          margin={{ top: 70, bottom: 30, left: 60, right: 10 }}
          barLabel="value"
          borderRadius={10}
        />
      );
}

