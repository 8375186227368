import { Button } from "@mui/material";
import { Link } from "react-router-dom";

const ShowError = () => {
  return (
    <div>
      No data to display, proceed to enter data{" "}
      <Link to="/beneficiaries-data">
        <Button sx={{ textTransform: "capitalize" }}>here</Button>
      </Link>
    </div>
  );
};

export default ShowError;
