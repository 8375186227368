export const pdoHeaders = [
  {
    id: 1,
    title:
      "Number of youths obtaining recognized skills certification after completing an informal apprenticeship ",
  },
  {
    id: 2,
    title: "Beneficiaries Of Job-Focuses Interventions",
  },
];
export const pdoFormHeaders = [
  {
    id: 1,
    title:
      "Number of youths obtaining recognized skills certification after completing an informal apprenticeship (disaggregated by gender)",
  },
  
  {
    id: 2,
    title: "Beneficiaries Of Job-Focuses Interventions",
  },
];
