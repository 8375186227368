import React from "react";
import PageHeader from "../../Components/page_header";
import CertifiedYouthsCard from "./CardComponents/CertifiedYouths";
import {
  TraineesEnrolledFedTCCard,
  TraineesEnrolledStateTCCard,
} from "./CardComponents/TraineesEnrolled";
import {
  TraineesGraduatedFedTCCard,
  TraineesGraduatedStateTCCard,
} from "./CardComponents/TraineesGraduated";
import {
  MasterCraftPersonAssessorCard,
  MasterCraftPersonVerifierCard,
} from "./CardComponents/MasterCraftPerson";
import {
  CertifiedYouthsBarCharts,
  EnrolledTraineedFedTCBarCharts,
  EnrolledTraineeInFedTCBarCharts,
  EnrolledTraineeInStateTCBarCharts,
  GraduatedTraineeInFedTCBarCharts,
  GraduatedTraineeInStateTCBarCharts,
  McpsAssessors,
  McpsVerifiers,
} from "./Charts/BarCharts";
import { CertifiedYouthsDoughnutChartDisplay } from "./Charts/Donut";
import { useQuery } from "@tanstack/react-query";
import {
  fetchComponent2Pdo1Data,
  fetchComponent2Pdo2Data,
} from "../../utils/apiContext";
import { useLocation } from "react-router-dom";

const PMUAnalyticsDisplay = () => {
  const location = useLocation();
  const {
    data: allC2Pdo1Data,
    isLoading: pdo1C2IsLoading,
    error: pdo1C2Error,
  } = useQuery({
    queryKey: ["pdo1C2"],
    queryFn: () => fetchComponent2Pdo1Data(),
  });

  const {
    data: allC2Pdo2Data,
    isLoading: pdo2C2IsLoading,
    error: pdo2C2Error,
  } = useQuery({
    queryKey: ["pdo2C2"],
    queryFn: () => fetchComponent2Pdo2Data(),
  });

  const certifiedYouths = allC2Pdo1Data?.subDoc_aggreagte;
  const enrolledTrainees =
    allC2Pdo2Data?.aggr_sum_data?.trainees_in_technical_colleges?.enrolled;
  const graduatedTrainees =
    allC2Pdo2Data?.aggr_sum_data?.trainees_in_technical_colleges?.graduated;
  const mcpCertification = allC2Pdo2Data?.aggr_sum_data?.master_craft_person;
  const sumTrainees =
    allC2Pdo2Data?.aggr_micro_sum_data?.trainees_in_technical_colleges;
  const sumMcpCertification =
    allC2Pdo2Data?.aggr_micro_sum_data?.master_craft_person;

  return (
    <div>
      {location.pathname === "/pmu/analytics" && (
        <PageHeader title="Analytics Dashboard" />
      )}

      <div className="px-4">
        <div className="grid grid-cols-4 mt-3 gap-3 ">
          <CertifiedYouthsCard
            totalYouths={allC2Pdo1Data?.subDoc_aggreagte_sum}
          />
          <TraineesEnrolledFedTCCard
            totalTraineesEnrolledInFedTC={sumTrainees?.enrolled?.federal}
          />
          <TraineesEnrolledStateTCCard
            totalTraineesEnrolledInStateTC={sumTrainees?.enrolled?.state}
          />
          <TraineesGraduatedFedTCCard
            totalTraineesGraduatedFedTC={sumTrainees?.graduated?.federal}
          />
          <TraineesGraduatedStateTCCard
            totalTraineesGraduatedStateTC={sumTrainees?.graduated?.state}
          />
          <MasterCraftPersonAssessorCard
            totalAssessors={sumMcpCertification?.assessor}
          />
          <MasterCraftPersonVerifierCard
            totalVerifiers={sumMcpCertification?.verifiers}
          />
        </div>
        <br />

        <div className="grid grid-cols-2 gap-x-3">
          <div className="custom-shadow flex flex-col items-center bg-white px-4 py-4 rounded-lg">
            <h1
              className="text-primary_text font-bold"
              style={{ fontFamily: "Poppins, sans-serif" }}
            >
              Certified Youths after Informal Apprenticeship Training
            </h1>
            <CertifiedYouthsBarCharts
              maleCount={certifiedYouths?.male}
              femaleCount={certifiedYouths?.female}
            />
          </div>

          <div className="custom-shadow flex flex-col items-center bg-white px-4 py-4 rounded-lg">
            <h1
              className="text-primary_text font-bold"
              style={{ fontFamily: "Poppins, sans-serif" }}
            >
              Percentage of Certified Youths after Informal Apprenticeship
              Training
            </h1>
            <CertifiedYouthsDoughnutChartDisplay
              data={allC2Pdo1Data?.aggr_percentage}
            />
          </div>
        </div>
        <br />
        <div className="grid grid-cols-2 gap-3">
          {/* Enrolled Trainees */}
          <div className="custom-shadow flex flex-col items-center bg-white px-4 py-4 rounded-lg">
            <h1
              className="text-primary_text font-bold"
              style={{ fontFamily: "Poppins, sans-serif" }}
            >
              Trainees Enrolled in private-sector programs at Federal Technical
              Colleges.
            </h1>
            <EnrolledTraineeInFedTCBarCharts
              maleCount={enrolledTrainees?.federal?.male}
              femaleCount={enrolledTrainees?.federal?.female}
            />
          </div>
          <div className="custom-shadow flex flex-col items-center bg-white px-4 py-4 rounded-lg">
            <h1
              className="text-primary_text font-bold"
              style={{ fontFamily: "Poppins, sans-serif" }}
            >
              Trainees Enrolled in private-sector programs at State Technical
              Colleges.
            </h1>
            <EnrolledTraineeInStateTCBarCharts
              maleCount={enrolledTrainees?.state?.male}
              femaleCount={enrolledTrainees?.state?.female}
            />
          </div>
          {/* Graduated Trainees */}
          <div className="custom-shadow flex flex-col items-center bg-white px-4 py-4 rounded-lg">
            <h1
              className="text-primary_text font-bold"
              style={{ fontFamily: "Poppins, sans-serif" }}
            >
              Trainees Graduated in private-sector programs at Federal Technical
              Colleges.
            </h1>
            <GraduatedTraineeInFedTCBarCharts
              maleCount={graduatedTrainees?.federal?.male}
              femaleCount={graduatedTrainees?.federal?.female}
            />
          </div>
          <div className="custom-shadow flex flex-col items-center bg-white px-4 py-4 rounded-lg">
            <h1
              className="text-primary_text font-bold"
              style={{ fontFamily: "Poppins, sans-serif" }}
            >
              Trainees Graduated in private-sector programs at State Technical
              Colleges.
            </h1>
            <GraduatedTraineeInStateTCBarCharts
              maleCount={graduatedTrainees?.state?.male}
              femaleCount={graduatedTrainees?.state?.female}
            />
          </div>
          <div className="custom-shadow flex flex-col items-center bg-white px-4 py-4 rounded-lg">
            <h1
              className="text-primary_text font-bold"
              style={{ fontFamily: "Poppins, sans-serif" }}
            >
              Master Craft Persons certified as Assessors.
            </h1>
            <McpsAssessors
              maleCount={mcpCertification?.assessor?.male}
              femaleCount={mcpCertification?.assessor?.female}
            />
          </div>
          <div className="custom-shadow flex flex-col items-center bg-white px-4 py-4 rounded-lg">
            <h1
              className="text-primary_text font-bold"
              style={{ fontFamily: "Poppins, sans-serif" }}
            >
              Master Craft Persons certified as Verifiers.
            </h1>
            <McpsVerifiers
              maleCount={mcpCertification?.verifiers?.male}
              femaleCount={mcpCertification?.verifiers?.female}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PMUAnalyticsDisplay;
