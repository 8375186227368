export const federalTcNames = [
    { value: "", label: "Select school" },
  { value: "federal science and technical college, doma", label: "Federal Science And Technical College, Doma" },
  { value: "federal science and technical college, awka", label: "Federal Science And Technical College, Awka" },
  { value: "federal science and technical college, orozo", label: "Federal Science and Technical College, Orozo" },
  { value: "federal science and technical college, zuru", label: "Federal Science And Technical College, Zuru" },
  { value: "federal science and technical college, kuta shiroro", label: "Federal Science And Technical College, Kuta Shiroro" },
  { value: "federal science and technical college, Uyo", label: "Federal Science And Technical College, Uyo" },
  { value: "federal science and technical college, ikare", label: "Federal Science And Technical College, Ikare" },
  { value: "federal science and technical college, dayi", label: "Federal Science And Technical College, Dayi" },
  { value: "federal science and technical college, ahoada", label: "Federal Science And Technical College, Ahoada" },
  { value: "federal science and technical college, ijebu-imusin", label: "Federal Science And Technical College, Ijebu-imusin" },
  { value: "federal science and technical college, kafanchan", label: "Federal Science And Technical College, Kafanchan" },
  { value: "federal science and technical college, otukpo", label: "Federal Science And Technical College, Otukpo" },
  { value: "federal science and technical college, ilesa", label: "Federal Science And Technical College, Ilesa" },
  { value: "federal science and technical college, usi-ekiti", label: "Federal Science And Technical College, Usi-Ekiti" },
  { value: "federal science and technical college, yaba", label: "Federal Science And Technical College, Yaba" },
  { value: "federal science and technical college, jalingo", label: "Federal Science And Technical College, Jalingo" },
  { value: "federal science and technical college, michika", label: "Federal Science And Technical College, Michika" },
  { value: "federal science and technical college, uromi", label: "Federal Science And Technical College, Uromi" },
  { value: "federal science and technical college, tungbo", label: "Federal Science And Technical College, Tungbo" },
  { value: "federal science and technical college, ohanso", label: "Federal Science And Technical College, Ohanso" },
  ];
  
  export const stateTcNames = [
    { value: "", label: "Select school" },
    { value: "government technical college, bagauda", label: "Government Technical College, Bagauda" },
    { value: "government technical college, ungogo", label: "Government Technical College, Ungogo" },
    { value: "government technical college, dambatta", label: "Government Technical College, Dambatta" },
    { value: "government technical college, gombe", label: "Government Technical College, Gombe" },
    { value: "government technical college, kumo", label: "Government Technical College, Kumo" },
    { value: "government technical college, tula", label: "Government Technical College, Tula" },
    { value: "government technical college, otukpa", label: "Government Technical College, Otukpa" },
    { value: "government technical college, gbajimba", label: "Government Technical College, Gbajimba" },
    { value: "government technical college, zaki-biam", label: "Government Technical College, Zaki-Biam" },
    { value: "government technical college, ania-ohafia", label: "Government Technical College, Ania-Ohafia" },
    { value: "government technical college, osusu-aba", label: "Government Technical College, Osusu-Aba" },
    { value: "government technical college, afaraukwu", label: "Government Technical College, Afaraukwu" },
    { value: "government technical college, otun-ekiti", label: "Government Technical College, Otun-Ekiti" },
    { value: "government technical college, igbara-odo", label: "Government Technical College, Igbara-Odo" },
    { value: "government technical college, ijero-ekiti", label: "Government Technical College, Ijero-Ekiti" },
    { value: "government technical college, benin", label: "Government Technical College, Benin" },
    { value: "government technical college, osugbenu-irrua", label: "Government Technical College, Osugbenu-Irrua" },
    { value: "government technical college, igarra", label: "Government Technical College, Igarra" },
  ];
  