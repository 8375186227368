import { useEffect, useState } from "react";

function App() {
  const [isDesktop, setIsDesktop] = useState(window.innerWidth >= 1024);

  useEffect(() => {
    const handleResize = () => {
      console.log("Window width:", window.innerWidth);
      setIsDesktop(window.innerWidth >= 1024);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const handleWheel = (event) => {
      if (document.activeElement.type === "number") {
        event.preventDefault();
      }
    };

    document.addEventListener("wheel", handleWheel, { passive: false });

    return () => {
      document.removeEventListener("wheel", handleWheel);
    };
  }, []);

  

  if (!isDesktop) {
    return (
      <div className="flex items-center justify-center h-screen text-center">
        <h1 className="text-xl font-semibold">
          Please use a desktop to view this application.
        </h1>
      </div>
    );
  }

  return null;
}

export default App;
