import React, { useState } from "react";
import StudentCard from "../../../Components/BeneficiariesCards/Student";
import TcCard from "../../../Components/BeneficiariesCards/Tc";
import PageHeader from "../../../Components/page_header";
import ModalTcForm from "../../../Components/Modalformsbeneficiaries/TcForm";
import ModalStudentForm from "../../../Components/Modalformsbeneficiaries/StudentForm";
import TeachersCard from "../../../Components/BeneficiariesCards/Teachers";
import ModalTeacherForm from "../../../Components/Modalformsbeneficiaries/TeacherForm";
import CrossCuttingCard from "../../../Components/BeneficiariesCards/CrossCuttingCard";
import ModalCrossCuttingIssuesForm from "../../../Components/Modalformsbeneficiaries/CrossCuttingIssues";
import GeneralActivitiesCard from "../../../Components/BeneficiariesCards/GeneralActivitiesCard";
import ModalGeneralActivitiesForm from "../../../Components/Modalformsbeneficiaries/GeneralActivitiesForm";
import ModalSummaryProjectForm from "../../../Components/Modalformsbeneficiaries/SummaryProjectForm";
import SummaryProjectCard from "../../../Components/BeneficiariesCards/SummaryProjectCard";
import { useQueries, useQuery } from "@tanstack/react-query";
import {
  fetchAllTechnicalCollegePosts,
  fetchTechnicalCollegePostById,
} from "../../../utils/apiContext";
import {
  selectCurrentJurisdiction,
  selectCurrentTcName,
  selectCurrentToken,
  selectCurrentUserRole,
} from "../../../features/auth/authSlice";
import { useSelector } from "react-redux";
import { getTcName } from "../../../utils/getFormattedToken";
import { toast } from "sonner";
import {
  selectIoi1Post,
  selectTcPost,
} from "../../../features/auth/isPostMade";
import Component2BeneficiariesPage from "../../Component2/BeneficiariesPage";

const BeneficiariesPage = () => {
  const [modalType, setModalType] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const isTcPost = useSelector(selectTcPost);

  const openModal = (type) => {
    setModalType(type);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const currRole = useSelector(selectCurrentUserRole);

  return (
    <div className="">
      <PageHeader title="Beneficiaries Data" />
      {currRole === "Pmu" ? (
        <Component2BeneficiariesPage />
      ) : (
        <div className="flex justify-start gap-3 mx-3">
          {/* TC Card */}
          <TcCard
            openModal={() => openModal("tc")}
            isDataAvailable={isTcPost}
          />
          {/* Student Card */}
          <div>
            <StudentCard openModal={() => openModal("student")} />
            <TeachersCard openModal={() => openModal("teacher")} />
            <CrossCuttingCard
              openModal={() => openModal("cross-cutting issues")}
            />
            <GeneralActivitiesCard
              openModal={() => openModal("general activities")}
            />
            <SummaryProjectCard
              openModal={() => openModal("summary project card")}
            />
          </div>

          {/* Modal */}
          {isModalOpen && modalType === "tc" && (
            <ModalTcForm open={isModalOpen} handleClose={handleCloseModal} />
          )}
          {isModalOpen && modalType === "student" && (
            <ModalStudentForm
              open={isModalOpen}
              handleClose={handleCloseModal}
            />
          )}
          {isModalOpen && modalType === "teacher" && (
            <ModalTeacherForm
              open={isModalOpen}
              handleClose={handleCloseModal}
            />
          )}
          {isModalOpen && modalType === "cross-cutting issues" && (
            <ModalCrossCuttingIssuesForm
              open={isModalOpen}
              handleClose={handleCloseModal}
            />
          )}
          {isModalOpen && modalType === "general activities" && (
            <ModalGeneralActivitiesForm
              open={isModalOpen}
              handleClose={handleCloseModal}
            />
          )}
          {isModalOpen && modalType === "summary project card" && (
            <ModalSummaryProjectForm
              open={isModalOpen}
              handleClose={handleCloseModal}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default BeneficiariesPage;
