import React, { useState, useRef, useEffect } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { motion } from "framer-motion";
import { Grid, Typography } from "@mui/material";
import { FormInput, FormDropdown } from "../../../Components/form";
import { useMutation } from "@tanstack/react-query";
import { toast } from "sonner";
import axiosCreateUser from "../../../Services/axiosCreateUser";
import { useSelector } from "react-redux";
import { selectCurrentToken } from "../../../features/auth/authSlice";
import { stateOptions } from "../../../utils/stateOptions";

const jurisdictionOptions = [
  { value: "", label: "Select Jurisdiction" },
  { value: "federal", label: "Federal" },
  { value: "state", label: "State" },
];

const genderOptions = [
  { value: "", label: "Select Gender" },
  { value: "M", label: "Male" },
  { value: "F", label: "Female" },
  { value: "O", label: "Other" },
];

const titleOptions = [
  { value: "", label: "Select Title" },
  { value: "Mr", label: "MR" },
  { value: "Mrs", label: "MRS" },
  { value: "Miss", label: "MISS" },
  { value: "Ms", label: "MS" },
  { value: "Prof", label: "PROF" },
  { value: "Dr", label: "DR" },
  { value: "Hon", label: "HON" },
  { value: "Mx", label: "OTHER" },
];

const ReviewerComponent = ({ open, handleClose }) => {
  const modalRef = useRef(null);
  const token = useSelector(selectCurrentToken);

  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    user_lga: "",
    user_city: "",
    user_state: "",
    address: "",
    phone_number: "",
    gender: "",
    title: "",
    component_access: "COMPONENT1",
    jurisdiction: "",
    alloted_reviewer_permissions: [],
  });

  const mutation = useMutation({
    mutationFn: async (formData) => {
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      await axiosCreateUser.post(`/reviewer/create/`, formData, config);
    },
    onSuccess: () => {
      toast.success("Successful", { duration: 5000 });
      handleClose();
    },
    onError: (err) => {
      if (err.response && err.response.status === 400) {
        toast.error("Post already exists", { duration: 5000 });
      } else {
        toast.error("An error occurred", { duration: 5000 });
      }
    },
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleSelectChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    mutation.mutate(formData);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        handleClose();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [handleClose]);

  const modalVariants = {
    hidden: {
      y: "-100vh",
      opacity: 0,
    },
    visible: {
      y: "0",
      opacity: 1,
      transition: { delay: 0.2, type: "spring", stiffness: 120 },
    },
  };

  return (
    <div
      className={`fixed left-0 top-0 w-full h-full z-20 bg-black bg-opacity-50 flex justify-center items-center ${
        open ? "" : "hidden"
      }`}
    >
      <motion.div
        ref={modalRef}
        variants={modalVariants}
        initial="hidden"
        animate="visible"
        exit="hidden"
        className="scrollbar-hide overflow-auto w-[50%] h-[98vh] px-8 pt-8 pb-6 bg-white rounded-lg relative"
      >
        <div className="flex items-center font-bold relative">
          <Typography variant="h4" className="font-bold">
            Create Reviewer User
          </Typography>
          <CloseIcon
            onClick={handleClose}
            style={{ fontSize: "2rem" }}
            className="text-red-500 cursor-pointer absolute top-2 right-4"
          />
        </div>
        <hr className="mt-2 mb-4" />
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <FormInput
                fullWidth
                label="First Name"
                name="first_name"
                value={formData.first_name}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12}>
              <FormInput
                fullWidth
                label="Last Name"
                name="last_name"
                value={formData.last_name}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12}>
              <FormInput
                fullWidth
                label="Email"
                name="email"
                value={formData.email}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12}>
              <FormInput
                fullWidth
                label="LGA"
                name="user_lga"
                value={formData.user_lga}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12}>
              <FormInput
                fullWidth
                label="City"
                name="user_city"
                value={formData.user_city}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12}>
              <FormDropdown
                fullWidth
                label="State"
                name="user_state"
                value={formData.user_state}
                options={stateOptions}
                onChange={handleSelectChange}
              />
            </Grid>
            <Grid item xs={12}>
              <FormInput
                fullWidth
                label="Address"
                name="address"
                value={formData.address}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12}>
              <FormInput
                fullWidth
                label="Phone Number"
                name="phone_number"
                value={formData.phone_number}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12}>
              <FormDropdown
                fullWidth
                label="Title"
                name="title"
                value={formData.title}
                options={titleOptions}
                onChange={handleSelectChange}
              />
            </Grid>
            <Grid item xs={12}>
              <FormDropdown
                fullWidth
                label="Gender"
                name="gender"
                value={formData.gender}
                options={genderOptions}
                onChange={handleSelectChange}
              />
            </Grid>
            <Grid item xs={12}>
              <FormDropdown
                fullWidth
                label="Jurisdiction"
                name="jurisdiction"
                value={formData.jurisdiction}
                options={jurisdictionOptions}
                onChange={handleSelectChange}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography
                variant="body1"
                sx={{
                  padding: "12px",
                  border: "1px solid #ced4da",
                  borderRadius: "4px",
                  backgroundColor: "#e9ecef",
                  color: "#495057",
                  fontSize: "16px",
                }}
              >
                Component Access: {formData.component_access}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <div className="flex justify-center">
                <button
                  type="submit"
                  className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-active_bg text-base font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:w-auto sm:text-sm"
                >
                  Create Reviewer User
                </button>
              </div>
            </Grid>
          </Grid>
        </form>
      </motion.div>
    </div>
  );
};

export default ReviewerComponent;
