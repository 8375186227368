import { v4 as uuidv4 } from "uuid";
import {
  Speed,
  PeopleAlt,
  BarChart,
  AddLocation,
  CompassCalibration,
} from "@mui/icons-material";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import AssignmentTurnedInRoundedIcon from "@mui/icons-material/AssignmentTurnedInRounded";
import ImportContactsRoundedIcon from "@mui/icons-material/ImportContactsRounded";
import ContentPasteIcon from "@mui/icons-material/ContentPaste";
import VisibilityIcon from "@mui/icons-material/Visibility";

export const initialNavListItems = [
  {
    id: uuidv4(),
    icon: <BarChart />,
    text: "Analytics",
    path: "/analytics",
    roles: [
      "Super_Admin",
      "National_Admin",
      "Reviewer",
      "Validator",
      "M_E_OFFICE",
      "Pmu"
    ],
  },
  {
    id: uuidv4(),
    icon: <PeopleAlt />,
    text: "Beneficiaries Data",
    path: "/beneficiaries-data",
    roles: ["M_E_OFFICE", "Pmu"],
  },

  // {
  //   id: uuidv4(),
  //   icon: <CompassCalibration />,
  //   text: "Track",
  //   path: "/tracking",
  //   roles: [
  //     "Super_Admin",
  //     "National_Admin",
  //     "Reviewer",
  //     "Validator",
  //     "M_E_OFFICE",
  //   ],
  // },

  {
    id: uuidv4(),
    icon: <CheckCircleRoundedIcon />,
    text: "Validate",
    path: "/validate",
    roles: ["Validator"],
  },
  {
    id: uuidv4(),
    icon: <AssignmentTurnedInRoundedIcon />,
    text: "Review",
    path: "/review",
    roles: ["Reviewer"],
  },

  {
    id: uuidv4(),
    icon: <ImportContactsRoundedIcon />,
    text: "Overview",
    path: "/overview",
    roles: ["Super_Admin", "National_Admin"],
  },
  {
    id: uuidv4(),
    icon: <Speed />,
    text: "Cross cutting issues",
    path: "/cross-cutting-issues",
    roles: ["Super_Admin", "National_Admin", "Reviewer", "Validator"],
  },
  // {
  //   icon: <AddLocation />,
  //   text: "GIS Page",
  //   path: "/gis",
  //   roles: ["Super_Admin", "National_Admin", "Reviewer", "Validator"],
  // },
  {
    icon: <ContentPasteIcon />,
    text: "Create Users",
    path: "/super_admin/create-users",
    roles: ["Super_Admin"],
  },
  {
    icon: <VisibilityIcon />,
    text: "View Users",
    path: "/super_admin/view-users",
    roles: ["Super_Admin"],
  },
  {
    icon: <VisibilityIcon />,
    text: "View Trainees",
    path: "/pmu/trainees",
    roles: ["Pmu"],
  },
  {
    icon: <VisibilityIcon />,
    text: "View MCP Profiles",
    path: "/pmu/mcp-profile",
    roles: ["Pmu"],
  },
];
