// export const tcDataHeader = [
//   "Institution Code",
//   // "Institution State",
//   "Institution Name",
//   "Institution Type",
//   "Institution Category",
//   "Institution Ownership",
//   "Year Established",
//   "Institution Address",
//   "Institution Phone",
//   "Institution Email",
//   "Male Hostel Number",
//   "Female Hostel Number",
//   "Sponsor",
//   "Bus Number",
//   "Gen Number",
//   "Power Type",
//   "Power Capacity",
//   "Agricultural Fields",
// ];
export const tcDataHeader = [
  { title: "Institution Code", id: 1 },
  { title: "Institution Name", id: 2 },
  { title: "Institution Type", id: 3 },
  { title: "Institution Category", id: 4 },
  { title: "Institution Ownership", id: 5 },
  { title: "Year Established", id: 6 },
  { title: "Institution Address", id: 7 },
  { title: "Institution Phone", id: 8 },
  { title: "Institution Email", id: 9 },
  { title: "Male Hostel Number", id: 10 },
  { title: "Female Hostel Number", id: 11 },
  { title: "Sponsor", id: 12 },
  { title: "Bus Number", id: 13 },
  { title: "Gen Number", id: 14 },
  { title: "Power Type", id: 15 },
  { title: "Power Capacity", id: 16 },
  { title: "Agricultural Fields", id: 17 },
];
export const crossCuttingIssuesDataHeader = [
  {title:"Cross Cutting Issue", id: 1},
  {title:"Reported", id: 2},
  {title:"Investigated", id: 3},
  {title:"Uninvestigated", id: 4},
  {title:"Resolved", id: 5},
  {title:"Unresolved", id: 6},
  // "Reported",
  // "Investigated",
  // "Uninvestigated",
  // "Resolved",
  // "Unresolved",
];
