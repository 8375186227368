import { motion } from "framer-motion";
import CloseIcon from "@mui/icons-material/Close";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { useState } from "react";
// import TcDataTable from "./table_components/TcData";
import TcDataTable from "./table_components/TcData";
import StudentDataTable from "./table_components/StudentData";
import TeacherDataTable from "./table_components/TeacherData";
import IoiPreview from "./table_components/Ioi";
import PdoPreview from "./table_components/Pdo";
import CrossCuttingIssuesTable from "./table_components/CrossCuttingIssues";

const modalVariants = {
  hidden: {
    y: "-100vh",
    opacity: 0,
  },
  visible: {
    y: "0",
    opacity: 1,
    transition: { delay: 0.2, type: "spring", stiffness: 120 },
  },
};

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 0 }}>
          <Typography component={"span"}>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const Preview = ({ title, closePage, isProps, propsIndex }) => {
  const label = [
    "Technical College",
    "Student Beneficiaries in TVET",
    "Technical Teachers",
    "Intermediate Outcome Indicators",
    "PDO Indicators",
    "Cross Cutting Issues",
  ];
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <div className="fixed left-0 top-0 w-full h-screen bg-black bg-opacity-70 flex justify-center items-center z-50">
      <motion.div
        variants={modalVariants}
        initial="hidden"
        animate="visible"
        exit="hidden"
        className="scrollbar-hide overflow-auto w-[80%] h-[98vh] px-4 pt-2 pb-6 bg-page_bg rounded-lg relative"
      >
        <div
          className="flex justify-between items-center py-3 ml-8 font-bold"
          style={{ fontFamily: "Poppins, sans-serif" }}
        >
          <h1
            className="text-2xl text-active_bg"
            style={{ fontFamily: "Poppins, sans-serif" }}
          >
            {title}
          </h1>

          <CloseIcon
            onClick={closePage}
            sx={{ fontSize: "2rem" }}
            fontWeight={"30"}
            className="text-red-500 cursor-pointer absolute top-2 right-4"
          />
        </div>
        <hr />

        <Box className="w-full my-3">
          <div className="w-[98%] mx-auto">
            <Tabs
              value={isProps ? propsIndex : value}
              onChange={handleChange}
              variant="scrollable"
              scrollButtons={false}
              TabIndicatorProps={{
                style: {
                  backgroundColor: "#0E4F2F",
                  height: "5px",
                  borderRadius: "1rem",
                },
              }}
              className=""
              aria-label="scrollable tabs example"
            >
              {label.map((title, index) => (
                <Tab
                  key={2}
                  label={title}
                  {...a11yProps(isProps ? propsIndex : index)}
                  sx={{
                    color: "#9BB6A8",
                    textTransform: "capitalize",
                    fontSize: "14px",

                    fontWeight: "bold",
                    "&.Mui-selected": {
                      color: "#0E4F2F", // Active state color
                    },
                  }}
                />
              ))}
            </Tabs>
          </div>
          <br />
          <br />

          <CustomTabPanel value={isProps ? propsIndex : value} index={0}>
            <TcDataTable />
          </CustomTabPanel>
          <CustomTabPanel value={isProps ? propsIndex : value} index={1}>
            <StudentDataTable />
          </CustomTabPanel>
          <CustomTabPanel value={isProps ? propsIndex : value} index={2}>
            <TeacherDataTable />
          </CustomTabPanel>
          <CustomTabPanel value={isProps ? propsIndex : value} index={3}>
            <IoiPreview />
          </CustomTabPanel>
          <CustomTabPanel value={isProps ? propsIndex : value} index={4}>
            <PdoPreview />
          </CustomTabPanel>
          <CustomTabPanel value={isProps ? propsIndex : value} index={5}>
            <CrossCuttingIssuesTable />
          </CustomTabPanel>
        </Box>
      </motion.div>
    </div>
  );
};

export default Preview;
