import { createBrowserRouter } from "react-router-dom";
import SideNav from "./Layout/SideNav";
import BeneficiariesData from "./Pages/Component1/Beneficiaries/Beneficiaries_data";
// import CrossCuttingIssues from "./Pages/Component1/Component1/Cross_Cutting_Issues/Cross_Cutting_Issues";
import CrossCuttingIssues from "./Pages/Component1/Cross_Cutting_Issues/Cross_Cutting_Issues";
// import GIS from "./Pages/Component1/GIS/Gis";
import GIS from "./Pages/Component1/GIS/Gis";

import AnalyticsDashboard from "./Pages/Component1/Analytics_Dashboard/Analytics_Dashboard";
import MandEDashboard from "./Pages/Component1/Analytics_Dashboard/dashboard_views/User1";
import ValidatorDashboard from "./Pages/Component1/Analytics_Dashboard/dashboard_views/Validator";
import ReviewerDashboard from "./Pages/Component1/Analytics_Dashboard/dashboard_views/Reviewer";
import NationalAdminDashboard from "./Pages/Component1/Analytics_user3/NationalAdmin";
import LoginPage from "./Pages/Component1/Login";
import Track from "./Pages/Component1/Track/Track";
import Validate from "./Pages/Component1/Validate/validate";
import Overview from "./Pages/Component1/Overview/Overview";
import Review from "./Pages/Component1/Review/Review";
import ProtectedRoute from "./Pages/Component1/ProtectedRoute";
import RequireAuth from "./Pages/Component1/RequireAuth";
import Unauthorized from "./Pages/Component1/Unauthorized";
import AnalyticsSchools from "./Pages/Component1/AnalyticsSchoolPage";
import HomePage from "./Pages/Component1/Home";
import PMUAnalyticsDisplay from "./Pages/Component2/AnalyticsDisplay";
import ViewUsers from "./Pages/Component1/SuperAdmin_CreateUsers/ViewUsers";
import McpProfile from "./Pages/Component2/McpProfile";
import TraineesProfile from "./Pages/Component2/TraineesProfile";
import CreateUsers from "./Pages/Component1/SuperAdmin_CreateUsers/CreateUsers";
import Component2BeneficiariesPage from "./Pages/Component2/BeneficiariesPage";


export const router = createBrowserRouter(
    [
      {
        path: "/",
        element: <HomePage />, // Set Home as the default landing page
      },
      {
        path: "/auth",
        element: <LoginPage />,
      },
      {
        path: "/",
        element: <RequireAuth />, // Ensure user is authenticated
        children: [
          {
            element: <SideNav />,
            children: [
              {
                path: "m_e_officer/analytics/:id",
                element: (
                  <ProtectedRoute
                    element={<MandEDashboard />}
                    roles={[
                      "Super_Admin",
                      "National_Admin",
                      "Reviewer",
                      "Validator",
                      "M_E_OFFICE",
                    ]}
                  />
                ),
              },
              {
                path: "validator/analytics",
                element: (
                  <ProtectedRoute
                    element={<ValidatorDashboard />}
                    roles={["Validator"]}
                  />
                ),
              },
              {
                path: "reviewer/analytics",
                element: (
                  <ProtectedRoute
                    element={<ReviewerDashboard />}
                    roles={["Reviewer"]}
                  />
                ),
              },
              {
                path: "national_admin/analytics",
                element: (
                  <ProtectedRoute
                    element={<NationalAdminDashboard />}
                    roles={["National_Admin"]}
                  />
                ),
              },
              {
                path: "super_admin/analytics",
                element: (
                  <ProtectedRoute
                    element={<NationalAdminDashboard />}
                    roles={["Super_Admin"]}
                  />
                ),
              },
              // Component 2 PMU Analytics
              {
                path: "pmu/analytics",
                element: (
                  <ProtectedRoute
                    element={<PMUAnalyticsDisplay />}
                    roles={["Pmu"]}
                  />
                ),
              },
              {
                path: "beneficiaries-data",
                element: (
                  <ProtectedRoute
                    element={<BeneficiariesData />}
                    roles={["M_E_OFFICE", "Pmu"]}
                  />
                ),
              },
              {
                path: "validate",
                element: (
                  <ProtectedRoute element={<Validate />} roles={["Validator"]} />
                ),
              },
              {
                path: "review",
                element: (
                  <ProtectedRoute element={<Review />} roles={["Reviewer"]} />
                ),
              },
              {
                path: "overview",
                element: (
                  <ProtectedRoute
                    element={<Overview />}
                    roles={["Super_Admin", "National_Admin"]}
                  />
                ),
              },
              // {
              //   path: "gis",
              //   element: (
              //     <ProtectedRoute
              //       element={<GIS />}
              //       roles={[
              //         "Super_Admin",
              //         "National_Admin",
              //         "Reviewer",
              //         "Validator",
              //       ]}
              //     />
              //   ),
              // },
              {
                path: "tracking",
                element: (
                  <ProtectedRoute
                    element={<Track />}
                    roles={[
                      "Super_Admin",
                      "National_Admin",
                      "Reviewer",
                      "Validator",
                      "M_E_OFFICE",
                    ]}
                  />
                ),
              },
              {
                path: "cross-cutting-issues",
                element: (
                  <ProtectedRoute
                    element={<CrossCuttingIssues />}
                    roles={[
                      "Super_Admin",
                      "National_Admin",
                      "Reviewer",
                      "Validator",
                      "M_E_OFFICE",
                    ]}
                  />
                ),
              },
              {
                path: "analytics-schools/:id", // Add this route
                element: (
                  <ProtectedRoute
                    element={<AnalyticsSchools />}
                    roles={[
                      "Super_Admin",
                      "National_Admin",
                      "Reviewer",
                      "Validator",
                      "M_E_OFFICE",
                    ]}
                  />
                ),
              },
              {
                path: "super_admin/create-users",
                element: (
                  <ProtectedRoute
                    element={<CreateUsers />}
                    roles={["Super_Admin"]}
                  />
                ),
              },
              {
                path: "super_admin/view-users",
                element: (
                  <ProtectedRoute
                    element={<ViewUsers />}
                    roles={["Super_Admin"]}
                  />
                ),
              },
              // Component 2 Pages
              {
                path: "indicator-data",
                element: (
                  <ProtectedRoute
                    element={<Component2BeneficiariesPage />}
                    roles={["Pmu"]}
                  />
                ),
              },
              {
                path: "pmu/mcp-profile",
                element: (
                  <ProtectedRoute element={<McpProfile />} roles={["Pmu"]} />
                ),
              },
              {
                path: "pmu/trainees",
                element: (
                  <ProtectedRoute element={<TraineesProfile />} roles={["Pmu"]} />
                ),
              },
            ],
          },
        ],
      },
    ],
    {
      path: "/unauthorized",
      element: <Unauthorized />,
    }
    
  );