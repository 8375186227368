import React from "react";

import axios from "axios";

import AccountBalanceOutlinedIcon from "@mui/icons-material/AccountBalanceOutlined";
import PeopleAltOutlinedIcon from "@mui/icons-material/PeopleAltOutlined";
import SsidChartOutlinedIcon from "@mui/icons-material/SsidChartOutlined";
import Groups2OutlinedIcon from "@mui/icons-material/Groups2Outlined";

// import CrossCuttingIssuesDropdown from "./DropdownCci";
// import CrossCuttingIssuesCard from "./Cards_component/CrossCuttingIssuesCard";

import AnalyticsCard from "../../Pages/Component1/Analytics_user3/Cards_component/AnalyticsCards";
import PdoCard from "../../Pages/Component1/Analytics_user3/Cards_component/PdoCard";
// import GISComponent from "../../Pages/Component1/GIS/Gis";
import IntermediateCard from "../../Pages/Component1/Analytics_user3/Cards_component/IntermediateCard";

const Component1DashboardDisplay = ({
  totalStudents,
  totalTTIs,
  totalPDO2,
}) => {
  return (
    <div className="flex flex-col mt-3 px-3">
      <div className="grid grid-cols-4 gap-x-3">
        <AnalyticsCard
          title="Total TCs"
          value="38"
          icon={AccountBalanceOutlinedIcon}
        />
        <AnalyticsCard
          title="Total Students"
          value={totalStudents}
          icon={PeopleAltOutlinedIcon}
        />
        <AnalyticsCard
          title="Total Priority Trades"
          value="7"
          icon={SsidChartOutlinedIcon}
        />
        <AnalyticsCard
          title="Total Number of TTIs"
          value={totalTTIs}
          icon={Groups2OutlinedIcon}
        />
      </div>

      <div className="mt-4">
        <div className="custom-shadow bg-white px-4 py-4 rounded-lg mt-1">
          <IntermediateCard />
        </div>
      </div>

      <div className="mt-4">
        <div className="custom-shadow bg-white px-4 py-4 rounded-lg mt-1">
          <PdoCard totalPDO2={totalPDO2} />
        </div>
      </div>

      <br />
      <br />
      {/* <div className="flex items-center gap-5">
        <p
          className="text-primary_text_light mt-1"
          style={{ fontFamily: "Poppins, sans-serif" }}
        >
          GIS map
        </p>
      </div> */}

      {/* <GISComponent /> */}
    </div>
  );
};

export default Component1DashboardDisplay;
