import React, { useState } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import axiosInstance from "../../../../Services/axiosInstance";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useSelector } from "react-redux";
import {
  selectCurrentJurisdiction,
  selectCurrentToken,
} from "../../../../features/auth/authSlice";
import { getTcName } from "../../../../utils/getFormattedToken";
import { toSentenceCase } from "../../../../utils/toSentenceCase";
import ShowLoader from "../../../../utils/showLoader";
import ShowError from "../../../../utils/showError";
import MoreOptionsMenu from "../../../../Components/MoreOptionsIcon";
import EditModalForm from "../../../../Components/Modal_Forms/EditModal";
import ConfirmationModal from "../../../../Components/Modal_Forms/ConfirmDelete";
import { fetchAllTeachersPosts } from "../../../../utils/apiContext";

const columns = [
  // General Information
  {
    id: "school_state_location",
    label: "School State Location",
    minWidth: 170,
    align: "center",
    type: "text",
  },
  { id: "name", label: "Name", minWidth: 170, align: "center", type: "text" },
  {
    id: "gender",
    label: "Gender",
    minWidth: 170,
    align: "center",
    type: "dropdown",
    options: [
      { value: "Male", label: "Male" },
      { value: "Female", label: "Female" },
    ],
  },
  {
    id: "date_of_birth",
    label: "Date of Birth",
    minWidth: 170,
    align: "center",
    type: "date",
  },
  { id: "age", label: "Age", minWidth: 170, align: "center", type: "number" },
// Contact Information
{
  id: "phone_number",
  label: "Phone Number",
  minWidth: 170,
  align: "center",
  type: "number",
},
{
  id: "email",
  label: "Email",
  minWidth: 170,
  align: "center",
  type: "email",
},
  // Professional Information
  {
    id: "service_type",
    label: "Training Type",
    minWidth: 170,
    align: "center",
    type: "dropdown",
    options: [
      { value: "Pre-service", label: "Pre-Service" },
      { value: "In-service", label: "In-Service" },
    ],
  },
  {
    id: "area_of_specialization",
    label: "Area of Specialization",
    minWidth: 170,
    align: "center",
    type: "dropdown",
    options: [
      { value: "Construction", label: "Construction" },
      { value: "Mechanical Engineering", label: "Mechanical Engineering" },
      { value: "Hospitality", label: "Hospitality" },
      { value: "Electrical Engineering", label: "Electrical Engineering" },
      { value: "ICT", label: "ICT" },
      { value: "Agricultural Science", label: "Agricultural Science" },
      { value: "Garment Making", label: "Garment Making" },
    ],
  },
  {
    id: "certified_as_NSQF_assesors",
    label: "Certified NSQF Assessor",
    minWidth: 170,
    align: "center",
    type: "dropdown",
    options: [
      { value: true, label: "Yes" },
      { value: false, label: "No" },
    ],
    format: (value) => (value ? "Yes" : "No"),
  },
  {
    id: "certified_as_NSQF_verifiers",
    label: "Certified NSQF Verifier",
    minWidth: 170,
    align: "center",
    type: "dropdown",
    options: [
      { value: true, label: "Yes" },
      { value: false, label: "No" },
    ],
    format: (value) => (value ? "Yes" : "No"),
  },
  {
    id: "digital_literacy_training",
    label: "Digital Literacy Training",
    minWidth: 170,
    align: "center",
    type: "dropdown",
    options: [
      { value: true, label: "Yes" },
      { value: false, label: "No" },
    ],
    format: (value) => (value ? "Yes" : "No"),
  },
  {
    id: "pedagogy_training",
    label: "Pedagogy Training",
    minWidth: 170,
    align: "center",
    type: "dropdown",
    options: [
      { value: true, label: "Yes" },
      { value: false, label: "No" },
    ],
    format: (value) => (value === true ? "Yes" : value === false ? "No" : ""),
  },
  {
    id: "designation_in_the_college",
    label: "Designation in the College",
    minWidth: 170,
    align: "center",
    type: "text",
  },
  {
    id: "date_of_appointment",
    label: "Date of Appointment",
    minWidth: 170,
    align: "center",
    type: "date",
  },
  {
    id: "years_of_experience",
    label: "Years of Experience",
    minWidth: 170,
    align: "center",
    type: "number",
  },
  {
    id: "possible_date_of_retirement",
    label: "Possible Date of Retirement",
    minWidth: 170,
    align: "center",
    type: "date",
  },
  {
    id: "teaching_level",
    label: "Teaching Level",
    minWidth: 170,
    align: "center",
    type: "dropdown",
    options: [
      { value: "senior", label: "Senior" },
      { value: "junior", label: "Junior" },
    ],
  },
  {
    id: "nature_of_engagement",
    label: "Nature of Engagement",
    minWidth: 170,
    align: "center",
    type: "dropdown",
    options: [
      { value: "full-time", label: "Full-time" },
      { value: "part-time", label: "Part-time" },
    ],
  },
  {
    id: "highest_level_of_education_completed",
    label: "Highest Education Completed",
    minWidth: 170,
    align: "center",
    type: "text",
  },
  {
    id: "highest_level_ofteacher_training",
    label: "Highest Teacher Training",
    minWidth: 170,
    align: "center",
    type: "text",
  },
  {
    id: "professional_certificate",
    label: "Professional Certification",
    minWidth: 170,
    align: "center",
    type: "text",
  },
  {
    id: "trade_area_taught_in_the_college",
    label: "Trade Area Taught in College",
    minWidth: 170,
    align: "center",
    type: "text",
  },
  {
    id: "subjects_taught_in_the_college",
    label: "Subject Taught in College",
    minWidth: 170,
    align: "center",
    type: "text",
  },
  {
    id: "ict_proficiency",
    label: "ICT Proficiency",
    minWidth: 170,
    align: "center",
    type: "dropdown",
    options: [
      { value: "beginner", label: "Beginner" },
      { value: "intermediate", label: "Intermediate" },
      { value: "advanced", label: "Advanced" },
    ],
  },
  {
    id: "did_you_participate_in_the_ideas_tti_training",
    label: "IDEAS TTI Training",
    minWidth: 170,
    align: "center",
    type: "dropdown",
    options: [
      { value: "Yes", label: "Yes" },
      { value: "No", label: "No" },
    ],
  },
  {
    id: "special_need",
    label: "Does teacher have special needs?",
    minWidth: 170,
    align: "center",
    type: "dropdown",
    options: [
      { value: "Yes", label: "Yes" },
      { value: "No", label: "No" },
    ],
  },

  
];

export default function TeacherDataTable() {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState(null);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [rowToDelete, setRowToDelete] = useState(null);

  const jurisdiction = useSelector(selectCurrentJurisdiction);
  const userTkn = useSelector(selectCurrentToken);
  const tc_name = getTcName(userTkn);
  const queryClient = useQueryClient();

  const { data, isLoading, error } = useQuery({
    queryKey: ["teachersData"],
    queryFn: () => fetchAllTeachersPosts(jurisdiction),
  });

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const onEdit = (rowData) => {
    const rowDataWithTraining = {
      ...rowData,
      
      occupational_training: {
        digital_literacy_training: rowData?.digital_literacy_training,
        pedagogy_training: rowData?.pedagogy_training,
        trade_area: rowData?.trade_area,
      },
    };
    setSelectedRowData(rowDataWithTraining);
    // setSelectedRowData(rowData);
    console.log("rowDataWithTraining",rowDataWithTraining)
    setIsEditModalOpen(true);
  };

  const handleEditAndSave = async (updatedData) => {
    try {
      const updatedDataWithJurisdiction = { ...updatedData, jurisdiction };
      updatedDataWithJurisdiction.occupational_training = {
        digital_literacy_training: updatedData.digital_literacy_training,
        pedagogy_training: updatedData.pedagogy_training,
        trade_area: updatedData.trade_area,
      };
  
      await axiosInstance.put(
        `/teachers/c1/update-teacher/${updatedData._id}`,
        updatedDataWithJurisdiction
      );
      console.log("updatedDataWithJurisdiction", updatedDataWithJurisdiction);
      queryClient.invalidateQueries(["teachersData"]);
      setIsEditModalOpen(false);
    } catch (error) {
      console.error("Failed to save the updated data:", error);
    }
  };

  const onDelete = (rowId) => {
    setRowToDelete(rowId);
    setIsConfirmationModalOpen(true);
  };

  const handleConfirmDelete = async () => {
    try {
      await axiosInstance.delete(
        `/teachers/c1/delete-teacher/${rowToDelete}/?jurisdiction=${jurisdiction}`
      );
      setIsConfirmationModalOpen(false);
      queryClient.invalidateQueries(["teachersData"]);
    } catch (error) {
      console.error("Failed to delete the data:", error);
    }
  };

  if (isLoading) {
    return <ShowLoader />;
  }
  if (error?.response?.status === 400) {
    return <ShowError />;
  }

  const filteredData = Array.isArray(data)
    ? data.filter((teacher) => teacher.tc_name === tc_name)
    : [];

  return (
    <>
      <Paper>
        <TableContainer
          className="scrollbar scrollbar-w-2 scrollbar-h-1.5 scrollbar-thumb-rounded-[50px] scrollbar-track-gray-300 scrollbar-track-rounded-[50px] scrollbar-thumb-[#A5A2A2] w-full  overflow-x-scroll"
          sx={{ backgroundColor: "#F2F2F2" }}
        >
          <Table stickyHeader aria-label="sticky table">
            <TableHead className="bg-[#FAF9F9]">
              <TableRow>
                <TableCell
                  sx={{
                    whiteSpace: "nowrap",
                    width: "1%",
                    fontWeight: "bold",
                    color: "#2D3142",
                  }}
                >
                  Options
                </TableCell>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    sx={{
                      whiteSpace: "nowrap",
                      width: "1%",
                      fontWeight: "bold",
                      color: "#2D3142",
                    }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody className="border-2 mt-2">
              {filteredData
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row) => {
                  return (
                    <TableRow role="checkbox" tabIndex={-1} key={row._id}>
                      <TableCell>
                        <MoreOptionsMenu
                          onEdit={() => onEdit(row)}
                          onDelete={() => onDelete(row._id)}
                        />
                      </TableCell>
                      {columns.map((column) => {
                        let value = row[column.id];

                        if (column.id === "digital_literacy_training") {
                          value =
                            row?.occupational_training
                              ?.digital_literacy_training;
                        }
                        if (column.id === "pedagogy_training") {
                          value = row?.occupational_training?.pedagogy_training;
                        }
                        return (
                          <TableCell
                            key={column.id}
                            align={column.align}
                            sx={{
                              whiteSpace: "nowrap",
                              width: "1%",
                            }}
                          >
                            {/* {column.format && typeof value === "number"
                              ? column.format(value)
                              : value} */}
                            {column.format && typeof value === "boolean"
                              ? column.format(value)
                              : value}
                            {/* {column.format && typeof value !== "undefined"
                              ? column.format(value)
                              : value} */}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25, 100]}
          component="div"
          count={filteredData.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
      <EditModalForm
        open={isEditModalOpen}
        handleClose={() => setIsEditModalOpen(false)}
        rowData={selectedRowData}
        handleSave={handleEditAndSave}
        fields={columns}
        header="Edit Teacher Data"
      />
      <ConfirmationModal
        open={isConfirmationModalOpen}
        handleClose={() => setIsConfirmationModalOpen(false)}
        handleConfirm={handleConfirmDelete}
        message="Are you sure you want to delete this item?"
      />
    </>
  );
}
