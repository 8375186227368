import React from "react";
import AnalyticsCard from "../../Pages/Component1/Analytics_user3/Cards_component/AnalyticsCards";

import Groups2OutlinedIcon from "@mui/icons-material/Groups2Outlined";
import IntermediateCardComponent3 from "../../Pages/Component1/Analytics_user3/Cards_component/intermediateCardComponent3";
import PdoCardComponent3 from "../../Pages/Component1/Analytics_user3/Cards_component/PdoCardComponent3";
import { TeachersDoughnutChartDisplayComponent3 } from "../../Pages/Component1/Analytics_Dashboard/chart_components/component3Doughnut";

const Component3DashboardDisplay = ({
  totalTTIs,
  aggregatedData,
  preServiceData,
  inServiceData,
  preServiceAggregatedData,
  inServiceAggregatedData,
}) => {
  // console.log(totalTTIs, aggregatedData);
  const maleCount = aggregatedData.maleCount;
  const femaleCount = aggregatedData.femaleCount;
  const maleCountPreService = preServiceAggregatedData.maleCount;
  const femaleCountPreService = preServiceAggregatedData.femaleCount;
  const maleCountInService = inServiceAggregatedData.maleCount;
  const femaleCountInService = inServiceAggregatedData.femaleCount;
  const total = maleCount + femaleCount;
  const percentageMale = ((maleCount * 100) / total).toFixed(1);
  const percentageFemale = ((femaleCount * 100) / total).toFixed(1);

  const pieChartData = [
    { malePercentage: parseFloat(percentageMale) },
    { femalePercentage: parseFloat(percentageFemale) },
  ];
  // console.log(
  //   maleCountPreService,
  //   femaleCountPreService,
  //   maleCountInService,
  //   femaleCountInService
  // );
  return (
    <div className="flex flex-col mt-3 px-3">
      <div className="flex gap-3">
        <AnalyticsCard
          title="Total Number of TTIs participating in supported skills upgrading programs"
          value={totalTTIs}
          icon={Groups2OutlinedIcon}
        />
        <AnalyticsCard
          title="Total Number of TTIs TRAINING COURSES in pre-and in-service programs "
          value={totalTTIs}
          icon={Groups2OutlinedIcon}
        />
      </div>
      <div className="mt-4">
        <div className="custom-shadow bg-white px-4 py-4 rounded-lg mt-1">
          <h3 className=" m-5">
            Percentage of technical teachers and instructors participating in
            supported skills upgrading programs.
          </h3>
          <TeachersDoughnutChartDisplayComponent3 data={pieChartData} />
        </div>
      </div>
      <div className="mt-4">
        <div className="custom-shadow bg-white px-4 py-4 rounded-lg mt-1">
          <IntermediateCardComponent3
            maleCount={maleCount}
            femaleCount={femaleCount}
            maleCountPreService={maleCountPreService}
            femaleCountPreService={femaleCountPreService}
            maleCountInService={maleCountInService}
            femaleCountInService={femaleCountInService}
            percentageFemale={percentageFemale}
            percentageMale={percentageMale}
          />
        </div>
        <div className="mt-4">
          <div className="custom-shadow bg-white px-4 py-4 rounded-lg mt-1">
            <PdoCardComponent3
              maleCountPreService={maleCountPreService}
              femaleCountPreService={femaleCountPreService}
              maleCountInService={maleCountInService}
              femaleCountInService={femaleCountInService}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Component3DashboardDisplay;
