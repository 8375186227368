import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import { motion } from "framer-motion";

const ConfirmationModal = ({ open, handleClose, handleConfirm, message }) => {
  if (!open) return null;

  const modalVariants = {
    hidden: {
      y: "-100vh",
      opacity: 0,
    },
    visible: {
      y: "0",
      opacity: 1,
      transition: { delay: 0.2, type: "spring", stiffness: 120 },
    },
  };

  return (
    <div className="fixed left-0 top-0 w-full h-full z-20 bg-black bg-opacity-50 flex justify-center items-center">
      <motion.div
        variants={modalVariants}
        initial="hidden"
        animate="visible"
        exit="hidden"
        className="scrollbar-hide overflow-auto w-[30%] h-auto px-8 pt-8 pb-6 bg-white rounded-lg relative"
      >
        <div className="flex justify-between font-bold">
          <h1 className="text-2xl">Confirm Action</h1>
        </div>
        <hr className="mt-2 mb-4" />
        <p>{message}</p>
        <div className="flex justify-end mt-4">
          <button
            onClick={handleClose}
            className="mr-2 px-4 py-2 bg-gray-300 rounded"
          >
            Cancel
          </button>
          <button
            onClick={handleConfirm}
            className="px-4 py-2 bg-red-500 text-white rounded"
          >
            Confirm
          </button>
        </div>
      </motion.div>
    </div>
  );
};

export default ConfirmationModal;
