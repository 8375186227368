import React from "react";
import { useSelector } from "react-redux";
import school_logo from "../../Assets/school_logo.svg";

import PageHeader from "../../Components/page_header";
import StudentsCard from "./Analytics_Dashboard/card_components/Students";
import TeachersAnalyticsDisplay from "./Analytics_Dashboard/card_components/Teachers";
import { tcDataInfo } from "./Analytics_Dashboard/card_components/tc_data";
import {
  selectCurrentJurisdiction,
  selectCurrentToken,
  selectCurrentUserRole,
  selectValidatorState,
} from "../../features/auth/authSlice";
import MandEDashboard from "./Analytics_Dashboard/dashboard_views/User1";
import { jwtDecode } from "jwt-decode";
import ShowError from "../../utils/showError";
import {
  fetchAllStudents,
  fetchAllTeachersByState,
  fetchAllTeachersPosts,
  fetchTechnicalCollegePostById,
} from "../../utils/apiContext";
import { useQuery } from "@tanstack/react-query";
import { StudentsDoughnutChartDisplay } from "./Analytics_Dashboard/chart_components/DoughnutChart";
import {
  TechnicalCollegeDataDisplay,
  ValidatorTechnicalCollegeDataDisplay,
} from "./Analytics_Dashboard/card_components/TC";
import {
  FirstBarChartDisplay,
  SecondBarChartDisplay,
} from "./Analytics_Dashboard/chart_components/BarChartDisplays";
import { useParams } from "react-router-dom";
import Loader from "../../Components/Loader";
import TotalStudentsInTCCard from "./Analytics_Dashboard/card_components/TotalStudentsInTCCard";
import SummaryProjectDataCard from "./Analytics_Dashboard/card_components/SummaryProjectDataCard";
import { toSentenceCase } from "../../utils/toSentenceCase";

const AnalyticsSchools = () => {
  // const userRoles = useSelector((state) => state.userRole.roles);
  const { id: collegeId } = useParams();
  // console.log(collegeId);
  const userRoles = useSelector(selectCurrentUserRole);
  const jurisdiction = useSelector(selectCurrentJurisdiction);
  const userTkn = useSelector(selectCurrentToken);
  // console.log("jurisdiction:", jurisdiction);
  const decodedTkn = jwtDecode(userTkn);
  const { tc_name } = decodedTkn;
  const validatorState = useSelector(selectValidatorState);

  // console.log(userRoles);
  const allowedRoles = [
    "Super_Admin",
    "National_Admin",
    "Reviewer",
    "Validator",
    "M_E_OFFICE",
  ];
  const roleMessage =
    allowedRoles.includes(userRoles) === ""
      ? ""
      : userRoles === "Reviewer"
      ? "Total no of Technical colleges"
      : userRoles === "Validator"
      ? "Total no of Technical colleges"
      : userRoles === "M_E_OFFICE"
      ? "Technical Colleges"
      : "";

  const filterAndAggregateData = (data, tc_name) => {
    let maleCount = 0;
    let femaleCount = 0;
    let maleGraduates = 0;
    let femaleGraduates = 0;

    // Filter data by collegeId if provided
    const filteredData = collegeId
      ? data.filter((obj) => obj.school_name === tc_name)
      : data;
    // console.log();
    // console.log(filteredData);

    const tradeCounts = filteredData.reduce((acc, obj) => {
      if (!acc[obj.occupational_trade]) {
        acc[obj.occupational_trade] = { male: 0, female: 0 };
      }
      return acc;
    }, {});

    filteredData.forEach((obj) => {
      if (obj.sex === "male") {
        maleCount += 1;
        if (obj.grad_status === "yes") {
          maleGraduates += 1;
        }
        if (tradeCounts[obj.occupational_trade]) {
          tradeCounts[obj.occupational_trade].male += 1;
        }
      } else if (obj.sex === "female") {
        femaleCount += 1;
        if (obj.grad_status === "yes") {
          femaleGraduates += 1;
        }
        if (tradeCounts[obj.occupational_trade]) {
          tradeCounts[obj.occupational_trade].female += 1;
        }
      }
    });

    return {
      maleCount,
      femaleCount,
      maleGraduates,
      femaleGraduates,
      totalStudents: filteredData.length,
      tradeCounts,
    };
  };
  const {
    data: teachersData,
    isLoading: teachersIsLoading,
    error: teachersError,
  } = useQuery({
    queryKey: ["teachersData"],
    queryFn: () => fetchAllTeachersPosts(jurisdiction),
  });

  const {
    data: studentsData,
    isLoading,
    error,
  } = useQuery({
    queryKey: ["studentData"],
    queryFn: () => fetchAllStudents(jurisdiction),
  });
  // console.log("all data:", data);
  const {
    data: collegeData,
    isLoading: collegeLoading,
    error: collegeError,
  } = useQuery({
    queryKey: ["technicalCollegeData", collegeId],
    queryFn: () => fetchTechnicalCollegePostById(collegeId, jurisdiction),
    enabled: !!collegeId, // Ensure the query runs only when collegeId is available
  });

  const fileteredTeachersData = teachersData?.filter(
    (obj) => obj.tc_name === collegeData?.institution_name
  );

  console.log("fileteredTeachersData",fileteredTeachersData)

  const aggregatedData = studentsData
    ? filterAndAggregateData(studentsData, collegeData?.institution_name)
    : {
        maleCount: 0,
        femaleCount: 0,
        maleGraduates: 0,
        femaleGraduates: 0,
        totalStudents: 0,
        tradeCounts: {},
      };

  const tcNameInCaps = toSentenceCase(collegeData?.institution_name);

  // if (isLoading || collegeLoading || teachersIsLoading) {
  //   return <Loader />;
  // }
  if (error || collegeError || teachersError) {
    return (
      <div>
        <ShowError />
      </div>
    );
  }
  // const postData = "data";

  return (
    <>
      <div>
        <PageHeader title="Analytics" />

        <div className="mt-3 px-3">
          <div className="flex gap-x-4">
            <div className="w-[45%]">
              <div className=" flex gap-x-6">
                <TotalStudentsInTCCard
                  totalStudentsInTC={aggregatedData.totalStudents}
                />
                <div className="custom-shadow bg-white px-4 py-4 rounded-lg">
                  <h1
                    className="text-[20px] font-bold text-primary_text"
                    style={{ fontFamily: "Poppins, sans-serif" }}
                  >
                    Students in TVET in {tcNameInCaps}
                  </h1>
                  <h1
                    className="text-active_bg text-3xl font-bold mt-2"
                    style={{ fontFamily: "Poppins, sans-serif" }}
                  >
                    {userRoles === "Validator" || userRoles === "Reviewer"
                      ? aggregatedData.totalStudents
                      : userRoles === "M_E_OFFICE"
                      ? `${aggregatedData.totalStudents}`
                      : ""}
                  </h1>
                </div>
              </div>
              <br />
              <div className="custom-shadow h-auto bg-white rounded-lg">
                <ValidatorTechnicalCollegeDataDisplay data={collegeData} />
              </div>
            </div>
            <div className="w-[55%] custom-shadow h-auto bg-white rounded-lg">
              <SummaryProjectDataCard />
            </div>
          </div>

          <br />

          <div className="flex justify-between">
            <div className="custom-shadow w-[45%] flex flex-col items-center bg-white px-4 py-4 rounded-lg">
              <h1
                className="text-primary_text font-bold text-[20px]"
                style={{ fontFamily: "Poppins, sans-serif" }}
              >
                Enrolled Students
              </h1>
              <SecondBarChartDisplay
                maleCount={aggregatedData.maleCount}
                femaleCount={aggregatedData.femaleCount}
              />
            </div>
            <div className="custom-shadow w-[45%] flex flex-col items-center bg-white px-4 py-4 rounded-lg">
              <h1
                className="text-primary_text font-bold text-[20px]"
                style={{ fontFamily: "Poppins, sans-serif" }}
              >
                Graduate Students
              </h1>
              <FirstBarChartDisplay
                maleCount={aggregatedData.maleGraduates}
                femaleCount={aggregatedData.femaleGraduates}
              />
            </div>
          </div>

          <br />
          <div className="custom-shadow h-auto bg-white rounded-lg">
            <div className="px-4 py-4">
              <h1
                className="text-primary_text font-bold text-[20px]"
                style={{ fontFamily: "Poppins, sans-serif" }}
              >
                Students in Supported Trades
              </h1>
              <br />

              <div>
                <StudentsDoughnutChartDisplay data={aggregatedData} />
              </div>
            </div>
          </div>
          <br />
          <div className="custom-shadow h-auto bg-white rounded-lg">
            <TeachersAnalyticsDisplay Teachersdata={fileteredTeachersData} />
          </div>
          {/* <div className="w-[350px] h-auto">
          <div className="custom-shadow bg-white px-6 py-6 rounded-lg">
            <h1
              className="text-[20px] font-bold text-primary_text"
              style={{ fontFamily: "Poppins, sans-serif" }}
            >
              Total number of Students in {collegeData?.institution_name}
            </h1>
            <h1
              className="text-active_bg text-3xl font-bold mt-2"
              style={{ fontFamily: "Poppins, sans-serif" }}
            >
              {userRoles === "Validator" || userRoles === "Reviewer"
                ? aggregatedData.totalStudents
                : userRoles === "M_E_OFFICE"
                ? `${aggregatedData.totalStudents}`
                : ""}
            </h1>
          </div>
          <br />
          <div className="custom-shadow bg-white px-4 py-14 rounded-lg">
            <div className="">
              <div>
                <h1
                  className="text-primary_text font-bold mb-8 text-[20px]"
                  style={{ fontFamily: "Poppins, sans-serif" }}
                >
                  Total Number of enrolled students
                </h1>
                <SecondBarChartDisplay
                  maleCount={aggregatedData.maleCount}
                  femaleCount={aggregatedData.femaleCount}
                />
              </div>
              <div className="">
                <h1
                  className="text-primary_text font-bold mt-8 mb-4 text-[20px]"
                  style={{ fontFamily: "Poppins, sans-serif" }}
                >
                  Total Number of graduates{" "}
                </h1>
                <FirstBarChartDisplay
                  maleCount={aggregatedData.maleGraduates}
                  femaleCount={aggregatedData.femaleGraduates}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="w-[710px] flex flex-col gap-4">
          <div className="custom-shadow h-auto bg-white rounded-lg">
            <ValidatorTechnicalCollegeDataDisplay data={collegeData} />
          </div>
          <div className="custom-shadow h-auto bg-white rounded-lg">
            <div className="px-4 py-4">
              <h1
                className="text-primary_text font-bold text-[20px]"
                style={{ fontFamily: "Poppins, sans-serif" }}
              >
                Students in Supported Trades
              </h1>
              <br />
              <br />
              <div>
                <StudentsDoughnutChartDisplay data={aggregatedData} />
              </div>
            </div>
          </div>
          <div className="custom-shadow h-auto bg-white rounded-lg">
            <TeachersAnalyticsDisplay Teachersdata={fileteredTeachersData} />
          </div>
        </div> */}
        </div>
      </div>
    </>
  );

  // return (
  //   <div>
  //     <PageHeader title="Analytics" />
  //     <div className="flex gap-3 mt-3 px-3">
  //       <div className="w-[350px] h-auto">
  //         <div className="custom-shadow bg-white px-6 py-6 rounded-lg">
  //           <h1
  //             className="text-[20px] font-bold"
  //             style={{ fontFamily: "Poppins, sans-serif" }}
  //           >
  //             Total no. of Students
  //           </h1>
  //           <h1
  //             className="text-active_bg text-3xl font-bold mt-2"
  //             style={{ fontFamily: "Poppins, sans-serif" }}
  //           >
  //             1140
  //           </h1>
  //         </div>
  //         <br />
  //         <div className="custom-shadow bg-white px-4 py-4 rounded-lg">
  //           <StudentsCard />
  //         </div>
  //       </div>

  //       <div className="w-[807px] flex flex-col gap-4">
  //         <div className="custom-shadow h-auto bg-white rounded-lg relative">
  //           {tcDataInfo.map((item) => (
  //             <div
  //               key={item.id}
  //               className="

  //                items-center border-b border-b-nav_box mb-4 py-2 pl-4"
  //             >
  //               <div className="w-[30%]">
  //                 <h1
  //                   className="font-bold"
  //                   style={{ fontFamily: "Poppins, sans-serif" }}
  //                 >
  //                   {item.title}
  //                 </h1>
  //               </div>

  //               <div className="w-[65%] flex gap-1 items-center">
  //                 <img
  //                   src={school_logo}
  //                   alt="school logo"
  //                   className={`${item.id === 1 ? "block" : "hidden"}`}
  //                 />
  //                 <h1 style={{ fontFamily: "Poppins, sans-serif" }}>
  //                   {item.response}
  //                 </h1>
  //               </div>
  //             </div>
  //           ))}
  //         </div>
  //         <div className="custom-shadow h-auto bg-white rounded-lg">
  //           <TeachersAnalyticsDisplay />
  //         </div>
  //       </div>
  //     </div>
  //   </div>
  // );
};

export default AnalyticsSchools;
