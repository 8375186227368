import { pieArcLabelClasses, PieChart } from "@mui/x-charts";
import ShowLoader from "../../../utils/showLoader";

// export const CertifiedYouthsDoughnutChartDisplay = ({ data }) => {
// //   if (!data) {
// //     return <ShowLoader />;
// //   }
//   const formattedData = [
//     { label: "Male", value: data[0].malePercentage },
//     { label: "Female", value: data[1].femalePercentage },
//   ];
//   return (
//     <PieChart
//       series={[
//         {
//           data: formattedData.map((item) => ({
//             ...item,
//             label: `${item.label} (${item.value})`,
//           })),
//           arcLabel: (item) => `${item.label}`,
//           arcLabelMinAngle: 1,
//           innerRadius: 30,
//           cornerRadius: 5,
//           // cx: 300,
//           cx: 170,
//           // cy: 175,
//           cy: 175,
//         },
//       ]}
//       width={680}
//       // width={680}
//       height={350}
//       // height={350}
//       sx={{
//         [`& .${pieArcLabelClasses.root}`]: {
//           fill: "white",
//           fontWeight: "bold",
//           fontSize: "10px",
//         },
//       }}
//     />
//   );
// };
const data = [
  { value: 5, label: "Male" },
  { value: 10, label: "Female" },
];

const size = {
  width: 400,
  height: 200,
};

export const CertifiedYouthsDoughnutChartDisplay = ({data}) => {
  const chartData = [
    { value: data?.male, label: "Male" },
    { value: data?.female, label: "Female" },
  ];
  return (
    <PieChart
      colors={["#CFF0B5", "#BC5645"]}
      series={[
        {
          arcLabel: (item) => `${item.label} (${item.value}%)`,

          arcLabelMinAngle: 1,
          innerRadius: 10,
          cornerRadius: 5,
          data: chartData,
        },
      ]}
      sx={{
        [`& .${pieArcLabelClasses.root}`]: {
          fill: "black",
          fontWeight: "bold",
          fontSize: "10px",
        },
      }}
      {...size}
    />
  );
};
