import React from "react";

const AnalyticsCard = ({ title, value, icon }) => {
  const Icon = icon;
  return (
    <div className="flex flex-col flex-1 ">
      <div className="custom-shadow bg-white px-6 py-6 rounded-lg flex-1 flex flex-col justify-between">
        {icon && (
          <Icon className="text-linear_grad_1" style={{ fontSize: "2.0rem" }} />
        )}

        <h1
          className="text-[15px] text-primary_text font-bold"
          style={{ fontFamily: "Poppins, sans-serif" }}
        >
          {title}
        </h1>
        <h1
          className="text-active_bg text-2xl font-bold mt-2"
          style={{ fontFamily: "Poppins, sans-serif" }}
        >
          {value}
        </h1>
      </div>
    </div>
  );
};

export default AnalyticsCard;
