import React, { useState, useRef, useEffect } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { motion } from "framer-motion";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import axiosInstance from "../../Services/axiosInstance";
import { useSelector } from "react-redux";
import { jwtDecode } from "jwt-decode";
import {
  selectCurrentJurisdiction,
  selectCurrentToken,
} from "../../features/auth/authSlice";
import { FormDropdown } from "../form";
import { toast } from "sonner";
import { studentClassOptions } from "../../Data_Objects/dummyData";
import { validatorStates } from "../Modal_Forms/Component1/states";

const options = [
  { value: "Construction", label: "Construction" },
  { value: "Mechanical Engineering", label: "Mechanical Engineering" },
  { value: "Hospitality", label: "Hospitality" },
  { value: "Electrical Engineering", label: "Electrical Engineering" },
  { value: "ICT", label: "ICT" },
  { value: "Agricultural Science", label: "Agricultural Science" },
  { value: "Garment Making", label: "Garment Making" },
];

const genderOptions = [
  { value: "", label: "-Select Gender-" },
  { value: "male", label: "Male" },
  { value: "female", label: "Female" },
];
const marriedOptions = [
  { value: "yes", label: "Yes" },
  { value: "no", label: "No" },
];
const ModalStudentForm = ({ open, handleClose }) => {
  const modalRef = useRef(null);

  const jurisdiction = useSelector(selectCurrentJurisdiction);
  const userTkn = useSelector(selectCurrentToken);
  const decodedTkn = jwtDecode(userTkn);
  const { tc_name } = decodedTkn;
  const [isLoading, setIsLoading] = useState(false);

  const [schoolType, setSchoolType] = useState("");
  const [schoolStateLocation, setSchoolStateLocation] = useState("");
  const [schoolName, setSchoolName] = useState("");
  const [surname, setSurname] = useState("");
  const [firstName, setFirstName] = useState("");
  const [otherName, setOtherName] = useState("");
  const [email, setEmail] = useState("");
  const [nationality, setNationality] = useState("");
  const [state, setState] = useState("");
  const [lga, setLga] = useState("");
  const [sex, setSex] = useState("");
  const [regNum, setRegNum] = useState("");
  const [studentClass, setStudentClass] = useState("");
  const [married, setMarried] = useState("");
  const [dateOfBirth, setDateOfBirth] = useState("");
  const [homeTown, setHomeTown] = useState("");
  const [homeAddress, setHomeAddress] = useState("");
  const [parentPhoneNumber, setParentPhoneNumber] = useState("");
  const [parentName, setParentName] = useState("");
  const [parentAddress, setParentAddress] = useState("");
  const [nin, setNin] = useState("");
  const [gpa, setGpa] = useState("");
  const [gradStatus, setGradStatus] = useState("");
  const [yearOfCert, setYearOfCert] = useState("");
  const [gradeAward, setGradeAward] = useState("");
  const [modeOfEntry, setModeOfEntry] = useState("");
  const [dept, setDept] = useState("");
  const [entryYear, setEntryYear] = useState("");
  const [entryLevel, setEntryLevel] = useState("");
  const [qualificationInView, setQualificationInView] = useState("");
  const [exitYear, setExitYear] = useState("");
  const [currentSession, setCurrentSession] = useState("");
  const [currentSemester, setCurrentSemester] = useState("");
  const [durationOfStudy, setDurationOfStudy] = useState("");
  const [cgpa, setCgpa] = useState("");
  const [studentCode, setStudentCode] = useState("");
  const [occupationalTrade, setOccupationalTrade] = useState("");
  const [specialNeeds, setSpecialNeeds] = useState("");

  const [errors, setErrors] = useState({});
  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationFn: async (formData) => {
      setIsLoading(true);
      const response = await axiosInstance.post(
        "/student/c1/make-post-student-bio",
        formData
      );
    },
    onSuccess: () => {
      toast.success("Successful");
      setIsLoading(false);

      queryClient.invalidateQueries(["studentData"]);
      handleClose();
    },
    onError: (err) => {
      if (err.response.status === 400) {
        toast.error("Post already exists");
        setIsLoading(false);
        handleClose();
      } else {
        toast.error("An Error Occurred");
        setIsLoading(false);
        handleClose();
      }
    },
  });

  const handleChange = (e, setValue) => {
    setValue(e.target.value);
    setErrors({ ...errors, [e.target.name]: "" });
  };

  const handleSelectChange = (event) => {
    setSex(event.target.value);
    setErrors({ ...errors, sex: "" });
  };

  const handleRadioChange = (event) => {
    setGradStatus(event.target.value);
    setErrors({ ...errors, gradStatus: "" });
  };

  const validate = () => {
    const newErrors = {};
    const requiredFields = {};

    // Assuming `jurisdiction` and `schoolStateLocation` are already defined variables
    if (jurisdiction === "state") {
      requiredFields.schoolStateLocation = schoolStateLocation;
    }

    // Add the rest of the fields unconditionally
    requiredFields.firstName = firstName;
    requiredFields.surname = surname;
    requiredFields.email = email;
    requiredFields.nationality = nationality;
    requiredFields.state = state;
    requiredFields.lga = lga;
    requiredFields.sex = sex;
    requiredFields.regNum = regNum;
    requiredFields.dateOfBirth = dateOfBirth;
    requiredFields.homeAddress = homeAddress;
    requiredFields.parentName = parentName;
    requiredFields.parentAddress = parentAddress;
    requiredFields.nin = nin;
    requiredFields.gradStatus = gradStatus;
    requiredFields.studentClass = studentClass;
    requiredFields.occupationalTrade = occupationalTrade;

    Object.keys(requiredFields).forEach((field) => {
      if (!requiredFields[field]) {
        newErrors[field] = "This field is required";
      }
    });

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (!validate()) {
      toast.error("Required Fields can't be empty");
      return;
    }
    const newObj = {
      jurisdiction: jurisdiction,
      school_state_location: schoolStateLocation,
      school_type: jurisdiction,
      school_name: tc_name,
      surname: surname,
      first_name: firstName,
      other_name: otherName,
      email: email,
      nationality: nationality,
      state: state,
      lga: lga,
      sex: sex,
      reg_num: regNum,
      class: studentClass,
      married: married,
      date_of_birth: dateOfBirth,
      home_town: homeTown,
      home_address: homeAddress,
      parent_phone_number: parentPhoneNumber,
      parent_name: parentName,
      parent_address: parentAddress,
      nin: nin,
      gpa: gpa,
      grad_status: gradStatus,
      year_of_cert: yearOfCert,
      grade_award: gradeAward,
      mode_of_entry: modeOfEntry,
      dept: dept,
      entry_level: entryLevel,
      entry_year: entryYear,
      qualification_in_view: qualificationInView,
      exit_year: exitYear,
      current_session: currentSession,
      current_semester: currentSemester,
      duration_of_study: durationOfStudy,
      cgpa: cgpa,
      student_code: studentCode,
      occupational_trade: occupationalTrade,
      special_needs: specialNeeds,
    };

    mutation.mutate(newObj);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        handleClose();
      }
    };

    // Add event listener when the component mounts
    document.addEventListener("mousedown", handleClickOutside);

    // Remove event listener on cleanup
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [handleClose]);

  const modalVariants = {
    hidden: {
      y: "-100vh",
      opacity: 0,
    },
    visible: {
      y: "0",
      opacity: 1,
      transition: { delay: 0.2, type: "spring", stiffness: 120 },
    },
  };

  return (
    <div className="fixed left-0 top-0 w-full h-full z-20 bg-black bg-opacity-50 flex justify-center items-center">
      <motion.div
        ref={modalRef}
        variants={modalVariants}
        initial="hidden"
        animate="visible"
        exit="hidden"
        className="scrollbar-hide overflow-auto w-[50%] h-[98vh] px-8 pt-8 pb-6 bg-white rounded-lg relative"
      >
        <div className="flex justify-between font-bold">
          <h1 className="text-2xl">Add Student</h1>

          <CloseIcon
            onClick={handleClose}
            style={{ fontSize: "2rem" }}
            className="text-red-500 cursor-pointer absolute top-2 right-4"
          />
        </div>
        <hr className="mt-2 mb-4" />
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            {jurisdiction === "state" && (
              <FormDropdown
                name="school_state_location"
                label="School's State Location"
                value={schoolStateLocation}
                options={validatorStates}
                onChange={(e) => handleChange(e, setSchoolStateLocation)}
                errorMessage={errors.schoolStateLocation}
                isRequired={true}
              />
            )}
          </div>
          <div className="mb-4">
            <label className="block text-sm font-bold mb-2">School Type</label>
            <input
              type="text"
              name="school_type"
              value={jurisdiction}
              disabled
              onChange={(e) => handleChange(e, setSchoolType)}
              className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              placeholder="Enter school type"
            />
          </div>
          <div className="mb-4">
            <label className="block text-sm font-bold mb-2">School Name</label>
            <input
              type="text"
              name="school_name"
              value={tc_name}
              disabled
              onChange={(e) => handleChange(e, setSchoolName)}
              className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              placeholder="Enter school name"
            />
          </div>

          <div className="mb-4">
            <label className="block text-sm font-bold mb-2">
              Surname<span className=" text-red-600">*</span>
            </label>
            <input
              type="text"
              name="surname"
              value={surname}
              onChange={(e) => handleChange(e, setSurname)}
              placeholder="Enter surname"
              className={`appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline  ${
                errors.surname ? "border-red-500" : "border-gray-300"
              } `}
            />
            {errors.surname && (
              <p className="text-red-500 text-xs mt-1">{errors.surname}</p>
            )}
          </div>

          <div className="mb-4">
            <label className="block text-sm font-bold mb-2">
              First Name <span className=" text-red-600">*</span>
            </label>
            <input
              type="text"
              name="first_name"
              value={firstName}
              onChange={(e) => handleChange(e, setFirstName)}
              className={`appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline  ${
                errors.firstName ? "border-red-500" : "border-gray-300"
              } `}
              placeholder="Enter first name"
            />
            {errors.firstName && (
              <p className="text-red-500 text-xs mt-1">{errors.firstName}</p>
            )}
          </div>
          <div className="mb-4">
            <label className="block text-sm font-bold mb-2">Other Name</label>
            <input
              type="text"
              name="other_name"
              value={otherName}
              onChange={(e) => handleChange(e, setOtherName)}
              className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              placeholder="Enter other name"
            />
          </div>
          <div className="mb-4">
            <label className="block text-sm font-bold mb-2">
              Reg Number <span className=" text-red-600">*</span>
            </label>
            <input
              required
              type="number"
              name="reg_num"
              value={regNum}
              onChange={(e) => handleChange(e, setRegNum)}
              className={`appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline  ${
                errors.regNum ? "border-red-500" : "border-gray-300"
              } `}
              placeholder="Enter reg num"
            />
            {errors.regNum && (
              <p className="text-red-500 text-xs mt-1">{errors.regNum}</p>
            )}
          </div>
          <FormDropdown
            name="class"
            label="Student Class"
            options={studentClassOptions}
            value={studentClass}
            onChange={(e) => handleChange(e, setStudentClass)}
            placeholder="Select an option"
            className="appearance-none border rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            errorMsg={errors.studentClass}
          />
          <div className="mb-4">
            <label className="block text-sm font-bold mb-2">
              Email <span className=" text-red-600">*</span>
            </label>
            <input
              type="text"
              name="email"
              value={email}
              onChange={(e) => handleChange(e, setEmail)}
              className={`appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline  ${
                errors.email ? "border-red-500" : "border-gray-300"
              } `}
              placeholder="Enter email"
            />
            {errors.email && (
              <p className="text-red-500 text-xs mt-1">{errors.email}</p>
            )}
          </div>
          <FormDropdown
            name="occupational_trade"
            label="Select Occupational Trade"
            value={occupationalTrade}
            options={options}
            onChange={(e) => handleChange(e, setOccupationalTrade)}
            placeholder="- Choose -"
            isRequired={true}
            errorMsg={errors.occupationalTrade}
          />
          <div className="mb-4">
            <label className="block text-sm font-bold mb-2">
              Nationality <span className=" text-red-600">*</span>
            </label>
            <input
              type="text"
              name="nationality"
              value={nationality}
              onChange={(e) => handleChange(e, setNationality)}
              className={`appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline  ${
                errors.nationality ? "border-red-500" : "border-gray-300"
              } `}
              placeholder="Enter nationality"
            />
            {errors.nationality && (
              <p className="text-red-500 text-xs mt-1">{errors.nationality}</p>
            )}
          </div>
          <div className="mb-4">
            <label className="block text-sm font-bold mb-2">
              State <span className=" text-red-600">*</span>
            </label>
            <input
              type="text"
              name="state"
              value={state}
              onChange={(e) => handleChange(e, setState)}
              className={`appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline  ${
                errors.state ? "border-red-500" : "border-gray-300"
              } `}
              placeholder="Enter state"
            />
            {errors.state && (
              <p className="text-red-500 text-xs mt-1">{errors.state}</p>
            )}
          </div>
          <div className="mb-4">
            <label className="block text-sm font-bold mb-2">
              Lga <span className=" text-red-600">*</span>
            </label>
            <input
              type="text"
              name="lga"
              value={lga}
              onChange={(e) => handleChange(e, setLga)}
              className={`appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline  ${
                errors.lga ? "border-red-500" : "border-gray-300"
              } `}
              placeholder="Enter lga"
            />
            {errors.lga && (
              <p className="text-red-500 text-xs mt-1">{errors.lga}</p>
            )}
          </div>
          <FormDropdown
            name="sex"
            label="Gender"
            value={sex}
            options={genderOptions}
            onChange={handleSelectChange}
            
            isRequired={true}
            errorMsg={errors.sex}
          />
          <FormDropdown
            name="married"
            label="Married"
            value={married}
            options={marriedOptions}
            onChange={(e) => handleChange(e, setMarried)}
            placeholder="- Choose -"
            isRequired={true}
            errorMsg={errors.sex}
          />

          {/* 
          <div className="mb-4">
            <label className="block text-sm font-bold mb-2">Married</label>
            <input
              type="text"
              name="married"
              value={married}
              onChange={(e) => handleChange(e, setMarried)}
              className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              placeholder="Enter married"
            />
          </div> */}

          <div className="mb-4">
            <label className="block text-sm font-bold mb-2">
              DOB<span className=" text-red-600">*</span>
            </label>
            <input
              type="date"
              name="date_of_birth"
              value={dateOfBirth}
              onChange={(e) => handleChange(e, setDateOfBirth)}
              className={`appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline  ${
                errors.dateOfBirth ? "border-red-500" : "border-gray-300"
              } `}
              placeholder="Enter date of birth"
            />
            {errors.dateOfBirth && (
              <p className="text-red-500 text-xs mt-1">{errors.dateOfBirth}</p>
            )}
          </div>

          <div className="mb-4">
            <label className="block text-sm font-bold mb-2">Home town</label>
            <input
              type="text"
              name="home_town"
              value={homeTown}
              onChange={(e) => handleChange(e, setHomeTown)}
              className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              placeholder="Enter home town"
            />
          </div>
          <div className="mb-4">
            <label className="block text-sm font-bold mb-2">
              Home address <span className=" text-red-600">*</span>
            </label>
            <input
              type="text"
              name="home_address"
              value={homeAddress}
              onChange={(e) => handleChange(e, setHomeAddress)}
              className={`appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline  ${
                errors.homeAddress ? "border-red-500" : "border-gray-300"
              } `}
              placeholder="Enter home address"
            />
            {errors.homeAddress && (
              <p className="text-red-500 text-xs mt-1">{errors.homeAddress}</p>
            )}
          </div>
          <div className="mb-4">
            <label className="block text-sm font-bold mb-2">
              Parent Phone Number
            </label>
            <input
              type="number"
              name="parent_phone_number"
              value={parentPhoneNumber}
              onChange={(e) => handleChange(e, setParentPhoneNumber)}
              className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              placeholder="Enter parent phone number"
            />
          </div>
          <div className="mb-4">
            <label className="block text-sm font-bold mb-2">
              Parent Name <span className=" text-red-600">*</span>
            </label>
            <input
              type="text"
              name="parent_name"
              value={parentName}
              onChange={(e) => handleChange(e, setParentName)}
              className={`appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline  ${
                errors.parentName ? "border-red-500" : "border-gray-300"
              } `}
              placeholder="Enter parent name"
            />
            {errors.parentName && (
              <p className="text-red-500 text-xs mt-1">{errors.parentName}</p>
            )}
          </div>
          <div className="mb-4">
            <label className="block text-sm font-bold mb-2">
              Parent Address<span className=" text-red-600">*</span>
            </label>
            <input
              type="text"
              name="parent_address"
              value={parentAddress}
              onChange={(e) => handleChange(e, setParentAddress)}
              className={`appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline  ${
                errors.parentAddress ? "border-red-500" : "border-gray-300"
              } `}
              placeholder="Enter parent name"
            />
            {errors.parentAddress && (
              <p className="text-red-500 text-xs mt-1">
                {errors.parentAddress}
              </p>
            )}
          </div>
          <div className="mb-4">
            <label className="block text-sm font-bold mb-2">
              NIN<span className=" text-red-600">*</span>
            </label>
            <input
              type="number"
              name="nin"
              value={nin}
              onChange={(e) => handleChange(e, setNin)}
              className={`appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline  ${
                errors.nin ? "border-red-500" : "border-gray-300"
              } `}
              placeholder="Enter nin"
            />
            {errors.nin && (
              <p className="text-red-500 text-xs mt-1">{errors.nin}</p>
            )}
          </div>

          <div className="mb-4">
            <label className="block text-sm font-bold mb-2">Gpa</label>
            <input
              type="number"
              name="gpa"
              value={gpa}
              onChange={(e) => handleChange(e, setGpa)}
              className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              placeholder="Enter gpa"
            />
          </div>

          <div className="mb-4">
            <label className="block text-sm font-bold mb-2">
              Has this Student Graduated?(Yes/No)
              <span className=" text-red-600">*</span>
            </label>
            <div className="flex items-center">
              <input
                type="radio"
                id="yes"
                name="grad_status"
                value="yes"
                onChange={handleRadioChange}
                checked={gradStatus === "yes"}
                className="mr-2"
              />

              <label htmlFor="yes" className="mr-4">
                Yes
              </label>
              <input
                type="radio"
                id="no"
                name="grad_status"
                value="no"
                onChange={handleRadioChange}
                checked={gradStatus === "no"}
                className="mr-2"
              />
              <label htmlFor="no">No</label>
              {errors.gradStatus && (
                <p className="text-red-500 text-xs mt-1">{errors.gradStatus}</p>
              )}
            </div>
          </div>

          <div className="mb-4">
            <label className="block text-sm font-bold mb-2">Year of Cert</label>
            <input
              type="number"
              name="year_of_cert"
              value={yearOfCert}
              onChange={(e) => handleChange(e, setYearOfCert)}
              className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              placeholder="Enter year of cert"
            />
          </div>
          <div className="mb-4">
            <label className="block text-sm font-bold mb-2">Grade Award</label>
            <input
              type="text"
              name="grade_award"
              value={gradeAward}
              onChange={(e) => handleChange(e, setGradeAward)}
              className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              placeholder="Enter grade award"
            />
          </div>
          <div className="mb-4">
            <label className="block text-sm font-bold mb-2">
              Mode of Entry
            </label>
            <input
              type="text"
              name="mode_of_entry"
              value={modeOfEntry}
              onChange={(e) => handleChange(e, setModeOfEntry)}
              className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              placeholder="Enter mode of entry"
            />
          </div>
          <div className="mb-4">
            <label className="block text-sm font-bold mb-2">Department</label>
            <input
              type="text"
              name="dept"
              value={dept}
              onChange={(e) => handleChange(e, setDept)}
              className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              placeholder="Enter department"
            />
          </div>
          <div className="mb-4">
            <label className="block text-sm font-bold mb-2">Entry Year</label>
            <input
              type="number"
              name="entry_year"
              value={entryYear}
              onChange={(e) => handleChange(e, setEntryYear)}
              className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              placeholder="Enter entry year"
            />
          </div>

          <div className="mb-4">
            <label className="block text-sm font-bold mb-2">Entry Level</label>
            <input
              type="text"
              name="entry_level"
              value={entryLevel}
              onChange={(e) => handleChange(e, setEntryLevel)}
              className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              placeholder="Enter entry Level"
            />
          </div>
          <div className="mb-4">
            <label className="block text-sm font-bold mb-2">
              Qualification in view
            </label>
            <input
              type="text"
              name="qualification_in_view"
              value={qualificationInView}
              onChange={(e) => handleChange(e, setQualificationInView)}
              className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              placeholder="Enter qualification in view"
            />
          </div>

          <div className="mb-4">
            <label className="block text-sm font-bold mb-2">Exit Year</label>
            <input
              type="number"
              name="exit_year"
              value={exitYear}
              onChange={(e) => handleChange(e, setExitYear)}
              className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              placeholder="Enter exit year"
            />
          </div>
          <div className="mb-4">
            <label className="block text-sm font-bold mb-2">
              Current Session
            </label>
            <input
              type="text"
              name="current_session"
              value={currentSession}
              onChange={(e) => handleChange(e, setCurrentSession)}
              className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              placeholder="Enter current session"
            />
          </div>
          <div className="mb-4">
            <label className="block text-sm font-bold mb-2">
              Current Semester
            </label>
            <input
              type="text"
              name="current_semester"
              value={currentSemester}
              onChange={(e) => handleChange(e, setCurrentSemester)}
              className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              placeholder="Enter current semester"
            />
          </div>

          <div className="mb-4">
            <label className="block text-sm font-bold mb-2">
              Duration of Study
            </label>
            <input
              type="text"
              name="duration_of_study"
              value={durationOfStudy}
              onChange={(e) => handleChange(e, setDurationOfStudy)}
              className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              placeholder="Enter duration of study"
            />
          </div>

          <div className="mb-4">
            <label className="block text-sm font-bold mb-2">Cgpa</label>
            <input
              type="number"
              name="cgpa"
              value={cgpa}
              onChange={(e) => handleChange(e, setCgpa)}
              className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              placeholder="Enter cgpa"
            />
          </div>

          <FormDropdown
            name="special_needs"
            label="Does Student Have Special Needs?"
            options={[
              { value: "Yes", label: "Yes" },
              { value: "No", label: "No" },
            ]}
            value={specialNeeds}
            onChange={(e) => handleChange(e, setSpecialNeeds)}
            placeholder="Select an option"
            className="appearance-none border rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          />

          <div className="flex justify-center">
            <button
              type="submit"
              className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-active_bg text-base font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:w-auto sm:text-sm"
              disabled={isLoading}
            >
              {isLoading ? "Saving..." : "Save"}
            </button>
          </div>
        </form>
      </motion.div>
    </div>
  );
};

export default ModalStudentForm;
