import UploadFileIcon from "@mui/icons-material/UploadFile";

const FormInput = ({
  type,
  name,
  label,
  value,
  onChange,
  placeholder,
  errorMessage,
  required,
  customStyle,
  isRequired,
}) => {
  return (
    <div className="flex flex-col mb-4">
      <label htmlFor={name} className="font-bold mb-1 text-sm">
        {label} {isRequired && <span className=" text-red-600">*</span>}
      </label>
      <input
        type={type}
        name={name}
        id={name}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        required={required}
        aria-describedby={`${name}-error`}
        className={`appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline  ${
          errorMessage ? "border-red-500" : "border-gray-300"
        } `}
      />

      {errorMessage && (
        <p id={`${name}-error`} style={{ color: "red" }}>
          {errorMessage}
        </p>
      )}
    </div>
  );
};

const FormMediaUpload = ({ type, name, label, value, onChange, fileNames }) => {
  return (
    <div className="h-44 flex flex-col items-center justify-center border-2 border-dashed border-active_bg rounded-lg mb-4 text-sm px-3">
      <label htmlFor={name}>
        <UploadFileIcon sx={{ fontSize: "3rem" }} className="text-active_bg" />
      </label>
      <input
        type={type}
        name={name}
        id={name}
        onChange={onChange}
        className="hidden"
        multiple
      />
      {fileNames &&
        fileNames?.map((fileName, index) => (
          <p key={index} className="text-red-700">
            {fileName}
          </p>
        ))}
      <label className="text-center font-bold">{label}</label>
    </div>
  );
};

const FormRadioInput = ({ type, name, label, checkedValue, onChange }) => {
  return (
    <div className="flex gap-6 mb-4">
      <div className="w-[70%]">
        <label htmlFor={name} className="font-bold text-sm">
          {label}
        </label>
      </div>
      <div className="w-[15%] flex items-center gap-3">
        <div className="flex gap-1">
          <input
            type={type}
            id={`${name}-yes`}
            name={name}
            value="yes"
            checked={checkedValue === "yes"}
            onChange={onChange}
          />
          <label htmlFor="yes">Yes</label>
        </div>
        <div className="flex gap-1">
          <input
            type={type}
            name={name}
            value="no"
            checked={checkedValue === "no"}
            onChange={onChange}
          />
          <label htmlFor="no">No</label>
        </div>
      </div>
    </div>
  );
};

const FormRadioInputBool = ({ type, name, label, checkedValue, onChange }) => {
  return (
    <div className="flex gap-6 mb-4 text-sm">
      <div className="w-[70%]">
        <label htmlFor={name} className="font-bold">
          {label}
        </label>
      </div>
      <div className="w-[30%] flex items-center justify-end gap-3">
        <div className="flex gap-1">
          <input
            type={type}
            id={`${name}-yes`}
            name={name}
            value="true"
            checked={checkedValue === true}
            onChange={() => onChange({ target: { name, value: true } })}
          />
          <label htmlFor={`${name}-yes`}>Yes</label>
        </div>
        <div className="flex gap-1">
          <input
            type={type}
            id={`${name}-no`}
            name={name}
            value="false"
            checked={checkedValue === false}
            onChange={() => onChange({ target: { name, value: false } })}
          />
          <label htmlFor={`${name}-no`}>No</label>
        </div>
      </div>
    </div>
  );
};

export default FormRadioInputBool;

const FormDropdown = ({
  name,
  label,
  options,
  value,
  onChange,
  placeholder,
  isRequired,
  errorMsg,
}) => {
  return (
    <div className="flex justify-between items-center my-4">
      <label htmlFor={name} className="w-1/2 font-bold text-sm">
        {label} {isRequired && <span className=" text-red-600">*</span>}
      </label>
      <select
        name={name}
        value={value}
        onChange={onChange}
        className={`w-1/2 py-2 px-2 text-sm border border-active_bg focus:outline-none focus:ring-active_bg focus:border-active_bg rounded-md ${
          errorMsg ? "border-red-500" : "border-gray-300"
        }`}
      >
        {placeholder && !value && <option value="">{placeholder}</option>}
        {options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
      <br />
      {errorMsg && <p className="text-red-500 text-xs mt-1">{errorMsg}</p>}
    </div>
  );
};

const FormCheckbox = ({ workshops, id, label, checked, onChange, value, isRequired }) => {
  return (
    <div className="ml-3">
      
      <input
        id={id}
        type="checkbox"
        checked={checked}
        onChange={onChange}
        value={value}
        className="mr-2"
      />
      <label htmlFor={id}>{label} </label>
    </div>
  );
};

export {
  FormInput,
  FormMediaUpload,
  FormRadioInput,
  FormDropdown,
  FormCheckbox,
};
