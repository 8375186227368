import { useQuery, useQueries } from "@tanstack/react-query";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  fetchAllPDO2Posts,
  fetchAllPDO2PostsByJurisdiction,
  fetchAllPDO3Posts,
  fetchAllPDO3PostsByJurisdiction,
} from "../utils/apiContext";
import { setPdo2Post, setPdo3Post } from "../features/auth/isPostMade";

export const usePdo2Post = (jurisdiction, tc_name) => {
  const dispatch = useDispatch();

  const {
    data: allJurisdictionPosts,
    isLoading: jurisdictionIsLoading,
    error: jurisdictionError,
  } = useQuery({
    queryKey:
      jurisdiction === "state" ? ["pdo2_Jurisdiction"] : ["pdo2b_Jurisdiction"],
    queryFn: () => fetchAllPDO2PostsByJurisdiction(jurisdiction),
  });

  useEffect(() => {
    const isAvailable = allJurisdictionPosts?.some(
      (post) => post?.tc_name === tc_name
    );
    if (isAvailable) {
      dispatch(setPdo2Post(true));
    } else {
      dispatch(setPdo2Post(false));
    }
    // console.log(isAvailable);
    dispatch(setPdo2Post(isAvailable));
  }, [allJurisdictionPosts, dispatch, tc_name]);

  return { jurisdictionIsLoading, jurisdictionError, allJurisdictionPosts };
};

export const usePdo3Post = (jurisdiction, tc_name) => {
  const dispatch = useDispatch();

  const {
    data: allJurisdictionPosts,
    isLoading: jurisdictionIsLoading,
    error: jurisdictionError,
  } = useQuery({
    queryKey:
      jurisdiction === "state" ? ["pdo3_Jurisdiction"] : ["pdo3b_Jurisdiction"],
    queryFn: () => fetchAllPDO3PostsByJurisdiction(jurisdiction),
  });

  useEffect(() => {
    const isAvailable = allJurisdictionPosts?.some(
      (post) => post?.tc_name === tc_name
    );
    if (isAvailable) {
      dispatch(setPdo3Post(true));
    } else {
      dispatch(setPdo3Post(false));
    }
    // console.log(isAvailable);
  }, [allJurisdictionPosts, dispatch, tc_name]);

  return { jurisdictionIsLoading, jurisdictionError, allJurisdictionPosts };
};
