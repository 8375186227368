import React, { useState } from "react";
import PageHeader from "../../../Components/page_header";
import {
  Tabs,
  Tab,
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { useSelector } from "react-redux";
import { selectCurrentToken } from "../../../features/auth/authSlice";
import { fetchUserRoles } from "../../../utils/apiContext";

const ProfileTable = ({ data }) => {
  if (!data || data.length === 0) return <p>No profiles available.</p>;

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>
              <Typography variant="h6" component="div">
                Name
              </Typography>
            </TableCell>
            <TableCell>
              <Typography variant="h6" component="div">
                Email
              </Typography>
            </TableCell>
            <TableCell>
              <Typography variant="h6" component="div">
                Contact Information
              </Typography>
            </TableCell>
            <TableCell>
              <Typography variant="h6" component="div">
                Jurisdiction
              </Typography>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((profile, index) => (
            <TableRow key={index}>
              <TableCell>
                {profile.user.first_name} {profile.user.last_name}
              </TableCell>
              <TableCell>{profile.user.email}</TableCell>
              <TableCell>{profile.phone_number || "N/A"}</TableCell>
              <TableCell>{profile.jurisdiction || "N/A"}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const ViewUsers = () => {
  const [tabIndex, setTabIndex] = useState(0);
  const token = useSelector(selectCurrentToken);

  const roles = ["M&E", "Validator", "Reviewer", "National Admin"];
  const selectedRole = roles[tabIndex];

  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  const {
    data: userRoleData,
    isLoading,
    error,
  } = useQuery({
    queryKey: ["userRoleData", selectedRole],
    queryFn: () => fetchUserRoles(token, selectedRole), // Fetch based on selected role
  });

  return (
    <Box
      sx={{ width: "100%", backgroundColor: "#f5f5f5", borderRadius: "8px" }}
    >
      <PageHeader title="View User Profiles" />
      <Tabs
        value={tabIndex}
        onChange={handleTabChange}
        TabIndicatorProps={{
          style: {
            backgroundColor: "#0E4F2F",
            height: "5px",
            borderRadius: "1rem",
          },
        }}
        sx={{
          display: "flex",
          justifyContent: "space-around",
          marginBottom: "12px",
        }}
      >
        {roles.map((role, index) => (
          <Tab
            key={role}
            label={role}
            sx={{
              textTransform: "capitalize",
              fontSize: "15px",
              color: "#9BB6A8",
              fontWeight: "bold",
              flex: 1,
              "&.Mui-selected": {
                color: "#0E4F2F",
              },
            }}
          />
        ))}
      </Tabs>
      <Box
        sx={{
          padding: "2rem",
          backgroundColor: "white",
          borderRadius: "8px",
          marginTop: "1rem",
        }}
      >
        {isLoading && <p>Loading...</p>}
        {error && <p>Error loading profiles</p>}
        {!isLoading && !error && <ProfileTable data={userRoleData?.results || []} />}
      </Box>
    </Box>
  );
};

export default ViewUsers;
