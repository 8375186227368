import React, { useState } from "react";
import { PDOHeaders } from "../../../../Models/ComponentOne/PDO/data";
import ArrowDropDownCircleIcon from "@mui/icons-material/ArrowDropDownCircle";

import { PdoPreview2, PdoPreview3 } from "./models/pdo-data";
import { Button } from "@mui/material";
import Pdo2Display from "../displays/pdo/Pdo2";
import Pdo3Display from "../displays/pdo/Pdo3";

const PdoPreview = ({ data, propsJurisdiction }) => {
  const [showAnswers, setShowAnswers] = useState({});
  const propsTc_name = data?.institution_name;
  const displayAnswers = (index) => {
    setShowAnswers((prev) => ({
      ...prev,
      [index]: !prev[index],
    }));
  };
  return (
    <div>
      {PDOHeaders.map((item, index) => (
        <div key={item.id} className=" text-primary_text cursor-pointer">
          <div
            onClick={() => {
              displayAnswers(index);
            }}
            className="flex justify-between items-center border-2 text-md border-indicator_border mb-2 rounded-md px-4 h-16 relative"
            style={{ fontFamily: "Poppins, sans-serif" }}
          >
            <div className="w-[85%] text-md font-bold">{item.header}</div>
            {!showAnswers[index] ? (
              <ArrowDropDownCircleIcon
                sx={{ fontSize: "30px", color: "#0E4F2F" }}
              />
            ) : (
              <ArrowDropDownCircleIcon
                sx={{
                  fontSize: "30px",
                  color: "#0E4F2F",
                  transform: "rotate(180deg)",
                }}
              />
            )}
          </div>

          {showAnswers[index] && (
            <div
              className={`relative ${index === 0 && "h-fit"} ${
                index === 1 && "h-[72vh]"
              } ${index === 2 && "h-[65vh]"}`}
            >
              {index === 0 && (
                <h1 className="text-center font-bold">
                  nothing to display yet
                </h1>
              )}
              {index === 1 && (
                <Pdo2Display
                  questions={PdoPreview2}
                  propsTc_name={propsTc_name}
                  propsJurisdiction={propsJurisdiction}
                />
              )}
              {index === 2 && (
                <Pdo3Display
                  questions={PdoPreview3}
                  propsTc_name={propsTc_name}
                  propsJurisdiction={propsJurisdiction}
                />
              )}
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default PdoPreview;
