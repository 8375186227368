import React, { useEffect, useRef, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { motion } from "framer-motion";
import {
  FormCheckbox,
  FormDropdown,
  FormInput,
} from "../../../Components/form";
import CustomButton from "../../../Components/button";
import { toast } from "sonner";
import { useMutation } from "react-query";
import axiosInstance from "../../../Services/axiosInstance";
import { stateOptions } from "./stateOptions";

const certificationLabels = ["Assessor", "Verifier"];

const TraineesComponent2Form = ({ closeForm, title }) => {
  const modalRef = useRef(null);

  const [nameOfBusiness, setNameOfBusiness] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [gender, setGender] = useState("");
  const [age, setAge] = useState("");
  const [skill, setSkill] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [address, setAddress] = useState("");
  const [stateOfOrgin, setStateOfOrigin] = useState("");

  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState({});

  const mutation = useMutation({
    mutationFn: async (formData) => {
      setIsLoading(true);
      const response = await axiosInstance.post(`/trainees/c2/create-trainee`, formData);
      console.log(response.data);
      return response;
    },
    onSuccess: (response) => {
      toast.success("Successful");
      setIsLoading(false);
      closeForm();
    },
    onError: (err) => {
      setIsLoading(false);
      closeForm();
    },
  });

  const handleInputChange = (e, setValue) => {
    setValue(e.target.value);
    setErrors({ ...errors, [e.target.name]: "" });
  };

  const validate = () => {
    const newErrors = {};
    const requiredFields = {
      nameOfBusiness,
      firstName,
      lastName,
      phoneNumber,
    };

    Object.keys(requiredFields).forEach((field) => {
      if (!requiredFields[field]) {
        newErrors[field] = "This field is required";
      }
    });

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!validate()) {
      toast.error("Required Fields can't be empty");
      return;
    }

    const newObj = {
      business_name: nameOfBusiness,
      first_name: firstName,
      last_name: lastName,
      gender: gender,
      age: age,
      skill: skill,
      phone_number: phoneNumber,
      email: email,
      address: address,
      state_of_origin: stateOfOrgin,
    };
    mutation.mutate(newObj);
    console.log("formObject:", newObj);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        closeForm(); // Assuming closeForm sets the state that controls the modal visibility
      }
    };
    document.addEventListener("mousedown", handleClickOutside);

    // Remove event listener on cleanup
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [closeForm]);
  const modalVariants = {
    hidden: {
      y: "-100vh",
      opacity: 0,
    },
    visible: {
      y: "0",
      opacity: 1,
      transition: { delay: 0.2, type: "spring", stiffness: 120 },
    },
  };
  return (
    <div className="fixed left-0 top-0 w-full h-full bg-black bg-opacity-50 flex justify-center items-center z-20">
      <motion.div
        ref={modalRef}
        variants={modalVariants}
        initial="hidden"
        animate="visible"
        exit="hidden"
        className="scrollbar-hide overflow-auto w-[50%] h-[98vh] px-5 pt-8 pb-6 bg-white rounded-lg relative"
      >
        <div className="flex justify-between items-center font-bold">
          <h1 className="text-2xl">{title}</h1>

          <CloseIcon
            onClick={closeForm}
            sx={{ fontSize: "2rem" }}
            fontWeight={"30"}
            className="text-red-500 cursor-pointer absolute top-2 right-4"
          />
        </div>
        <hr className="mt-2 mb-4" />
        <form onSubmit={handleSubmit}>
          <FormInput
            type="text"
            name="business_name"
            label="Business Name"
            isRequired={true}
            value={nameOfBusiness}
            onChange={(e) => handleInputChange(e, setNameOfBusiness)}
          />
          {errors.nameOfBusiness && (
            <p className="text-red-500 text-xs mt-1">{errors.nameOfBusiness}</p>
          )}
          <div className="flex justify-between gap-2">
            <div className="w-full">
              <FormInput
                type="text"
                name="first_name"
                label="First Name"
                isRequired={true}
                value={firstName}
                onChange={(e) => handleInputChange(e, setFirstName)}
              />
              {errors.firstName && (
                <p className="text-red-500 text-xs mt-1">{errors.firstName}</p>
              )}
            </div>
            <div className="w-full">
              <FormInput
                type="text"
                name="last_name"
                label="Last Name"
                isRequired={true}
                value={lastName}
                onChange={(e) => handleInputChange(e, setLastName)}
              />
              {errors.lastName && (
                <p className="text-red-500 text-xs mt-1">{errors.lastName}</p>
              )}
            </div>
          </div>
          <div className="flex justify-between items-center gap-2">
            <div className="w-full">
              <FormDropdown
                name="gender"
                label="Gender"
                value={gender}
                options={[
                  { value: "M", label: "Male" },
                  { value: "F", label: "Female" },
                ]}
                onChange={(e) => handleInputChange(e, setGender)}
                placeholder="- Choose -"
                
              />
            </div>
            <div className="w-full">
              <FormInput
                type="number"
                name="age"
                label="Age"
                value={age}
                onChange={(e) => handleInputChange(e, setAge)}
              />
            </div>
          </div>
          <FormInput
            type="tel"
            name="phone_number"
            label="Phone Number"
            value={phoneNumber}
            isRequired={true}
            onChange={(e) => handleInputChange(e, setPhoneNumber)}
          />
          {errors.phoneNumber && (
            <p className="text-red-500 text-xs mt-1">{errors.phoneNumber}</p>
          )}
          <FormInput
            type="email"
            name="email"
            label="Email"
            value={email}
            onChange={(e) => handleInputChange(e, setEmail)}
          />
          <FormDropdown
            name="state_of_origin"
            label="State of Origin"
            value={stateOfOrgin}
            onChange={(e) => handleInputChange(e, setStateOfOrigin)}
            options={stateOptions}
          />
          <FormInput
            type="text"
            name="address"
            label="Address"
            value={address}
            onChange={(e) => handleInputChange(e, setAddress)}
          />
          <FormInput
            type="text"
            name="skill"
            label="Skill"
            value={skill}
            onChange={(e) => handleInputChange(e, setSkill)}
          />

          <br />
          <div className="flex justify-center">
            <CustomButton className="bg-active_bg" isLoading={isLoading}>
              {isLoading ? "Submiting..." : "Submit"}
            </CustomButton>
          </div>
        </form>
      </motion.div>
    </div>
  );
};

export default TraineesComponent2Form;
