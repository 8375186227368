import { useEffect, useRef, useState } from "react";
import {
  FormRadioInput,
  FormMediaUpload,
  FormInput,
  FormCheckbox,
  FormDropdown,
} from "../../../form";
import CloseIcon from "@mui/icons-material/Close";
import CustomButton from "../../../button";
import { motion } from "framer-motion";
import { useDispatch, useSelector } from "react-redux";
import { jwtDecode } from "jwt-decode";
import { useMutation } from "@tanstack/react-query";
import axiosInstance from "../../../../Services/axiosInstance";
import { toast } from "sonner";
import {
  selectCurrentJurisdiction,
  selectCurrentUser,
} from "../../../../features/auth/authSlice";
import { setIoi4Post } from "../../../../features/auth/isPostMade";
import { validatorStates } from "../states";

const workshopLabels = [
  "Construction Workshops",
  "Mechanical Engineering Workshops",
  "Hospitality Workshops",
  "Electrical Engineering Workshops",
  "ICT Labs",
  "Agricultural Science Workshop",
  "Garment Making Workshop",
];

const IntermediateOutcome4 = ({ title, closeForm }) => {
  const modalRef = useRef(null);
  const authToken = useSelector((state) => state.auth.token);
  const decodedTkn = jwtDecode(authToken);

  const jurisdiction = useSelector(selectCurrentJurisdiction);
  const email = useSelector(selectCurrentUser);
  const [errors, setErrors] = useState({});
  const { tc_name } = decodedTkn;

  const [state, setState] = useState("");
  const [workshops, setWorkshops] = useState([]);
  const [initialDisbursement, setInitialDisbursement] = useState("");
  const [cdpReceived, setCdpReceived] = useState("");
  const [cdpApproved, setCdpApproved] = useState("");
  const [workshopsRenovated, setWorkshopsRenovated] = useState();
  const [workshopsEquipped, setWorkshopsEquipped] = useState();
  const [trainingOfTeachers, setTrainingOfTeachers] = useState("");
  const [noOfTeachersTrained, setNoOfTeachersTrained] = useState();
  const [disbursementReceivedPdf, setDisbursementReceivedPdf] = useState(null);
  const [statusReportEquipmentPdf, setStatusReportEquipmentPdf] =
    useState(null);
  const [statusReportTrainingPdf, setStatusReportTrainingPdf] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useDispatch();
  const validate = () => {
    const newErrors = {};
    const requiredFields = {};

    if (jurisdiction === "state") {
      requiredFields.state = state;
    }

    Object.keys(requiredFields).forEach((field) => {
      if (!requiredFields[field]) {
        newErrors[field] = "This field is required";
      }
    });

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };
  const mutation = useMutation({
    mutationFn: async (formData) => {
      setIsLoading(true);
      const response = await axiosInstance.post(
        "/ioi/c1/make-post-no-of-fully-functioning-upgraded-workshops-in-supported-tc",
        formData
      );
      return response;
    },
    onSuccess: (response) => {
      toast.success("Successful");
      setIsLoading(false);
      const isCheck = response.data.newDoc;
      const letSee = `${jurisdiction}_tc`;
      const isPostMade = isCheck[letSee].some(
        (post) => post.tc_name === tc_name
      );
      console.log(isPostMade);
      if (isPostMade) {
        dispatch(setIoi4Post(true));
      } else {
        dispatch(setIoi4Post(false));
      }
      closeForm();
    },
    onError: (err) => {
      if (err.response.status === 400) {
        toast.error("Post already exists");
      } else {
        toast.error("An Error Occurred");
      }
      setIsLoading(false);
      closeForm();
    },
  });

  const handleRadioChange = (event) => {
    const { name, value } = event.target;

    switch (name) {
      case "initial_disbursement_of_250kusd_received":
        setInitialDisbursement(value);
        break;
      case "cdp_received_by_the_npcu":
        setCdpReceived(value);
        break;
      case "cdp_approved_by_the_world_bank":
        setCdpApproved(value);
        break;
      case "training_of_ttis_on_the_use_of_newly_installed_tools":
        setTrainingOfTeachers(value);
        break;
      default:
        break;
    }
  };

  const handleFileChange = (e, setFile) => {
    setFile(e.target.files[0]); // Store a single file for each FormMediaUpload
  };

  const handleInputChange = (e, setValue) => {
    setValue(e.target.value);
  };

  const handleCheckboxChange = (event) => {
    const { value, checked } = event.target;

    setWorkshops((prevWorkshops) => {
      if (checked && prevWorkshops.length < 3) {
        return [...prevWorkshops, value];
      } else if (!checked) {
        return prevWorkshops.filter((workshop) => workshop !== value);
      } else {
        return prevWorkshops; // Prevent adding more than 3
      }
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    if (!validate()) {
      toast.error("Required Fields can't be empty");
      return;
    }
    const formData = new FormData();
    formData.append("state", state);
    formData.append("tc_name", tc_name);
    formData.append("jurisdiction", jurisdiction);
    formData.append("email", email);
    formData.append(
      "initial_disbursement_of_250kusd_received",
      initialDisbursement
    );
    formData.append("cdp_received_by_the_npcu", cdpReceived);
    formData.append("cdp_approved_by_the_world_bank", cdpApproved);
    formData.append("no_of_workshops_renovated", workshopsRenovated);
    formData.append(
      "no_of_workshops_equipped_with_modern_tools_and_ready_for_use",
      workshopsEquipped
    );
    formData.append(
      "training_of_ttis_on_the_use_of_newly_installed_tools",
      trainingOfTeachers
    );
    formData.append(
      "no_of_ttis_trained_on_the_use_of_newly_installed_tools",
      noOfTeachersTrained
    );
    formData.append("workshops", workshops.join(","));
    formData.append(
      "doc_confirming_disbursment_received_pdf",
      disbursementReceivedPdf
    );
    formData.append("status_report_pdf", statusReportEquipmentPdf);
    formData.append("ttis_status_report_pdf", statusReportTrainingPdf);

    mutation.mutate(formData);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        closeForm();
      }
    };

    // Add event listener when the component mounts
    document.addEventListener("mousedown", handleClickOutside);

    // Remove event listener on cleanup
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [closeForm]);

  const modalVariants = {
    hidden: {
      y: "-100vh",
      opacity: 0,
    },
    visible: {
      y: "0",
      opacity: 1,
      transition: { delay: 0.2, type: "spring", stiffness: 120 },
    },
  };

  return (
    <div className="fixed left-0 top-0 w-full h-full bg-black bg-opacity-50 flex justify-center items-center z-20">
      <motion.div
        ref={modalRef}
        variants={modalVariants}
        initial="hidden"
        animate="visible"
        exit="hidden"
        className="scrollbar-hide overflow-auto w-[50%] h-[98vh] px-5 pt-8 pb-6 bg-white rounded-lg relative"
      >
        <div className="flex justify-between items-center font-bold">
          <h1 className="text-2xl">{title}</h1>

          <CloseIcon
            onClick={closeForm}
            sx={{ fontSize: "2rem" }}
            fontWeight={"30"}
            className="text-red-500 cursor-pointer absolute top-2 right-4"
          />
        </div>
        <hr className="mt-2 mb-4" />
        <form onSubmit={handleSubmit}>
        {jurisdiction === "state" && (
            <FormDropdown
              name="state"
              label="Enter State Location"
              value={state}
              options={validatorStates}
              onChange={(e) => handleInputChange(e, setState)}
              errorMessage={errors.state}
            />
          )}
          {errors.state && (
            <p className="text-red-500 text-xs mt-1">{errors.state}</p>
          )}
          <div className="mb-8">
            <p className="font-bold">Select Workshop</p>
            {workshopLabels.map((label, index) => (
              <FormCheckbox
                key={index}
                id={`checkbox-${index}`}
                label={label}
                value={label}
                // workshops={formData.workshops}
                checked={workshops.includes(label)}
                onChange={handleCheckboxChange}
              />
            ))}
            {workshops.length >= 3 && (
              <p className=" text-orange-500 text-sm">
                Note: You can only select up to 3 workshops.
              </p>
            )}
          </div>

          <FormRadioInput
            type="radio"
            name="initial_disbursement_of_250kusd_received"
            label="Initial disbursement of 250,000 USD received by the school?"
            checkedValue={initialDisbursement}
            onChange={handleRadioChange}
          />

          <FormMediaUpload
            type="file"
            id="doc_confirming_disbursment_received_pdf"
            name="doc_confirming_disbursment_received_pdf"
            label="Upload Your account Statement or TC Record Showing this credit (file max size 40MB)"
            onChange={(e) => handleFileChange(e, setDisbursementReceivedPdf)}
            fileName={disbursementReceivedPdf?.name}
          />
          <FormRadioInput
            type="radio"
            name="cdp_received_by_the_npcu"
            label="CDP reviewed by the NPCU"
            checkedValue={cdpReceived}
            onChange={handleRadioChange}
          />
          <FormRadioInput
            type="radio"
            name="cdp_approved_by_the_world_bank"
            label="CDP approved by the World Bank"
            checkedValue={cdpApproved}
            onChange={handleRadioChange}
          />
          <FormInput
            type="number"
            name="no_of_workshops_renovated"
            label="Number of workshops renovated"
            value={workshopsRenovated}
            onChange={(e) => handleInputChange(e, setWorkshopsRenovated)}
          />
          <FormInput
            type="number"
            name="no_of_workshops_equipped_with_modern_tools_and_ready_for_use"
            label="Number of workshops equipped with modern tools/equipment and ready for use"
            value={workshopsEquipped}
            onChange={(e) => handleInputChange(e, setWorkshopsEquipped)}
          />
          <FormMediaUpload
            type="file"
            name="status_report_pdf"
            label="Upload Updated Status Report Showing a list of all renovated and equipped workshops/labs, including picture evidence (file max size 40MB)"
            onChange={(e) => handleFileChange(e, setStatusReportEquipmentPdf)}
            fileName={statusReportEquipmentPdf?.name}
          />
          <FormRadioInput
            type="radio"
            name="training_of_ttis_on_the_use_of_newly_installed_tools"
            label="Training of Technical Teachers and Instructors (TTIs) on the use of newly installed tools/Equipment"
            checkedValue={trainingOfTeachers}
            onChange={handleRadioChange}
          />
          <FormInput
            type="number"
            name="no_of_ttis_trained_on_the_use_of_newly_installed_tools"
            label="Number of Technical Teachers and Instructors (TTIs) trained on the use of newly installed tools/Equipment"
            value={noOfTeachersTrained}
            onChange={(e) => handleInputChange(e, setNoOfTeachersTrained)}
          />
          <FormMediaUpload
            type="file"
            name="ttis_status_report_pdf"
            label="Upload Updated Status Report Showing a list of all TTIs that have been trained on the use of newly installed tools/equipment (PDF file max size 40MB"
            onChange={(e) => handleFileChange(e, setStatusReportTrainingPdf)}
            fileName={statusReportTrainingPdf?.name}
          />
          <div className="flex justify-center">
            <CustomButton className="bg-active_bg" isLoading={isLoading}>
              {isLoading ? "Submiting..." : "Submit"}
            </CustomButton>
          </div>
        </form>
      </motion.div>
    </div>
  );
};

export default IntermediateOutcome4;
