import IdeasLogo from "../Assets/ideas_logo.svg";
import { initialNavListItems } from "../Data_Objects/sidenav";
import { useLocation, Outlet, Link } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  selectCurrentToken,
  selectCurrentUserRole,
} from "../features/auth/authSlice";
import { getFormattedToken } from "../utils/getFormattedToken";

const SideNav = () => {
  const location = useLocation();
  // const userRoles = useSelector((state) => state.userRole.roles);
  const userRoles = useSelector(selectCurrentUserRole);
  const routeTkn = useSelector(selectCurrentToken);

  const navListItems = initialNavListItems.map((item) => {
    if (item.path === "/analytics") {
      if (userRoles.includes("M_E_OFFICE")) {
        try {
          const formattedRoute = getFormattedToken(routeTkn);
          return { ...item, path: `/m_e_officer/analytics/${formattedRoute}` };
        } catch (error) {
          console.error("Error formatting token:", error);
          return item;
        }
      } else if (userRoles.includes("Validator")) {
        return { ...item, path: `/validator/analytics` };
      } else if (userRoles.includes("Reviewer")) {
        return { ...item, path: `/reviewer/analytics` };
      } else if (userRoles.includes("National_Admin")) {
        return { ...item, path: `/national_admin/analytics` };
      } else if (userRoles.includes("Super_Admin")) {
        return { ...item, path: `/super_admin/analytics` };
      } else if (userRoles.includes("Pmu")) {
        return {...item, path: `/pmu/analytics`}
      }
    }
    return item;
  });

  const hasRole = (roles) => {
    return roles.some((role) => userRoles.includes(role));
  };

  return (
    <div className="flex h-screen border-[6px] border-main_bg ">
      <nav className="w-60 bg-white flex flex-col shadow-md ">
        <div className="h-20 mx-auto mt-4 mb-2">
          <img src={IdeasLogo} alt="Ideas Logo" className="w-auto" />
        </div>

        <div className="scrollbar scrollbar-w-2  scrollbar-track-gray-100 scrollbar-h-1.5 scrollbar-thumb-rounded-[50px] scrollbar-track-rounded-[50px] scrollbar-thumb-[#E3E2E2] overflow-y-scroll">
          <div className="flex flex-col ">
            {navListItems.map((list) => {
              const isAllowed = hasRole(list.roles);
              return (
                <Link key={list.path} to={isAllowed ? list.path : "#"}>
                  <div
                    className={`w-[98%] flex items-center pl-8 mb-6 py-2 gap-4 mt-2 ${
                      location.pathname === list.path
                        ? "bg-main_bg text-white"
                        : `${
                            isAllowed
                              ? "text-primary_text hover:bg-gray-100"
                              : "text-disabled hidden"
                          }`
                    } rounded-r-md cursor-pointer transition-colors duration-300`}
                    style={{ fontFamily: "Poppins, sans-serif" }}
                  >
                    {list.icon}
                    <p
                      className={`text-sm  ${
                        location.pathname === list.path
                          ? "text-white"
                          : `${
                              isAllowed
                                ? "text-primary_text"
                                : "text-disabled hidden"
                            }`
                      }`}
                    >
                      {list.text}
                    </p>
                  </div>
                </Link>
              );
            })}
          </div>
        </div>
      </nav>
      <div className="scrollbar scrollbar-w-2.5 scrollbar-h-1.5 scrollbar-thumb-rounded-[10px] scrollbar-track-rounded-[10px] scrollbar-thumb-[#E3E2E2] overflow-y-scroll flex-1  bg-page_bg rounded-sm shadow-md pb-2">
        <Outlet />
      </div>
    </div>
  );
};

export default SideNav;
