import React, { useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useSelector } from "react-redux";
import { selectCurrentUserRole } from "../../../../features/auth/authSlice";
import { Button } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import DashIcon from "@mui/icons-material/Remove";
import RejectDataEntry from "../../../../Components/Modal_Forms/RejectMessage/Reject";

function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}

const tableHeaders = [
  {
    id: 1,
    activity: "GBV Sensitization",
    key: "gbv_sensitization",
  },
  {
    id: 2,
    activity: "Installation of Solar Power System",
    key: "installation_of_solar_power_system",
  },
  {
    id: 3,
    activity: "Installation of Solar Powered Streetlights",
    key: "installation_of_solar_powered_streetlights",
  },
  {
    id: 4,
    activity:
      "Procurement and Installations of ICT Equipment Internet Infrastructure",
    key: "procurement_and_installations_of_ICT_equipment_internet_infrastructure",
  },
  {
    id: 5,
    activity: "Procurement of Diesel Generator",
    key: "procurement_of_diesel_generator",
  },
  {
    id: 6,
    activity: "Procurement of Office Furniture and Equipment for IDEAS Office",
    key: "procurement_of_office_furniture_and_equipment_for_ideas_office",
  },
  {
    id: 7,
    activity: "Procurement of Project Vehicle",
    key: "procurement_of_project_vehicle",
  },
  {
    id: 8,
    activity: "Provision of Solar Powered Borehole and Water Reticulation",
    key: "provision_of_solar_powered_borehole_and_water_reticulation",
  },
  {
    id: 9,
    activity: "Renovation and Upgrading of Toilet Facility",
    key: "renovation_and_upgrading_of_toilet_facility",
  },
  {
    id: 10,
    activity: "Renovation of ICT Laboratory",
    key: "renovation_of_ICT_laboratory",
  },
  {
    id: 11,
    activity: "Renovation of Classrooms",
    key: "renovation_of_classrooms",
  },
  {
    id: 12,
    activity: "Renovation of College Library",
    key: "renovation_of_college_library",
  },
  {
    id: 13,
    activity: "Renovation of IDEAS Project Office",
    key: "renovation_of_ideas_project_office",
  },
  {
    id: 14,
    activity: "Government Technical College, Afaraukwu",
    key: "tc_name",
  },
  {
    id: 15,
    activity: "TVET Sensitization",
    key: "tvet_sensitization",
  },
];

const GeneralActivitiesData = ({ data, propsJurisdiction }) => {
  const [rejectionReason1, setRejectionReason1] = useState("");
  const [openRejectModal1, setOpenRejectModal1] = useState(false);

  const userRoles = useSelector(selectCurrentUserRole);

  const handleReject1 = () => {
    setOpenRejectModal1(true);
  };

  const handleCloseRejectModal1 = () => {
    setOpenRejectModal1(false);
    setRejectionReason1("");
  };

  const handleRejectReasonChange1 = (event) => {
    setRejectionReason1(event.target.value);
  };

  const handleSubmit1 = () => {
    setOpenRejectModal1(false);
    setRejectionReason1("");
  };

  if(!data) {
    return <p>No data to display!</p>
  }

  return (
    <div className="relative pb-12">
      <div className="">
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell
                  sx={{
                    whiteSpace: "nowrap",
                    width: "1%",
                    fontWeight: "bold",
                    color: "#2D3142",
                  }}
                >
                  Activity
                </TableCell>
                <TableCell
                  sx={{
                    whiteSpace: "nowrap",
                    width: "1%",
                    fontWeight: "bold",
                    color: "#2D3142",
                  }}
                >
                  Status
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {tableHeaders.map((item) => (
                <TableRow key={item.id}>
                  <TableCell>{item.activity}</TableCell>
                  <TableCell>
                    {data[item.key] === "yes" ? (
                      <CheckIcon
                        style={{ color: "green", fontWeight: "bold" }}
                      />
                    ) : (
                      <DashIcon style={{ color: "red" }} />
                    )}
                    
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        <RejectDataEntry
          openRejectModal1={openRejectModal1}
          handleCloseRejectModal1={handleCloseRejectModal1}
          rejectionReason1={rejectionReason1}
          handleRejectReasonChange1={handleRejectReasonChange1}
          handleSubmit1={handleSubmit1}
        />
      </div>
      <br />
      {userRoles === "Reviewer" && (
        <div className="text-active_bg rounded-md text-sm absolute bottom-0 right-6 flex gap-3">
          <Button
            onClick={handleReject1}
            variant="outlined"
            color="error"
            sx={{
              bgcolor: "#F44336",
              color: "white",
              textTransform: "capitalize",
              "&:hover": { bgcolor: "#F44336" },
            }}
          >
            Reject
          </Button>
          <Button
            variant="contained"
            color="success"
            sx={{
              bgcolor: "#0E4F2F",
              color: "#FFFFFF",
              textTransform: "capitalize",
            }}
          >
            Approve
          </Button>
        </div>
      )}{" "}
      {userRoles === "Validator" && (
        <div className="text-active_bg rounded-md text-sm absolute right-8 flex gap-3">
          <Button
            onClick={handleReject1}
            variant="outlined"
            color="error"
            sx={{
              bgcolor: "#F44336",
              color: "white",
              textTransform: "capitalize",
              "&:hover": { bgcolor: "#F44336" },
            }}
          >
            Reject
          </Button>
          <Button
            variant="contained"
            color="success"
            sx={{
              bgcolor: "#0E4F2F",
              color: "#FFFFFF",
              textTransform: "capitalize",
            }}
          >
            Validate
          </Button>
        </div>
      )}{" "}
    </div>
  );
};

export default GeneralActivitiesData;
