import React, { useState } from "react";
import ArrowDropDownCircleIcon from "@mui/icons-material/ArrowDropDownCircle";
import { Tab, Tabs } from "@mui/material";
import IoiPreview from "../Pages/Component1/Preview/table_components/Ioi";
import PdoPreview from "../Pages/Component1/Preview/table_components/Pdo";
import { toSentenceCase } from "../utils/toSentenceCase";
import GeneralActivitiesData from "../Pages/Component1/Preview/table_components/GenActivitiesData";
import { useQueries, useQuery } from "@tanstack/react-query";
import {
  fecthGenActivities,
  fecthGenActivitiesById,
} from "../utils/apiContext";
import { useSelector } from "react-redux";
import { selectCurrentJurisdiction } from "../features/auth/authSlice";
import { toast } from "sonner";

const ReviewList = ({ data, propsJurisdiction }) => {
  const [showAnswers1, setShowAnswers1] = useState(false);
  const [tabIndex1, setTabIndex1] = useState(0);
  const jurisdiction = useSelector(selectCurrentJurisdiction);

  const {
    data: allGenActivities,
    isLoading,
    error,
  } = useQuery({
    queryKey: ["generalActivitiesData"],
    queryFn: () => fecthGenActivities(jurisdiction),
  });

  const specificPost = allGenActivities?.filter(
    (post) => post?.tc_name === data?.institution_name
  );

  const displayAnswers1 = () => {
    setShowAnswers1(!showAnswers1);
    // fetchDatas();
  };

  const handleChange1 = (event, newValue) => {
    setTabIndex1(newValue);
  };

  if (error) {
    toast.error("Something went wrong, try again");
  }

  return (
    <div className="px-3 ">
      <div
        className=" text-primary_text cursor-pointer mt-4"
        onClick={displayAnswers1}
      >
        <div
          className="flex justify-between items-center border-2 border-indicator_border mb-2 rounded-md px-4 h-16"
          style={{
            fontFamily: "Poppins, sans-serif",
            backgroundColor: "white",
          }}
        >
          <div className="w-[85%] text-md font-bold">
            {toSentenceCase(data.institution_name)}
          </div>

          <ArrowDropDownCircleIcon
            sx={{
              fontSize: "30px",
              color: "#0E4F2F",
              transform: showAnswers1 ? "rotate(180deg)" : "",
            }}
          />
        </div>
      </div>
      {showAnswers1 && (
        <div className="bg-white shadow-lg pt-2 pb-4 border border-main_bg">
          <Tabs
            value={tabIndex1}
            onChange={handleChange1}
            TabIndicatorProps={{
              style: {
                backgroundColor: "#0E4F2F",
                height: "5px",
                borderRadius: "1rem",
              },
            }}
            sx={{
              justifyContent: "center",
              paddingLeft: "30px",
              marginBottom: "12px",
            }}
          >
            <Tab
              label="Intermediate Indicators"
              sx={{
                textTransform: "capitalize",
                fontSize: "15px",
                color: "#9BB6A8",
                fontWeight: "bold",
                "&.Mui-selected": {
                  color: "#0E4F2F", // Active state color
                },
              }}
            />
            <Tab
              label="PDO Indicators"
              sx={{
                textTransform: "capitalize",
                fontSize: "15px",
                color: "#9BB6A8",
                fontWeight: "bold",
                "&.Mui-selected": {
                  color: "#0E4F2F", // Active state color
                },
              }}
            />
            <Tab
              label="Results Implementation Monitoring"
              sx={{
                textTransform: "capitalize",
                fontSize: "15px",
                color: "#9BB6A8",
                fontWeight: "bold",
                "&.Mui-selected": {
                  color: "#0E4F2F", // Active state color
                },
              }}
            />
          </Tabs>
          {tabIndex1 === 0 && (
            <div className="mx-4">
              <IoiPreview data={data} propsJurisdiction={propsJurisdiction} />
              {/* Integrating IoiPreview component */}
            </div>
          )}
          {tabIndex1 === 1 && (
            <div className="mx-4">
              <PdoPreview data={data} propsJurisdiction={propsJurisdiction} />{" "}
              {/* Integrating PdoPreview component */}
            </div>
          )}
          {tabIndex1 === 2 && (
            <div className="mx-4">
              <GeneralActivitiesData data={specificPost}  />
              {/* Integrating Results Implemntation Monitoring */}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default ReviewList;
