import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper/modules";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { Box } from "@mui/material";
import { motion } from "framer-motion";

import { BarChartDisplayTti } from "../../Analytics_Dashboard/chart_components/BarChartDisplays";

const PdoCardComponent3 = ({
  maleCountPreService,
  femaleCountPreService,
  maleCountInService,
  femaleCountInService,
}) => {
  // console.log(
  //   maleCountPreService,
  //   femaleCountPreService,
  //   maleCountInService,
  //   femaleCountInService
  // );
  return (
    <div className="">
      <h1
        className="font-bold text-xl text-primary_text"
        style={{ fontFamily: "Poppins, sans-serif" }}
      >
        PDO
      </h1>
      <Swiper
        modules={[Navigation, Pagination]}
        spaceBetween={30}
        slidesPerView={2}
        navigation={{ nextEl: ".button-next1", prevEl: ".button-prev1" }}
      >
        <SwiperSlide>
          <h4 className=" font-semibold">Pre-service</h4>
          <BarChartDisplayTti
            maleCount={maleCountPreService}
            femaleCount={femaleCountPreService}
          />
          <p className="text-xs" style={{ fontFamily: "Poppins, sans-serif" }}>
            Number of Male and Female technical teachers and instructors trained
            in Supported pre-service skills upgrading programs
          </p>
        </SwiperSlide>
        <SwiperSlide>
          <h4 className=" font-semibold">In-service</h4>
          <BarChartDisplayTti
            maleCount={maleCountInService}
            femaleCount={femaleCountInService}
          />
          <p className="text-xs" style={{ fontFamily: "Poppins, sans-serif" }}>
            Number of Males & females technical teachers and instructors trained
            in Supported in-service skills upgrading programs
          </p>
        </SwiperSlide>
      </Swiper>
      <Box className="flex items-center justify-between text-primary_text_light mt-3">
        <motion.div whileTap={{ scale: 0.9 }} className="button-prev1">
          <KeyboardArrowLeftIcon
            sx={{ fontSize: "60px" }}
            className="cursor-pointer"
          />
        </motion.div>
        <motion.div whileTap={{ scale: 0.9 }} className="button-next1">
          <KeyboardArrowRightIcon
            sx={{ fontSize: "60px" }}
            className="cursor-pointer"
          />
        </motion.div>
      </Box>
    </div>
  );
};

export default PdoCardComponent3;
