import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
} from "@mui/material";
import PageHeader from "../../Components/page_header";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { fetchComponent2MCPData } from "../../utils/apiContext";
import MoreOptionsMenu from "../../Components/MoreOptionsIcon";
import EditModalForm from "../../Components/Modal_Forms/EditModal";
import axiosInstance from "../../Services/axiosInstance";
import { stateOptions } from "./FormComponents/stateOptions";

const columns = [
  { id: 1, label: "", type: "disabled" },
  { id: 2, label: "S/N", type: "disabled" },
  { id: "name_of_business", label: "Business Name", type: "text" },
  { id: "first_name", label: "First Name", type: "text" },
  { id: "last_name", label: "Last Name", type: "text" },
  { id: "email", label: "Email", type: "email" },
  { id: "address", label: "Address", type: "text" },
  {
    id: "certification",
    label: "Certification",
    type: "checkbox",
    options: [
      { value: "Assessor", label: "Assessor" },
      { value: "Verifier", label: "Verifier" },
    ],
  },
  {
    id: "state_of_service",
    label: "State of Service",
    options: { stateOptions },
  },
  {
    id: "service_provider",
    label: "Service Provider",
    type: "dropdown",
    options: [
      { value: "Business Association", label: "Business Association" },
      { value: "Consultancy Firm", label: "Consultancy Firm" },
      { value: "NGO", label: "NGO" },
    ],
  },
];

const McpProfile = () => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState(null);

  const queryClient = useQueryClient();

  const {
    data: allMCPData,
    isLoading: mcpIsLoading,
    error: mcpError,
  } = useQuery({
    queryKey: ["mcpProfileData"],
    queryFn: () => fetchComponent2MCPData(),
  });

  const rows = allMCPData?.mcps;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const onEdit = (rowData) => {
    setSelectedRowData(rowData);
    setIsEditModalOpen(true);
  };
  const handleEditAndSave = async (updatedData) => {
    try {
      await axiosInstance.put(
        `/mcp/c2/update-mcp/${updatedData._id}`,
        updatedData
      );
      queryClient.invalidateQueries(["mcpProfileData"]);
      setIsEditModalOpen(false);
    } catch (error) {
      console.error("Failed to save the updated data:", error);
    }
  };
  // const onDelete = (rowId) => {
  //   // setRowToDelete(rowId);
  //   // setIsConfirmationModalOpen(true);
  // };

  return (
    <div>
      <PageHeader title="Master Craft Person Profiles" />
      <br />
      <Paper>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    sx={{
                      whiteSpace: "nowrap",
                      width: "1%",
                      fontWeight: "bold",
                      color: "#ffffff",
                      backgroundColor: "#2B8056",
                    }}
                    align="center"
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows
                ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => (
                  <TableRow key={index}>
                    <TableCell>
                      <MoreOptionsMenu
                        onEdit={() => onEdit(row)}
                        // onDelete={() => onDelete(row._id)}
                        hideDelete={true}
                      />
                    </TableCell>
                    <TableCell align="center">
                      {page * rowsPerPage + index + 1}
                    </TableCell>
                    <TableCell align="center">
                      {row?.name_of_business}
                    </TableCell>
                    <TableCell align="center" sx={{ whiteSpace: "nowrap" }}>
                      {row?.first_name}
                    </TableCell>
                    <TableCell align="center" sx={{ whiteSpace: "nowrap" }}>
                      {row?.last_name}
                    </TableCell>
                    <TableCell align="center" sx={{ whiteSpace: "nowrap" }}>
                      {row?.email}
                    </TableCell>
                    <TableCell align="center" sx={{ whiteSpace: "nowrap" }}>
                      {row?.address}
                    </TableCell>
                    <TableCell align="center" sx={{ whiteSpace: "nowrap" }}>
                      {row?.certification.join(", ")}
                    </TableCell>
                    <TableCell align="center" sx={{ whiteSpace: "nowrap" }}>
                      {row?.state_of_service}
                    </TableCell>
                    <TableCell align="center" sx={{ whiteSpace: "nowrap" }}>
                      {row?.service_provider}
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          component="div"
          count={rows?.length}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          rowsPerPageOptions={[5, 10, 25]}
        />
      </Paper>
      <EditModalForm
        open={isEditModalOpen}
        handleClose={() => setIsEditModalOpen(false)}
        rowData={selectedRowData}
        handleSave={handleEditAndSave}
        fields={columns}
        header="Edit Student Data"
      />
    </div>
  );
};

export default McpProfile;
