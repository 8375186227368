import { createSlice } from "@reduxjs/toolkit";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { clearPosts, setTcPost } from "./isPostMade";

const authSlice = createSlice({
  name: "auth",
  initialState: {
    user: null,
    token: null,
    roles: [],
    validator_state: null,
    jurisdiction: null,
    tc_name: null,
    lastRoute: null,
  },
  reducers: {
    setCredentials: (state, action) => {
      const { user, accessToken, user_role, jurisdiction, validator_state, tc_name } = action.payload;
      state.user = user;
      state.token = accessToken;
      state.roles = user_role;
      state.jurisdiction = jurisdiction;
      state.validator_state = validator_state;
      state.tc_name = tc_name;
      state.lastRoute = localStorage.getItem('lastRoute'); 
    },

    logOut: (state, action) => {
      state.user = null;
      state.token = null;
      state.roles = [];
      state.jurisdiction = null;
      state.tc_name = null;
      state.validator_state = null;
      localStorage.removeItem('lastRoute');
      clearPosts();
    },
  },
});

export const { setCredentials, logOut } = authSlice.actions;

export const selectCurrentUser = (state) => state.auth.user;
export const selectCurrentToken = (state) => state.auth.token;
export const selectCurrentUserRole = (state) => state.auth.roles;
export const selectCurrentJurisdiction = (state) => state.auth.jurisdiction;
export const selectCurrentTcName = (state) => state.auth.tc_name;
export const selectLastRoute = (state) => state.auth.lastRoute;
export const selectCurrentState = (state) => state.auth.state;
export const selectValidatorState = (state) => state.auth.validator_state

const persistConfig = {
  key: "auth",
  storage,
};

const persistedAuthReducer = persistReducer(persistConfig, authSlice.reducer);

export default persistedAuthReducer;
