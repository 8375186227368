import React, { useState } from "react";
import JoinInnerOutlinedIcon from "@mui/icons-material/JoinInnerOutlined";
import { useSelector } from "react-redux";
import { selectCciPost } from "../../features/auth/isPostMade";
import Preview from "../../Pages/Component1/Preview/Preview";
import UpdateNavigationModal from "../UpdatePromptModal";

const CrossCuttingCard = ({ openModal }) => {
  const isCciPost = useSelector(selectCciPost);
  const [showPreview, setShowPreview] = useState(false);
  const [openUpdateModal, setOpenUpdateModal] = useState(null);
  const [propsIndex, setPropsIndex] = useState("");
  const closePage = () => {
    setShowPreview(false);
  };
  const selectIndex = (index, type) => {
    if (type === "cci") {
      return isCciPost;
    }
  };
  const handleButtonClick = (index, type) => {
    if (type === "cci") {
      setPropsIndex(5);

      if (selectIndex(index, type)) {
        // openModal();

        setOpenUpdateModal({ index, type });
      } else {
        openModal();
      }
    }
  };
  const showPreviewDisplay = () => {
    setShowPreview((prev) => !prev);
    // console.log("PreviewBtn clicked");
  };
  return (
    <div className="flex mt-3 w-auto rounded-xl shadow-xl bg-white">
      <div className="p-6 space-y-4">
        <div className="flex items-center">
          <JoinInnerOutlinedIcon
            className="text-linear_grad_1"
            style={{ fontSize: "2.0rem" }}
          />
          <p
            className="font-bold text-active_bg text-[20px] ml-2"
            style={{ fontFamily: "Poppins, sans-serif" }}
          >
            Cross-Cutting Issues
          </p>
        </div>
        <div className="flex mt-4 pb-6">
          <p
            className="text-input_text text-sm font-sm mt-2"
            style={{ fontFamily: "Poppins, sans-serif" }}
          >
            Enter Cross-Cutting Issues data here. Click save when you're done.
          </p>
          <button
            onClick={() => handleButtonClick(0, "cci")}
            className="text-active_bg text-nowrap font-bold py-2 rounded-md cursor-pointer hover:bg-active_bg hover:text-white mx-12 px-4 mt-3"
            style={{ fontFamily: "Poppins, sans-serif" }}
          >
            + ADD DATA
          </button>
        </div>
      </div>
      {openUpdateModal !== null && (
        <UpdateNavigationModal
          urlLink={`beneficiaries-data`} // Customize URL as needed
          formTitle={"Cross Cutting Issues"}
          closeModal={() => setOpenUpdateModal(null)}
          onClick={showPreviewDisplay}
        />
      )}
      {showPreview && (
        <Preview
          title="Preview"
          closePage={closePage}
          isProps={true}
          propsIndex={propsIndex}
        />
      )}
    </div>
  );
};

export default CrossCuttingCard;
