import React from "react";
import JoinInnerOutlinedIcon from '@mui/icons-material/JoinInnerOutlined';
import LocalActivityIcon from '@mui/icons-material/LocalActivity';
import { useSelector } from "react-redux";
import { selectGenActivitiesPost } from "../../features/auth/isPostMade";

const GeneralActivitiesCard = ({ openModal }) => {
    const isGenActivities = useSelector(selectGenActivitiesPost)
    return (
        <div className="flex mt-3 w-auto rounded-xl shadow-xl bg-white">
            <div className="p-6 space-y-4">
                <div className="flex items-center">
                    <LocalActivityIcon className="text-linear_grad_1" style={{ fontSize: "2.0rem" }} />
                    <p className="font-bold text-active_bg text-[20px] ml-2" style={{ fontFamily: 'Poppins, sans-serif' }}>General Activities</p>
                </div>
                <div className="flex mt-4 pb-6">
                    <p className="text-input_text text-sm font-sm mt-2" style={{ fontFamily: 'Poppins, sans-serif' }}>
                        Enter General Activities data here. Click save when you're done.
                    </p>
                    
                    <button
                        onClick={openModal}
                        className="text-active_bg text-nowrap font-bold py-2 rounded-md cursor-pointer hover:bg-active_bg hover:text-white mx-12 px-4 mt-3"
                        style={{ fontFamily: 'Poppins, sans-serif' }}
                    >
                        {isGenActivities ? "+ EDIT DATA" : "+ ADD DATA"}
                    </button>
                </div>
            </div>
        </div>
    );
};

export default GeneralActivitiesCard;
