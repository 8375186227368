import React, { useEffect, useRef, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { motion } from "framer-motion";

export const IndicatorEditModalFormIoi1 = ({
  open,
  handleClose,
  rowData,
  handleSave,
  fields,
  isLoading,
  setIsLoading,
  header,
}) => {
  const [formValues, setFormValues] = useState({});
  const modalRef = useRef(null);
  const [files, setFiles] = useState({
    sch_ideas_project_team_established_report_pdf: null,
    no_of_mous_signed_with_industry_partners_all_signed_mous_pdf: null,
    no_of_times_ciu_met_over_past_year_minutes_pdf: null,
  });

  useEffect(() => {
    if (rowData) {
      setFormValues({ ...rowData });
    }
  }, [rowData]);

  const handleChange = (e) => {
    const { name, value, type, files } = e.target;
    if (type === "file") {
      setFiles((prevFiles) => ({ ...prevFiles, [name]: files[0] }));
    } else {
      setFormValues((prev) => ({ ...prev, [name]: value }));
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData();

    // Append form values
    formData.append(
      "sch_ideas_project_team_established",
      formValues.sch_ideas_project_team_established_value
    );
    formData.append(
      "no_of_mous_signed_with_industry_partners",
      formValues.no_of_mous_signed_with_industry_partners_value
    );
    formData.append(
      "no_of_times_ciu_met_over_past_year",
      formValues.no_of_times_ciu_met_over_past_year_value
    );
    formData.append("_id", formValues._id);

    // Append files
    formData.append(
      "report_pdf",
      files.sch_ideas_project_team_established_report_pdf
    );
    formData.append(
      "all_signed_mous_pdfs",
      files.no_of_mous_signed_with_industry_partners_all_signed_mous_pdf
    );
    formData.append(
      "minutes_pdf",
      files.no_of_times_ciu_met_over_past_year_minutes_pdf
    );

    handleSave(formData);
    // handleClose();
  };

  if (!open) return null;

  const modalVariants = {
    hidden: {
      y: "-100vh",
      opacity: 0,
    },
    visible: {
      y: "0",
      opacity: 1,
      transition: { delay: 0.2, type: "spring", stiffness: 120 },
    },
  };

  return (
    <div className="fixed left-0 top-0 w-full h-full z-50 bg-black bg-opacity-10 flex justify-center items-center">
      <motion.div
        ref={modalRef}
        variants={modalVariants}
        initial="hidden"
        animate="visible"
        exit="hidden"
        className="scrollbar-hide overflow-auto w-[50%] max-h-[90vh] px-8 pt-8 pb-6 bg-white rounded-lg relative"
      >
        <div className="flex justify-between font-bold">
          <h1 className="text-2xl">{header}</h1>

          <CloseIcon
            onClick={handleClose}
            style={{ fontSize: "2rem" }}
            className="text-red-500 cursor-pointer absolute top-2 right-4"
          />
        </div>
        <hr className="mt-2 mb-4" />
        <form onSubmit={handleSubmit}>
          {fields.map((field) => (
            <div key={field.id} className="mb-4">
              <label className="block text-sm font-bold mb-2">
                {field.label}
              </label>
              {field.type === "radio" ? (
                field.options.map((option) => (
                  <div key={option.value}>
                    <label>
                      <input
                        type="radio"
                        name={field.id}
                        value={option.value}
                        checked={formValues[field.id] === option.value}
                        // onChange={handleRadioChange}
                        onChange={handleChange}
                        className="mr-2"
                      />
                      {option.label}
                    </label>
                  </div>
                ))
              ) : field.type === "file" ? (
                <div>
                  <input
                    id={field.id}
                    name={field.id}
                    type="file"
                    // onChange={(e) => handleFileChange(e, field.id)}
                    onChange={handleChange}
                    className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  />
                </div>
              ) : field.type === "select" ? (
                <select
                  id={field.id}
                  name={field.id}
                  value={formValues[field.id] || ""}
                  // onChange={handleInputChange}
                  onChange={handleChange}
                  className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                >
                  {field.options.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </select>
              ) : (
                <input
                  id={field.id}
                  name={field.id}
                  type={field.type || "text"}
                  value={formValues[field.id] || ""}
                  // onChange={handleInputChange}
                  onChange={handleChange}
                  className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                />
              )}
            </div>
          ))}
          <div className="flex justify-center">
            <button
              type="button"
              onClick={handleClose}
              className="mr-2 px-4 py-2 bg-gray-300 rounded"
            >
              Cancel
            </button>
            <button
              type="submit"
              className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-active_bg text-base font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:w-auto sm:text-sm"
              disabled={isLoading}
            >
              {isLoading ? "Saving..." : "Save"}
            </button>
          </div>
        </form>
      </motion.div>
    </div>
  );
};

export const IndicatorEditModalFormIoi2 = ({
  open,
  handleClose,
  rowData,
  handleSave,
  fields,
  isLoading,
  setIsLoading,
  header,
}) => {
  const [formValues, setFormValues] = useState({});
  const modalRef = useRef(null);
  const [files, setFiles] = useState({
    latest_tc_status_report_pdf: null,
    attendance_sheet_pdf: null,
  });

  useEffect(() => {
    if (rowData) {
      setFormValues({ ...rowData });
    }
  }, [rowData]);

  const handleChange = (e) => {
    const { name, value, type, files } = e.target;
    if (type === "file") {
      setFiles((prevFiles) => ({ ...prevFiles, [name]: files[0] }));
    } else {
      setFormValues((prev) => ({ ...prev, [name]: value }));
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData();

    // Append form values
    formData.append(
      "internship_arrangements",
      formValues.internship_arrangements
    );
    formData.append(
      "no_of_industry_partners",
      formValues.no_of_industry_partners
    );
    formData.append(
      "no_of_internship_arrangements",
      formValues.no_of_internship_arrangements
    );
    formData.append(
      "development_of_short_term_skills_upgrading_courses",
      formValues.development_of_short_term_skills_upgrading_courses
    );
    formData.append(
      "no_of_newly_developed_short_term_skills_upgrading_courses_on_partnership_with_industry",
      formValues.no_of_newly_developed_short_term_skills_upgrading_courses_on_partnership_with_industry
    );
    formData.append("_id", formValues._id);

    // Append files
    formData.append("latest_tc_status_report_pdf", files.latest_tc_status_report_pdf);
    formData.append("attendance_sheet_pdf", files.attendance_sheet_pdf);

    handleSave(formData);
    // handleClose();
  };

  if (!open) return null;

  const modalVariants = {
    hidden: {
      y: "-100vh",
      opacity: 0,
    },
    visible: {
      y: "0",
      opacity: 1,
      transition: { delay: 0.2, type: "spring", stiffness: 120 },
    },
  };

  return (
    <div className="fixed left-0 top-0 w-full h-full z-50 bg-black bg-opacity-10 flex justify-center items-center">
      <motion.div
        ref={modalRef}
        variants={modalVariants}
        initial="hidden"
        animate="visible"
        exit="hidden"
        className="scrollbar-hide overflow-auto w-[50%] max-h-[90vh] px-8 pt-8 pb-6 bg-white rounded-lg relative"
      >
        <div className="flex justify-between font-bold">
          <h1 className="text-2xl">{header}</h1>

          <CloseIcon
            onClick={handleClose}
            style={{ fontSize: "2rem" }}
            className="text-red-500 cursor-pointer absolute top-2 right-4"
          />
        </div>
        <hr className="mt-2 mb-4" />
        <form onSubmit={handleSubmit}>
          {fields.map((field) => (
            <div key={field.id} className="mb-4">
              <label className="block text-sm font-bold mb-2">
                {field.label}
              </label>
              {field.type === "radio" ? (
                field.options.map((option) => (
                  <div key={option.value}>
                    <label>
                      <input
                        type="radio"
                        name={field.id}
                        value={option.value}
                        checked={formValues[field.id] === option.value}
                        // onChange={handleRadioChange}
                        onChange={handleChange}
                        className="mr-2"
                      />
                      {option.label}
                    </label>
                  </div>
                ))
              ) : field.type === "file" ? (
                <div>
                  <input
                    id={field.id}
                    name={field.id}
                    type="file"
                    // onChange={(e) => handleFileChange(e, field.id)}
                    onChange={handleChange}
                    className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  />
                </div>
              ) : field.type === "select" ? (
                <select
                  id={field.id}
                  name={field.id}
                  value={formValues[field.id] || ""}
                  // onChange={handleInputChange}
                  onChange={handleChange}
                  className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                >
                  {field.options.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </select>
              ) : (
                <input
                  id={field.id}
                  name={field.id}
                  type={field.type || "text"}
                  value={formValues[field.id] || ""}
                  // onChange={handleInputChange}
                  onChange={handleChange}
                  className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                />
              )}
            </div>
          ))}
          <div className="flex justify-center">
            <button
              type="button"
              onClick={handleClose}
              className="mr-2 px-4 py-2 bg-gray-300 rounded"
            >
              Cancel
            </button>
            <button
              type="submit"
              className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-active_bg text-base font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:w-auto sm:text-sm"
              disabled={isLoading}
            >
              {isLoading ? "Saving..." : "Save"}
            </button>
          </div>
        </form>
      </motion.div>
    </div>
  );
};

export const IndicatorEditModalFormIoi3 = ({
  open,
  handleClose,
  rowData,
  handleSave,
  fields,
  isLoading,
  setIsLoading,
  header,
}) => {
  const [formValues, setFormValues] = useState({});
  const modalRef = useRef(null);
  const [files, setFiles] = useState({
    sensitization_pdf: null,
    school_gbv_policy_pdf: null,
    reports_showing_addressed_complaints_box_pdf: null,
  });

  useEffect(() => {
    if (rowData) {
      setFormValues({ ...rowData });
    }
  }, [rowData]);

  const handleChange = (e) => {
    const { name, value, type, files } = e.target;
    if (type === "file") {
      setFiles((prevFiles) => ({ ...prevFiles, [name]: files[0] }));
    } else {
      setFormValues((prev) => ({ ...prev, [name]: value }));
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData();

    // Append form values
    formData.append(
      "gbv_sensitization_conducted_by_the_school",
      formValues.gbv_sensitization_conducted_by_the_school
    );
    formData.append(
      "gbv_policy_developed_by_school",
      formValues.gbv_policy_developed_by_school
    );
    formData.append(
      "gbv_policy_published_by_school",
      formValues.gbv_policy_published_by_school
    );
    formData.append(
      "gbv_reporting_and_referral_system_for_youths_in_place_at_the_school",
      formValues.gbv_reporting_and_referral_system_for_youths_in_place_at_the_school
    );
    formData.append(
      "presence_of_grievance_redress_mechanism_at_the_school",
      formValues.presence_of_grievance_redress_mechanism_at_the_school
    );

    formData.append("_id", formValues._id);

    // Append files
    formData.append("sensitization_pdf", files.sensitization_pdf);
    formData.append("school_gbv_policy_pdf", files.school_gbv_policy_pdf);
    formData.append("reports_showing_addressed_complaints_box_pdf", files.reports_showing_addressed_complaints_box_pdf);

    handleSave(formData);
    // handleClose();
  };

  if (!open) return null;

  const modalVariants = {
    hidden: {
      y: "-100vh",
      opacity: 0,
    },
    visible: {
      y: "0",
      opacity: 1,
      transition: { delay: 0.2, type: "spring", stiffness: 120 },
    },
  };

  return (
    <div className="fixed left-0 top-0 w-full h-full z-50 bg-black bg-opacity-10 flex justify-center items-center">
      <motion.div
        ref={modalRef}
        variants={modalVariants}
        initial="hidden"
        animate="visible"
        exit="hidden"
        className="scrollbar-hide overflow-auto w-[50%] max-h-[90vh] px-8 pt-8 pb-6 bg-white rounded-lg relative"
      >
        <div className="flex justify-between font-bold">
          <h1 className="text-2xl">{header}</h1>

          <CloseIcon
            onClick={handleClose}
            style={{ fontSize: "2rem" }}
            className="text-red-500 cursor-pointer absolute top-2 right-4"
          />
        </div>
        <hr className="mt-2 mb-4" />
        <form onSubmit={handleSubmit}>
          {fields.map((field) => (
            <div key={field.id} className="mb-4">
              <label className="block text-sm font-bold mb-2">
                {field.label}
              </label>
              {field.type === "radio" ? (
                field.options.map((option) => (
                  <div key={option.value}>
                    <label>
                      <input
                        type="radio"
                        name={field.id}
                        value={option.value}
                        checked={formValues[field.id] === option.value}
                        // onChange={handleRadioChange}
                        onChange={handleChange}
                        className="mr-2"
                      />
                      {option.label}
                    </label>
                  </div>
                ))
              ) : field.type === "file" ? (
                <div>
                  <input
                    id={field.id}
                    name={field.id}
                    type="file"
                    // onChange={(e) => handleFileChange(e, field.id)}
                    onChange={handleChange}
                    className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  />
                </div>
              ) : field.type === "select" ? (
                <select
                  id={field.id}
                  name={field.id}
                  value={formValues[field.id] || ""}
                  // onChange={handleInputChange}
                  onChange={handleChange}
                  className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                >
                  {field.options.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </select>
              ) : (
                <input
                  id={field.id}
                  name={field.id}
                  type={field.type || "text"}
                  value={formValues[field.id] || ""}
                  // onChange={handleInputChange}
                  onChange={handleChange}
                  className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                />
              )}
            </div>
          ))}
          <div className="flex justify-center">
            <button
              type="button"
              onClick={handleClose}
              className="mr-2 px-4 py-2 bg-gray-300 rounded"
            >
              Cancel
            </button>
            <button
              type="submit"
              className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-active_bg text-base font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:w-auto sm:text-sm"
              disabled={isLoading}
            >
              {isLoading ? "Saving..." : "Save"}
            </button>
          </div>
        </form>
      </motion.div>
    </div>
  );
};

export const IndicatorEditModalFormIoi4 = ({
  open,
  handleClose,
  rowData,
  handleSave,
  fields,
  isLoading,
  setIsLoading,
  header,
}) => {
  const [formValues, setFormValues] = useState(() =>
    fields.reduce((acc, field) => {
      if (field.type === "checkbox") {
        acc[field.id] = [];
      } else {
        acc[field.id] = field.type === "file" ? null : "";
      }
      return acc;
    }, {})
  );
  const modalRef = useRef(null);
  const [files, setFiles] = useState({
    doc_confirming_disbursment_received_pdf: null,
    workshop_status_report_pdf: null,
    training_status_report_pdf: null,
  });

  // useEffect(() => {
  //   if (rowData) {
  //     setFormValues((prevValues) => ({
  //       ...prevValues,
  //       ...rowData,
  //       workshops: rowData.workshops || [],
  //     }));
  //   }
  // }, [rowData]);
  useEffect(() => {
    if (rowData) {
      setFormValues({ ...rowData });
    }
  }, [rowData]);

  const handleChange = (e) => {
    const { name, value, type, checked, files } = e.target;
    if (type === "file") {
      setFiles((prevFiles) => ({ ...prevFiles, [name]: files[0] }));
    } else if (type === "checkbox") {
      setFormValues((prev) => {
        const prevValues = prev[name] || [];
        if (checked) {
          if (prevValues.length >= 4) {
            return prev;
          }
          return { ...prev, [name]: [...prevValues, value] };
        } else {
          return { ...prev, [name]: prevValues.filter((v) => v !== value) };
        }
      });
    } else {
      setFormValues((prev) => ({ ...prev, [name]: value }));
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData();

    console.log("FormValues:", formValues);

    // Append form values
    formData.append("workshops", formValues.workshops);
    formData.append(
      "initial_disbursement_of_250kusd_received",
      formValues.initial_disbursement_of_250kusd_received.value
    );
    formData.append(
      "cdp_received_by_the_npcu",
      formValues.cdp_received_by_the_npcu
    );
    formData.append(
      "cdp_approved_by_the_world_bank",
      formValues.cdp_approved_by_the_world_bank
    );
    formData.append(
      "no_of_workshops_renovated",
      formValues.no_of_workshops_renovated
    );
    formData.append(
      "no_of_workshops_equipped_with_modern_tools_and_ready_for_use",
      formValues.no_of_workshops_equipped_with_modern_tools_and_ready_for_use
        .value
    );
    formData.append(
      "training_of_ttis_on_the_use_of_newly_installed_tools",
      formValues.training_of_ttis_on_the_use_of_newly_installed_tools
    );
    formData.append(
      "no_of_ttis_trained_on_the_use_of_newly_installed_tools",
      formValues.no_of_ttis_trained_on_the_use_of_newly_installed_tools.value
    );

    formData.append("_id", formValues._id);

    // Append files
    formData.append("doc_confirming_disbursment_received_pdf", files.doc_confirming_disbursment_received_pdf);
    formData.append("status_report_pdf", files.workshop_status_report_pdf);
    formData.append("ttis_status_report_pdf", files.training_status_report_pdf);

    handleSave(formData);
    // handleClose();
  };

  if (!open) return null;

  const modalVariants = {
    hidden: {
      y: "-100vh",
      opacity: 0,
    },
    visible: {
      y: "0",
      opacity: 1,
      transition: { delay: 0.2, type: "spring", stiffness: 120 },
    },
  };

  return (
    <div className="fixed left-0 top-0 w-full h-full z-50 bg-black bg-opacity-10 flex justify-center items-center">
      <motion.div
        ref={modalRef}
        variants={modalVariants}
        initial="hidden"
        animate="visible"
        exit="hidden"
        className="scrollbar-hide overflow-auto w-[50%] max-h-[90vh] px-8 pt-8 pb-6 bg-white rounded-lg relative"
      >
        <div className="flex justify-between font-bold">
          <h1 className="text-2xl">{header}</h1>

          <CloseIcon
            onClick={handleClose}
            style={{ fontSize: "2rem" }}
            className="text-red-500 cursor-pointer absolute top-2 right-4"
          />
        </div>
        <hr className="mt-2 mb-4" />
        <form onSubmit={handleSubmit}>
          {fields.map((field) => (
            <div key={field.id} className="mb-4">
              <label className="block text-sm font-bold mb-2">
                {field.label}
              </label>
              {field.type === "radio" ? (
                field.options.map((option) => (
                  <div key={option.value}>
                    <label>
                      <input
                        type="radio"
                        name={field.id}
                        value={option.value}
                        checked={formValues[field.id] === option.value}
                        onChange={handleChange}
                        className="mr-2"
                      />
                      {option.label}
                    </label>
                  </div>
                ))
              ) : field.type === "checkbox" ? (
                field.options.map((option) => (
                  <div key={option.value}>
                    <label>
                      <input
                        type="checkbox"
                        name={field.id}
                        value={option.value}
                        checked={formValues[field.id]?.includes(option.value)}
                        onChange={handleChange}
                        className="mr-2"
                      />
                      {option.label}
                    </label>
                  </div>
                ))
              ) : field.type === "file" ? (
                <div>
                  <input
                    id={field.id}
                    name={field.id}
                    type="file"
                    onChange={handleChange}
                    className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  />
                </div>
              ) : field.type === "select" ? (
                <select
                  id={field.id}
                  name={field.id}
                  value={formValues[field.id] || ""}
                  onChange={handleChange}
                  className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                >
                  {field.options.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </select>
              ) : (
                <input
                  id={field.id}
                  name={field.id}
                  type={field.type || "text"}
                  value={formValues[field.id] || ""}
                  onChange={handleChange}
                  className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                />
              )}
            </div>
          ))}
          <div className="flex justify-center">
            <button
              type="button"
              onClick={handleClose}
              className="mr-2 px-4 py-2 bg-gray-300 rounded"
            >
              Cancel
            </button>
            <button
              type="submit"
              className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-active_bg text-base font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:w-auto sm:text-sm"
              disabled={isLoading}
            >
              {isLoading ? "Saving..." : "Save"}
            </button>
          </div>
        </form>
      </motion.div>
    </div>
  );
};

export const IndicatorEditModalFormPdo2 = ({
  open,
  handleClose,
  rowData,
  handleSave,
  fields,
  header,
  isLoading,
  setIsLoading,
  jurisdiction,
}) => {
  const [formValues, setFormValues] = useState({});
  const modalRef = useRef(null);
  const [files, setFiles] = useState({
    tc_report_pdf: null,
    student_enrollment_data_doc_pdf: null,
  });

  useEffect(() => {
    if (rowData) {
      setFormValues({ ...rowData });
    }
  }, [rowData]);

  const handleChange = (e) => {
    const { name, value, type, files } = e.target;
    if (type === "file") {
      setFiles((prevFiles) => ({ ...prevFiles, [name]: files[0] }));
    } else {
      setFormValues((prev) => ({ ...prev, [name]: value }));
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData();

    console.log("FormValues:", formValues);

    // Append form values
    formData.append(
      "tvet_sensitization_consucted_in_host_community_by_school",
      formValues.tvet_sensitization_consucted_in_host_community_by_school
    );
    formData.append(
      "no_of_toilet_facilities_in_school",
      formValues.no_of_toilet_facilities_in_school
    );
    formData.append(
      "no_of_toilet_facilities_renovated_by_ideas_project",
      formValues.no_of_toilet_facilities_renovated_by_ideas_project
    );
    formData.append(
      "no_of_toilet_facilities_provided_with_wash_facilities_by_ideas_project",
      formValues.no_of_toilet_facilities_provided_with_wash_facilities_by_ideas_project
    );
    formData.append(
      "no_of_female_students_enrolled_in_priority_trade",
      formValues.no_of_female_students_enrolled_in_priority_trade
    );
    formData.append(
      "total_no_of_students_enrolled_in_priority_trades",
      formValues.total_no_of_students_enrolled_in_priority_trades
    );

    formData.append("_id", formValues._id);

    // Append files
    if (files.tc_report_pdf) {
      formData.append("tc_report_pdf", files.tc_report_pdf);
    }
    if (files.student_enrollment_data_doc_pdf) {
      formData.append("student_enrollment_data_doc_pdf", files.student_enrollment_data_doc_pdf);
    }

    formData.append("jurisdiction", jurisdiction);

    handleSave(formData);
    // handleClose();
  };

  if (!open) return null;

  const modalVariants = {
    hidden: {
      y: "-100vh",
      opacity: 0,
    },
    visible: {
      y: "0",
      opacity: 1,
      transition: { delay: 0.2, type: "spring", stiffness: 120 },
    },
  };

  return (
    <div className="fixed left-0 top-0 w-full h-full z-50 bg-black bg-opacity-10 flex justify-center items-center">
      <motion.div
        ref={modalRef}
        variants={modalVariants}
        initial="hidden"
        animate="visible"
        exit="hidden"
        className="scrollbar-hide overflow-auto w-[50%] max-h-[90vh] px-8 pt-8 pb-6 bg-white rounded-lg relative"
      >
        <div className="flex justify-between font-bold">
          <h1 className="text-2xl">{header}</h1>

          <CloseIcon
            onClick={handleClose}
            style={{ fontSize: "2rem" }}
            className="text-red-500 cursor-pointer absolute top-2 right-4"
          />
        </div>
        <hr className="mt-2 mb-4" />
        <form onSubmit={handleSubmit}>
          {fields.map((field) => (
            <div key={field.id} className="mb-4">
              <label className="block text-sm font-bold mb-2">
                {field.label}
              </label>
              {field.type === "radio" ? (
                field.options.map((option) => (
                  <div key={option.value}>
                    <label>
                      <input
                        type="radio"
                        name={field.id}
                        value={option.value}
                        checked={formValues[field.id] === option.value}
                        onChange={handleChange}
                        className="mr-2"
                      />
                      {option.label}
                    </label>
                  </div>
                ))
              ) : field.type === "checkbox" ? (
                field.options.map((option) => (
                  <div key={option.value}>
                    <label>
                      <input
                        type="checkbox"
                        name={field.id}
                        value={option.value}
                        checked={formValues[field.id]?.includes(option.value)}
                        onChange={handleChange}
                        className="mr-2"
                      />
                      {option.label}
                    </label>
                  </div>
                ))
              ) : field.type === "file" ? (
                <div>
                  <input
                    id={field.id}
                    name={field.id}
                    type="file"
                    onChange={handleChange}
                    className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  />
                </div>
              ) : field.type === "select" ? (
                <select
                  id={field.id}
                  name={field.id}
                  value={formValues[field.id] || ""}
                  onChange={handleChange}
                  className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                >
                  {field.options.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </select>
              ) : (
                <input
                  id={field.id}
                  name={field.id}
                  type={field.type || "text"}
                  value={formValues[field.id] || ""}
                  onChange={handleChange}
                  className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                />
              )}
            </div>
          ))}
          <div className="flex justify-center">
            <button
              type="button"
              onClick={handleClose}
              className="mr-2 px-4 py-2 bg-gray-300 rounded"
            >
              Cancel
            </button>
            <button
              type="submit"
              className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-active_bg text-base font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:w-auto sm:text-sm"
              disabled={isLoading}
            >
              {isLoading ? "Saving..." : "Save"}
            </button>
          </div>
        </form>
      </motion.div>
    </div>
  );
};

export const IndicatorEditModalFormPdo3 = ({
  open,
  handleClose,
  rowData,
  handleSave,
  fields,
  header,
  isLoading,
  setIsLoading,
  jurisdiction,
}) => {
  const [formValues, setFormValues] = useState({});
  const modalRef = useRef(null);
  const [files, setFiles] = useState({
    student_enrollment_data_doc_pdf: null,
  });

  useEffect(() => {
    if (rowData) {
      setFormValues({ ...rowData });
    }
  }, [rowData]);

  const handleChange = (e) => {
    const { name, value, type, files } = e.target;
    if (type === "file") {
      setFiles((prevFiles) => ({ ...prevFiles, [name]: files[0] }));
    } else {
      setFormValues((prev) => ({ ...prev, [name]: value }));
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData();

    console.log("FormValues:", formValues);

    // Append form values
    formData.append("male_graduates", formValues.male_graduates);
    formData.append("female_graduates", formValues.female_graduates);
    formData.append("male_enrolled", formValues.male_enrolled);
    formData.append("female_enrolled", formValues.female_enrolled);
    formData.append(
      "male_labour_market_worker",
      formValues.male_labour_market_workers
    );
    formData.append(
      "female_labour_market_workers",
      formValues.female_labour_market_workers
    );
    formData.append("male_unemployed", formValues.male_unemployed);
    formData.append("female_unemployed", formValues.female_unemployed);

    formData.append("_id", formValues._id);

    // Append files
    if (files.student_enrollment_data_doc_pdf) {
      formData.append("pdfs", files.student_enrollment_data_doc_pdf);
    }

    formData.append("jurisdiction", jurisdiction);

    handleSave(formData);
    // handleClose();
  };

  if (!open) return null;

  const modalVariants = {
    hidden: {
      y: "-100vh",
      opacity: 0,
    },
    visible: {
      y: "0",
      opacity: 1,
      transition: { delay: 0.2, type: "spring", stiffness: 120 },
    },
  };

  return (
    <div className="fixed left-0 top-0 w-full h-full z-50 bg-black bg-opacity-10 flex justify-center items-center">
      <motion.div
        ref={modalRef}
        variants={modalVariants}
        initial="hidden"
        animate="visible"
        exit="hidden"
        className="scrollbar-hide overflow-auto w-[50%] max-h-[90vh] px-8 pt-8 pb-6 bg-white rounded-lg relative"
      >
        <div className="flex justify-between font-bold">
          <h1 className="text-2xl">{header}</h1>

          <CloseIcon
            onClick={handleClose}
            style={{ fontSize: "2rem" }}
            className="text-red-500 cursor-pointer absolute top-2 right-4"
          />
        </div>
        <hr className="mt-2 mb-4" />
        <form onSubmit={handleSubmit}>
          {fields.map((field) => (
            <div key={field.id} className="mb-4">
              <label className="block text-sm font-bold mb-2">
                {field.label}
              </label>
              {field.type === "radio" ? (
                field.options.map((option) => (
                  <div key={option.value}>
                    <label>
                      <input
                        type="radio"
                        name={field.id}
                        value={option.value}
                        checked={formValues[field.id] === option.value}
                        onChange={handleChange}
                        className="mr-2"
                      />
                      {option.label}
                    </label>
                  </div>
                ))
              ) : field.type === "checkbox" ? (
                field.options.map((option) => (
                  <div key={option.value}>
                    <label>
                      <input
                        type="checkbox"
                        name={field.id}
                        value={option.value}
                        checked={formValues[field.id]?.includes(option.value)}
                        onChange={handleChange}
                        className="mr-2"
                      />
                      {option.label}
                    </label>
                  </div>
                ))
              ) : field.type === "file" ? (
                <div>
                  <input
                    id={field.id}
                    name={field.id}
                    type="file"
                    onChange={handleChange}
                    className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  />
                </div>
              ) : field.type === "select" ? (
                <select
                  id={field.id}
                  name={field.id}
                  value={formValues[field.id] || ""}
                  onChange={handleChange}
                  className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                >
                  {field.options.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </select>
              ) : (
                <input
                  id={field.id}
                  name={field.id}
                  type={field.type || "text"}
                  value={formValues[field.id] || ""}
                  onChange={handleChange}
                  className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                />
              )}
            </div>
          ))}
          <div className="flex justify-center">
            <button
              type="button"
              onClick={handleClose}
              className="mr-2 px-4 py-2 bg-gray-300 rounded"
            >
              Cancel
            </button>
            <button
              type="submit"
              className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-active_bg text-base font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:w-auto sm:text-sm"
              disabled={isLoading}
            >
              {isLoading ? "Saving..." : "Save"}
            </button>
          </div>
        </form>
      </motion.div>
    </div>
  );
};
