import * as React from "react";
import { BarChart } from "@mui/x-charts/BarChart";
import { axisClasses } from "@mui/x-charts/ChartsAxis";
import PageHeader from "../../../Components/page_header";
// import { navListItems } from "../../Data_Objects/sidenav";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import CrossCuttingIssuesTable from "../Preview/table_components/CrossCuttingIssues";
import { useSelector } from "react-redux";
import {
  selectCurrentJurisdiction,
  selectCurrentUserRole,
  selectValidatorState,
} from "../../../features/auth/authSlice";
import { useQuery } from "@tanstack/react-query";
import { fetchAllCCIForNA, fetchAllCCIPosts } from "../../../utils/apiContext";
import { toSentenceCase } from "../../../utils/toSentenceCase";
import ShowLoader from "../../../utils/showLoader";

const chartSetting = {
  yAxis: [
    {
      label: "Cross Cutting Issues",
    },
  ],
  width: 1020,
  height: 500,
  sx: {
    [`.${axisClasses.left} .${axisClasses.label}`]: {
      transform: "translate(-5px, 0)",
      fontWeight: "bold",
    },
    [`.${axisClasses.bottom} .${axisClasses.label}`]: {
      fontWeight: "bold", // Make x-axis label bold
    },
  },
};

// const valueFormatter = (value) => `${value}mm`;
const customColors = ["#0E4F2F", "#45BC5F", "#843C30", "#C0AA54", "#CFF0B5"];

export default function CrossCuttingIssues() {
  const [schoolData, setSchoolData] = React.useState("");
  const [selectedSchool, setSelectedSchool] = React.useState(null);
  const [propsJurisdiction, setPropsJurisdiction] = React.useState("");

  const jurisdiction = useSelector(selectCurrentJurisdiction);
  const validatorState = useSelector(selectValidatorState);
  const userRole = useSelector(selectCurrentUserRole);
  const isReviewer = userRole === "Reviewer";
  const isNationalAdmin = userRole === "National_Admin";
  const isValidator = userRole === "Validator";

  const {
    data: cciData,
    isLoading: validatorLoading,
    error: validatorError,
  } = useQuery({
    queryKey: ["tcCCIUsers"],
    queryFn: () => !isNationalAdmin && fetchAllCCIPosts(jurisdiction),
  });

  const filteredPost =
    isReviewer ||
    (isValidator && cciData?.filter((post) => post?.state === validatorState));

  const {
    data: cciData_NA,
    isLoading: NALoading,
    error: NAError,
  } = useQuery({
    queryKey: ["NaCCI"],
    queryFn: (isNationalAdmin || userRole === "Super_Admin") && fetchAllCCIForNA,
  });

  let cciData_NA_array = [];
  if (cciData_NA && Array.isArray(cciData_NA)) {
    cciData_NA_array = [
      ...(cciData_NA[0]?.data || []),
      ...(cciData_NA[1]?.data || []),
    ];
  }

  const aggregatedData = React.useMemo(() => {
    const data = {
      environmental_safeguard_issues: {
        reported: 0,
        investigated: 0,
        uninvestigated: 0,
        resolved: 0,
        unresolved: 0,
      },
      fraud_corruption: {
        reported: 0,
        investigated: 0,
        uninvestigated: 0,
        resolved: 0,
        unresolved: 0,
      },
      gender_based_violence: {
        reported: 0,
        investigated: 0,
        uninvestigated: 0,
        resolved: 0,
        unresolved: 0,
      },
      greviance_redress_mechanisms: {
        reported: 0,
        investigated: 0,
        uninvestigated: 0,
        resolved: 0,
        unresolved: 0,
      },
      social_safeguard_issues: {
        reported: 0,
        investigated: 0,
        uninvestigated: 0,
        resolved: 0,
        unresolved: 0,
      },
    };

    isReviewer &&
      cciData?.forEach((post) => {
        for (const key in data) {
          if (post[key]) {
            data[key].reported += post[key].reported;
            data[key].investigated += post[key].investigated;
            data[key].uninvestigated += post[key].uninvestigated;
            data[key].resolved += post[key].resolved;
            data[key].unresolved += post[key].unresolved;
          }
        }
      });

    isNationalAdmin &&
      cciData_NA_array?.forEach((post) => {
        for (const key in data) {
          if (post[key]) {
            data[key].reported += post[key].reported;
            data[key].investigated += post[key].investigated;
            data[key].uninvestigated += post[key].uninvestigated;
            data[key].resolved += post[key].resolved;
            data[key].unresolved += post[key].unresolved;
          }
        }
      });

    isValidator &&
      filteredPost?.forEach((post) => {
        for (const key in data) {
          if (post[key]) {
            data[key].reported += post[key].reported;
            data[key].investigated += post[key].investigated;
            data[key].uninvestigated += post[key].uninvestigated;
            data[key].resolved += post[key].resolved;
            data[key].unresolved += post[key].unresolved;
          }
        }
      });

    return data;
  }, [isReviewer, cciData, isNationalAdmin, cciData_NA_array, isValidator, filteredPost]);

  const dataset = React.useMemo(() => {
    return [
      {
        reported: aggregatedData?.environmental_safeguard_issues?.reported,
        investigated:
          aggregatedData?.environmental_safeguard_issues?.investigated,
        uninvestigated:
          aggregatedData?.environmental_safeguard_issues?.uninvestigated,
        resolved: aggregatedData?.environmental_safeguard_issues?.resolved,
        unresolved: aggregatedData?.environmental_safeguard_issues?.unresolved,
        issue: "Environmental Safeguard Issues",
      },
      {
        reported: aggregatedData?.fraud_corruption.reported,
        investigated: aggregatedData?.fraud_corruption.investigated,
        uninvestigated: aggregatedData?.fraud_corruption.uninvestigated,
        resolved: aggregatedData?.fraud_corruption.resolved,
        unresolved: aggregatedData?.fraud_corruption.unresolved,
        issue: "Fraud and Corruption",
      },
      {
        reported: aggregatedData?.gender_based_violence.reported,
        investigated: aggregatedData?.gender_based_violence.investigated,
        uninvestigated: aggregatedData?.gender_based_violence.uninvestigated,
        resolved: aggregatedData?.gender_based_violence.resolved,
        unresolved: aggregatedData?.gender_based_violence.unresolved,
        issue: "Gender Based Violence",
      },
      {
        reported: aggregatedData?.greviance_redress_mechanisms.reported,
        investigated: aggregatedData?.greviance_redress_mechanisms.investigated,
        uninvestigated:
          aggregatedData?.greviance_redress_mechanisms.uninvestigated,
        resolved: aggregatedData?.greviance_redress_mechanisms.resolved,
        unresolved: aggregatedData?.greviance_redress_mechanisms.unresolved,
        issue: "Greviance Redress Mechanisms",
      },
      {
        reported: aggregatedData?.social_safeguard_issues.reported,
        investigated: aggregatedData?.social_safeguard_issues.investigated,
        uninvestigated: aggregatedData?.social_safeguard_issues.uninvestigated,
        resolved: aggregatedData?.social_safeguard_issues.resolved,
        unresolved: aggregatedData?.social_safeguard_issues.unresolved,
        issue: "Social Safeguard Issues",
      },
    ];
  }, [aggregatedData]);
  // console.log("dataSetCCI:", dataset);
  const handleChange = (event) => {
    let selectedSchool = null;
    let jurisdiction = "";

    if (isValidator) {
      selectedSchool = filteredPost?.find(
        (school) => school.tc_name === event.target.value
      );
    }

    if (isReviewer) {
      selectedSchool = cciData?.find(
        (school) => school.tc_name === event.target.value
      );
    }

    if (isNationalAdmin) {
      selectedSchool = cciData_NA_array?.find(
        (school) => school.tc_name === event.target.value
      );

      if (cciData_NA[0]?.data?.includes(selectedSchool)) {
        jurisdiction = "state";
      } else if (cciData_NA[1]?.data?.includes(selectedSchool)) {
        jurisdiction = "federal";
      }
    }

    setSchoolData(event.target.value);
    setSelectedSchool(selectedSchool?.tc_name);
    setPropsJurisdiction(jurisdiction);
  };

  if (validatorLoading || NALoading) {
    return <ShowLoader />;
  }

  // console.log("propsJurisdiction", propsJurisdiction);
  return (
    <>
      <PageHeader title="Cross Cutting Issues" />
      <div>
        <div className="flex justify-end mt-4">
          <FormControl
            sx={{
              width: "50%",

              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  borderColor: "gray", // Unselected color
                },
                "&:hover fieldset": {
                  borderColor: "#45BC5F", // Hover color
                },
                "&.Mui-focused fieldset": {
                  borderColor: "#45BC5F", // Selected color
                },
              },
            }}
          >
            <InputLabel id="demo-simple-select-autowidth-label">
              Select to View Technical Colleges
            </InputLabel>
            <Select
              labelId="demo-simple-select-autowidth-label"
              id="demo-simple-select-autowidth"
              value={schoolData}
              onChange={handleChange}
              autoWidth
              label="Select to View Technical Colleges"
            >
              <MenuItem value="">
                <em>Select Technical College</em>
              </MenuItem>
              {isValidator &&
                filteredPost?.map((school) => (
                  <MenuItem key={school._id} value={school.tc_name}>
                    {toSentenceCase(school.tc_name)}
                  </MenuItem>
                ))}
              {isReviewer &&
                cciData?.map((school) => (
                  <MenuItem key={school._id} value={school.tc_name}>
                    {toSentenceCase(school.tc_name)}
                  </MenuItem>
                ))}
              {isNationalAdmin &&
                cciData_NA_array?.map((school) => (
                  <MenuItem key={school._id} value={school.tc_name}>
                    {toSentenceCase(school.tc_name)}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </div>
        {selectedSchool && (
          <div className="mb-4 bg-white px-4 py-4">
            <CrossCuttingIssuesTable
              key={selectedSchool}
              schoolData={selectedSchool}
              propsJurisdiction={propsJurisdiction}
            />
          </div>
        )}
        <BarChart
          dataset={dataset}
          xAxis={[{ scaleType: "band", dataKey: "issue" }]}
          barLabel="value"
          series={[
            { dataKey: "reported", label: "Reported", color: customColors[0] },
            {
              dataKey: "investigated",
              label: "Investigated",
              color: customColors[1],
            },
            {
              dataKey: "uninvestigated",
              label: "Univestigated",
              color: customColors[2],
            },
            {
              dataKey: "resolved",
              label: "Resolved",
              color: customColors[3],
            },
            {
              dataKey: "unresolved",
              label: "Unresolved",
              color: customColors[4],
            },
          ]}
          {...chartSetting}
        />
      </div>
    </>
  );
}
