import React, { useEffect, useRef, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { motion } from "framer-motion";
import { FormDropdown } from "../form";

const EditModalForm = ({
  open,
  handleClose,
  rowData,
  handleSave,
  fields,
  header,
}) => {
  const [formValues, setFormValues] = useState({});
  const modalRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (rowData) {
      setFormValues(rowData);
    }
  }, [rowData]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        handleClose();
      }
    };
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [handleClose]);

  // const handleChange = (e) => {
  //   const { name, value } = e.target;
  //   setFormValues((prev) => ({ ...prev, [name]: value }));
  // };
  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;

    if (type === "checkbox" && Array.isArray(formValues[name])) {
      setFormValues((prev) => ({
        ...prev,
        [name]: checked
          ? [...prev[name], value] // Add value to array if checked
          : prev[name].filter((v) => v !== value), // Remove value from array if unchecked
      }));
    } else {
      setFormValues((prev) => ({
        ...prev,
        [name]: type === "checkbox" ? [value] : value, // If not already an array, initialize as array
      }));
    }
  };

  const handleNestedChange = (e) => {
    const { name, value } = e.target;
    setFormValues((prev) => ({
      ...prev,
      occupational_training: {
        ...prev.occupational_training,
        [name]: value,
      },
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    handleSave(formValues);
    // handleClose();
  };

  if (!open) return null;

  const modalVariants = {
    hidden: {
      y: "-100vh",
      opacity: 0,
    },
    visible: {
      y: "0",
      opacity: 1,
      transition: { delay: 0.2, type: "spring", stiffness: 120 },
    },
  };

  return (
    <div className="fixed left-0 top-0 w-full h-full z-20 bg-black bg-opacity-50 flex justify-center items-center">
      <motion.div
        ref={modalRef}
        variants={modalVariants}
        initial="hidden"
        animate="visible"
        exit="hidden"
        className="scrollbar-hide overflow-auto w-[50%] h-[98vh] px-8 pt-8 pb-6 bg-white rounded-lg relative"
      >
        <div className="flex justify-between font-bold">
          <h1 className="text-2xl">{header}</h1>

          <CloseIcon
            onClick={handleClose}
            style={{ fontSize: "2rem" }}
            className="text-red-500 cursor-pointer absolute top-2 right-4"
          />
        </div>
        <hr className="mt-2 mb-4" />
        <form onSubmit={handleSubmit}>
          {fields.map((field) => (
            <div key={field.id} className="mb-4">
              {field.type === "dropdown" ? (
                <FormDropdown
                  name={field.id}
                  label={field.label}
                  options={field.options}
                  value={formValues[field.id] || ""}
                  onChange={handleChange}
                  placeholder="- Choose -"
                />
              ) : field.type === "date" ? (
                <>
                  <label className="block text-sm font-bold mb-2">
                    {field.label}
                  </label>
                  <input
                    id={field.id}
                    name={field.id}
                    type="date"
                    value={formValues[field.id] || ""}
                    onChange={handleChange}
                    className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  />
                </>
              ) : field.type === "number" ? (
                <>
                  <label className="block text-sm font-bold mb-2">
                    {field.label}
                  </label>
                  <input
                    id={field.id}
                    name={field.id}
                    type="number"
                    value={formValues[field.id] || ""}
                    onChange={handleChange}
                    className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  />
                </>
              ) : field.type === "radio" ? (
                <>
                  <label className="block text-sm font-bold mb-2">
                    {field.label}
                  </label>
                  {field.options.map((option) => (
                    <label
                      key={option.value}
                      className="inline-flex items-center mt-2 mr-4"
                    >
                      <input
                        type="radio"
                        name={field.id}
                        value={option.value}
                        checked={formValues[field.id] === option.value}
                        onChange={handleChange}
                        className="form-radio h-5 w-5 text-gray-600"
                      />
                      <span className="ml-2 text-gray-700">{option.label}</span>
                    </label>
                  ))}
                </>
              ) : field.type === "disabled" ? (
                <></>
              ) : field.type === "checkbox" && Array.isArray(field.options) ? (
                <>
                  <label className="block text-sm font-bold mb-2">
                    {field.label}
                  </label>
                  {field.options.map((option) => (
                    <label
                      key={option.value}
                      className="inline-flex items-center mt-2 mr-4"
                    >
                      <input
                        type="checkbox"
                        name={field.id}
                        value={option.value}
                        checked={
                          formValues[field.id]?.includes(option.value) || false
                        }
                        onChange={handleChange}
                        className="form-checkbox h-5 w-5 text-gray-600"
                      />
                      <span className="ml-2 text-gray-700">{option.label}</span>
                    </label>
                  ))}
                </>
              ) : (
                <>
                  <label className="block text-sm font-bold mb-2">
                    {field.label}
                  </label>
                  <input
                    id={field.id}
                    name={field.id}
                    type={field.type || "text"}
                    value={formValues[field.id] || ""}
                    onChange={handleChange}
                    className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  />
                </>
              )}
            </div>
          ))}

          <div className="flex justify-center">
            <button
              type="button"
              onClick={handleClose}
              className="mr-2 px-4 py-2 bg-gray-300 rounded"
            >
              Cancel
            </button>
            <button
              type="submit"
              className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-active_bg text-base font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:w-auto sm:text-sm"
            >
              Save
            </button>
          </div>
        </form>
      </motion.div>
    </div>
  );
};

export default EditModalForm;
