import { motion } from "framer-motion";
import CloseIcon from "@mui/icons-material/Close";
import React, { useState } from "react";
import dp from "../../../Assets/profilePicture.svg";
import CustomButton from "../../../Components/button";
import { FormMediaUpload } from "../../../Components/form";
import axiosFetchProfile from "../../../Services/axiosFetchProfile";
import { fetchUserProfileData } from "../../../utils/apiContext";
import { useSelector } from "react-redux";
import { selectCurrentToken } from "../../../features/auth/authSlice";
import { useQuery } from "@tanstack/react-query";
import ShowLoader from "../../../utils/showLoader";

const modalVariants = {
  hidden: {
    y: "-100vh",
    opacity: 0,
  },
  visible: {
    y: "0",
    opacity: 1,
    transition: { delay: 0.2, type: "spring", stiffness: 120 },
  },
};


const Settings = ({ title, closePage, setProfilePicture }) => {
  const [formData, setFormData] = useState({
    report: null,
  });
  const token = useSelector(selectCurrentToken);

  const {
    data: profileData,
    isLoading,
    error,
  } = useQuery({
    queryKey: ["profileData"],
    queryFn: () => fetchUserProfileData(token),
  });
  console.log("profileData",profileData);

  if (isLoading) return <ShowLoader />;
  if (error) return <p>Error loading profile data</p>;

  const handleChange = (event) => {
    const { name, type, files, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: type === "file" ? files[0] : value, // Handle file upload separately
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Update profile picture
    if (formData.report) {
      setProfilePicture(URL.createObjectURL(formData.report)); // Update profile picture state
    }
  };

  return (
    <div className="fixed left-0 top-0 w-full h-screen bg-black bg-opacity-70 flex justify-center items-center">
      <motion.div
        variants={modalVariants}
        initial="hidden"
        animate="visible"
        exit="hidden"
        className="scrollbar-hide overflow-auto w-/4 h-[98vh] px-4 pt-2 pb-6 bg-page_bg rounded-lg relative"
      >
        <div className="flex justify-between items-center py-3 ml-8 font-bold">
          <h1 className="text-2xl text-active_bg pl-4">Settings</h1>
          <CloseIcon
            onClick={closePage} // Close the modal when the close icon is clicked
            sx={{ fontSize: "2rem" }}
            fontWeight={"30"}
            className="text-red-500 cursor-pointer absolute top-2 right-4"
          />
        </div>
        <hr />

        <div className="flex-column bg-innerpage_bg p-12 rounded-md mt-2">
          <div>
            <div className="flex justify-between items-start mt-2">
              <div>
                <h3
                  className="font-medium text-[18.63px] text-primary_text"
                  style={{ fontFamily: "Poppins, sans-serif" }}
                >
                  Name
                </h3>
              </div>
              <div className="space-x-2">
                <input
                  type="text"
                  placeholder="Firstname"
                  className="border border-gray-300 rounded-md py-2 pl-4 focus:outline-none focus:ring-2 focus:ring-blue-200"
                  value={profileData?.user.first_name || ""}
                  disabled
                />
                <input
                  type="text"
                  placeholder="Lastname"
                  className="border border-gray-300 rounded-md py-2 pl-4 focus:outline-none focus:ring-2 focus:ring-blue-200"
                  value={profileData?.user.last_name || ""}
                  disabled
                />
              </div>
            </div>
            <hr className="border-[#DEDCDC] my-8" />
            <div className="flex justify-between items-start mt-10">
              <div>
                <h3
                  className="font-medium text-[18.63px] text-primary_text"
                  style={{ fontFamily: "Poppins, sans-serif" }}
                >
                  Email
                </h3>
              </div>
              <div>
                <input
                  type="text"
                  placeholder="example@gmail.com"
                  className="border border-gray-300 rounded-md py-2 px-4 focus:outline-none focus:ring-2 focus:ring-blue-200 w-[406px]"
                  value={profileData?.user.email|| ""}
                  disabled
                />
              </div>
            </div>
            <hr className="border-[#DEDCDC] my-8" />
            <div className="flex justify-between items-start mt-10">
              <div>
                <h3
                  className="font-medium text-[18.63px] text-primary_text"
                  style={{ fontFamily: "Poppins, sans-serif" }}
                >
                  Your Photo
                </h3>
                <p
                  className="text-[14.17px] text-primary_text_light"
                  style={{ fontFamily: "Poppins, sans-serif" }}
                >
                  This photo will be displayed on your profile
                </p>
              </div>
              <div className=" flex space-x-2">
                <img
                  src={
                    formData.report
                      ? URL.createObjectURL(formData.report)
                      : profileData?.profilePicture || dp
                  }
                  alt="dp"
                  className="h-20 w-20 rounded-full"
                />{" "}
                {/* Update profile picture */}
                <FormMediaUpload
                  type="file"
                  id="report"
                  name="report"
                  label="Click to upload Image"
                  onChange={handleChange}
                  fileName={formData.report?.name}
                />
              </div>
            </div>
            <hr className="border-[#DEDCDC] my-4" />
            <div className="flex justify-between items-start mt-10">
              <div>
                <h3
                  className="font-medium text-[18.63px] text-primary_text"
                  style={{ fontFamily: "Poppins, sans-serif" }}
                >
                  Role
                </h3>
              </div>
              <div>
                <h3
                  className="font-medium text-[18.63px] text-primary_text"
                  style={{ fontFamily: "Poppins, sans-serif" }}
                >
                  {profileData?.user.user_role || ""}
                </h3>
              </div>
            </div>
            <hr className="border-[#DEDCDC] my-8" />
            <div className="flex justify-between items-start">
              <div className="space-x-2">
                <CustomButton variant="secondary" onClick={closePage}>
                  Cancel
                </CustomButton>
                <CustomButton onClick={handleSubmit}>Submit</CustomButton>{" "}
                {/* Add handleSubmit function */}
              </div>
            </div>
          </div>
        </div>
      </motion.div>
    </div>
  );
};

export default Settings;
