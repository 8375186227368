import { useEffect, useState } from "react";
import { useLocation, Navigate, Outlet } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  selectCurrentTcName,
  selectCurrentToken,
  selectCurrentUserRole,
} from "../../features/auth/authSlice";
import useRedirectToLastRoute from "../../Hooks/useRedirectToLastRoute";


// const getDynamicHomepagePath = (userRole, tc_name) => {
//   const formattedRoute = tc_name.replace(/[ ,]/g, "_");

//   switch (userRole) {
//     case "M_E_OFFICE":
//       return `/m_e_officer/analytics/${formattedRoute}`;
//     case "Validator":
//       return `/validator/analytics`;
//     case "Reviewer":
//       return `/reviewer/analytics`;
//     case "National_Admin":
//       return `/national_admin/analytics`;
//     case "Super_Admin":
//       return `/super_admin/analytics`;
//     case "Pmu":
//       return `/pmu/analytics`;
//     default:
//       return "/auth";
//   }
// };

const RequireAuth = () => {
  const token = useSelector(selectCurrentToken);
  const userRole = useSelector(selectCurrentUserRole);
  const tc_name = useSelector(selectCurrentTcName);
  const location = useLocation();

  // useRedirectToLastRoute(token, userRole);


  return token && userRole ? (
    <Outlet />
  ) : (
    <Navigate to="/auth" state={{ from: location }} replace />
  );
};

export default RequireAuth;


