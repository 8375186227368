import React, { useState } from "react";
import PageHeader from "../../../Components/page_header";
import { navListItems } from "../../../Data_Objects/sidenav";
import ArrowDropDownCircleIcon from "@mui/icons-material/ArrowDropDownCircle";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

import IoiPreview from "../Preview/table_components/Ioi";
import PdoPreview from "../Preview/table_components/Pdo";
import { useSelector } from "react-redux";
import {
  selectCurrentJurisdiction,
  selectCurrentToken,
  selectCurrentUserRole,
} from "../../../features/auth/authSlice";
import { jwtDecode } from "jwt-decode";
import { useQuery } from "@tanstack/react-query";
import { fetchAllTechnicalCollegePosts } from "../../../utils/apiContext";
import ReviewList from "../../../Components/reviewList";
import ShowLoader from "../../../utils/showLoader";
import ShowError from "../../../utils/showError";
import { toast } from "sonner";

const Review = () => {
  const [showAnswers1, setShowAnswers1] = useState(false);
  const [tabIndex1, setTabIndex1] = useState(0);
  const userRoles = useSelector(selectCurrentUserRole);
  const jurisdiction = useSelector(selectCurrentJurisdiction);
  const userTkn = useSelector(selectCurrentToken);
  const decodedTkn = jwtDecode(userTkn);
  const { tc_name } = decodedTkn;

  const {
    data: allTcData,
    isLoading,
    error,
  } = useQuery({
    queryKey: ["all_Tc"],
    queryFn: () => fetchAllTechnicalCollegePosts(jurisdiction),
  });

  if(isLoading) {
    return <ShowLoader />
  }
  if(error){
    toast.error(error.message)
  }

  return (
    <div>
      <PageHeader title="Review" />
      <div className="mt-4 ml-4 mr-2 "></div>
      {allTcData?.map((data) => (
        <ReviewList data={data} key={data?._id} />
      ))}
    </div>
  );
};

export default Review;
