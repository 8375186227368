import { useQuery, useQueries } from "@tanstack/react-query";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  fecthGenActivities,
  fetchAllCCIPosts,
  fetchCCIById,
  fetchProjectSummary,
} from "../utils/apiContext";
import {
  setCciPost,
  setGenActivitiesPost,
  setProjectSummaryPost,
} from "../features/auth/isPostMade";

export const useCCIPost = (jurisdiction, tc_name) => {
  const dispatch = useDispatch();

  const {
    data: allPosts,
    isLoading,
    error,
  } = useQuery({
    queryKey: jurisdiction === "state" ? ["tcCCI"] : ["tcCCI2"],
    queryFn: () => fetchAllCCIPosts(jurisdiction),
  });

  const postQueries = useQueries({
    queries:
      allPosts?.map((post) => ({
        queryKey: ["post", post._id],
        queryFn: () => fetchCCIById(post._id, jurisdiction),
      })) || [],
  });

  const postsWithDetails = postQueries.map((query) => query.data);

  useEffect(() => {
    const isAvailable = postsWithDetails.some(
      (post) => post?.tc_name === tc_name
    );
    if (isAvailable) {
      dispatch(setCciPost(true));
    } else {
      dispatch(setCciPost(false));
    }
  }, [dispatch, postsWithDetails, tc_name]);

  return { isLoading, error, postsWithDetails };
};

export const useProjectSummaryData = (jurisdiction, tc_name) => {
  const dispatch = useDispatch();

  const {
    data: allPosts,
    isLoading,
    error,
  } = useQuery({
    queryKey: ["projectSummary"],
    queryFn: () => fetchProjectSummary(jurisdiction),
  });

  const isChecked = allPosts?.data;
  useEffect(() => {
    const isPostMade = isChecked?.some(
      (post) => post?.name_of_college === tc_name
    );

    if (isPostMade) {
      dispatch(setProjectSummaryPost(true));
    } else {
      dispatch(setProjectSummaryPost(false));
    }
  }, [dispatch, isChecked, tc_name]);

  return { isLoading, error, isChecked };
};

export const useGenActivitiesData = (jurisdiction, tc_name) => {
  const dispatch = useDispatch();

  const {
    data: allGenActivities,
    isLoading,
    error,
  } = useQuery({
    queryKey: ["generalActivitiesData"],
    queryFn: () => fecthGenActivities(jurisdiction),
  });

  useEffect(() => {
    const isPostMade = allGenActivities?.some(
      (post) => post?.tc_name === tc_name
    );

    if (isPostMade) {
      dispatch(setGenActivitiesPost(true));
    } else {
      dispatch(setGenActivitiesPost(false));
    }
  }, [dispatch, allGenActivities, tc_name]);

  return { allGenActivities, isLoading, error };
};
