import React, { useState } from "react";
import PageHeader from "../../Components/page_header";
import { useQuery } from "@tanstack/react-query";
import {
  fetchComponent2MCPData,
  fetchTraineesByBusinessName,
} from "../../utils/apiContext";
import TraineeDataTable from "./TraineesProfileTableData";
import ShowLoader from "../../utils/showLoader";

const TraineesProfile = () => {
  const [chooseName, setChooseName] = useState();

  const {
    data: allMCPData,
    isLoading: mcpIsLoading,
    error: mcpError,
  } = useQuery({
    queryKey: ["mcpProfileData"],
    queryFn: () => fetchComponent2MCPData(),
  });

  const mcpData = allMCPData?.mcps;

  const displayTraineesTable = (businessName) => {
    if (chooseName === businessName) {
      setChooseName(null);
    } else {
      setChooseName(null);
      setTimeout(() => {
        setChooseName(businessName);
      }, 0); // Adjust delay as needed, but 0ms should work in most cases
    }
  };

  const {
    data: traineesDataByBizName,
    isLoading: traineesDataIsLoading,
    error: traineesDataError,
  } = useQuery({
    queryKey: ["traineeData", chooseName],
    queryFn: () => fetchTraineesByBusinessName(chooseName),
    enabled: !!chooseName,
  });

  return (
    <div>
      <PageHeader title="Trainee Beneficiaries" />
      {mcpData?.map((data) => (
        <div key={data._id} className="mt-3 bg-white p-2 mx-2">
          <div className="flex justify-between items-center">
            <h1>{data?.name_of_business}</h1>
            <button
              onClick={() => displayTraineesTable(data?.name_of_business)}
              className="text-active_bg font-bold py-2 rounded-md px-4 cursor-pointer hover:bg-active_bg hover:text-white ml-4 mt-3 text-sm"
              style={{ fontFamily: "Poppins, sans-serif" }}
            >
              {chooseName === data?.name_of_business
                ? "Close Table"
                : "VIEW TRAINEES"}
            </button>
          </div>
          {chooseName === data?.name_of_business && (
            <div className="mt-4">
              {traineesDataIsLoading ? (
                <ShowLoader />
              ) : traineesDataError ? (
                <p className="text-red-500 text-sm">
                  Error loading trainees data
                </p>
              ) : (
                <TraineeDataTable traineesData={traineesDataByBizName} />
              )}
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default TraineesProfile;
