import React, { useState } from "react";
import PageHeader from "../../Components/page_header";
import { pdoFormHeaders, pdoHeaders } from "./FormComponents/formHeaders";
import AssignmentIcon from "@mui/icons-material/Assignment";
import HandymanIcon from "@mui/icons-material/Handyman";
import Pdo1Component2Form from "./FormComponents/Pdo1";
import Pdo2Component2Form from "./FormComponents/Pdo2";
import McpComponent2Form from "./FormComponents/Mcp";
import TraineesComponent2Form from "./FormComponents/Trainees";

const Component2BeneficiariesPage = () => {
  const [openPdoModal, setOpenPdoModal] = useState(null);
  const [openMcpModal, setOpenMcpModal] = useState(false);
  const [openTraineeModal, setOpenTraineeModal] = useState(false);

  const displayPdoModal = (index) => {
    setOpenPdoModal(index);
    // console.log(index)
  };
  const displayMcpModal = () => {
    setOpenMcpModal(true);
    // console.log(index)
  };
  const displayTraineeModal = () => {
    setOpenTraineeModal(true);
    // console.log(index)
  };

  return (
    <div className="">
      <div className="flex justify-between gap-4">
        <div className=" bg-white shadow-md mt-3 p-6 rounded-md">
          <div className="flex gap-2 items-center mb-4">
            <HandymanIcon
              className="text-linear_grad_1"
              style={{ fontSize: "2.0rem" }}
            />{" "}
            <h2
              className="text-active_bg font-bold "
              style={{ fontFamily: "Poppins, sans-serif" }}
            >
              Master Craft Person Data
            </h2>
          </div>
          <div className="flex justify-between items-center">
            <p
              className="text-gray-500 text-sm font-sm mt-2"
              style={{ fontFamily: "Poppins, sans-serif" }}
            >
              Enter Master Craft Persons data. Click save when you're done.
            </p>
            <button
              onClick={displayMcpModal}
              className="text-active_bg text-nowrap font-bold py-2 rounded-md px-4 cursor-pointer hover:bg-active_bg hover:text-white ml-4 mt-3 text-sm"
              style={{ fontFamily: "Poppins, sans-serif" }}
            >
              + ADD DATA
            </button>
          </div>
        </div>
        <div className=" bg-white shadow-md mt-3 p-6 rounded-md">
          <div className="flex gap-2 items-center mb-4">
            <HandymanIcon
              className="text-linear_grad_1"
              style={{ fontSize: "2.0rem" }}
            />{" "}
            <h2
              className="text-active_bg font-bold "
              style={{ fontFamily: "Poppins, sans-serif" }}
            >
              Trainee Data
            </h2>
          </div>
          <div className="flex justify-between items-center">
            <p
              className="text-gray-500 text-sm font-sm mt-2"
              style={{ fontFamily: "Poppins, sans-serif" }}
            >
              Enter Trainee data. Click save when you're done.
            </p>
            <button
              onClick={displayTraineeModal}
              className="text-active_bg text-nowrap font-bold py-2 rounded-md px-4 cursor-pointer hover:bg-active_bg hover:text-white ml-4 mt-3 text-sm"
              style={{ fontFamily: "Poppins, sans-serif" }}
            >
              + ADD DATA
            </button>
          </div>
        </div>
      </div>

      {openMcpModal && (
        <McpComponent2Form
          title="Enter Data for Master Craft Person"
          closeForm={() => setOpenMcpModal(false)}
        />
      )}
      {openTraineeModal && (
        <TraineesComponent2Form
          title="Enter Trainee Beneficiaries Data"
          closeForm={() => setOpenTraineeModal(false)}
        />
      )}
      <div className=" bg-white shadow-md mt-3 p-6 rounded-md">
        <div className="flex gap-2 items-center mb-4">
          <AssignmentIcon
            className="text-linear_grad_1"
            style={{ fontSize: "2.0rem" }}
          />{" "}
          <h2
            className="text-active_bg font-bold"
            style={{ fontFamily: "Poppins, sans-serif" }}
          >
            PDO Indicators
          </h2>
        </div>

        {pdoHeaders.map((item, index) => (
          <div
            key={item.id}
            className="flex items-center justify-between px-2 border-2 border-indicator_border mb-4 text-sm py-2 text-primary_text rounded-md"
            style={{ fontFamily: "Poppins, sans-serif" }}
          >
            <div className="w-[70%]">
              <p className="">{item.title}</p>
            </div>

            <button
              onClick={() => displayPdoModal(index)}
              className="text-active_bg font-bold py-2 rounded-md px-4 cursor-pointer hover:bg-active_bg hover:text-white"
            >
              + ADD DATA
            </button>
          </div>
        ))}
        {openPdoModal === 0 && (
          <Pdo1Component2Form
            title={pdoFormHeaders[openPdoModal].title}
            closeForm={() => setOpenPdoModal(null)}
          />
        )}
        {openPdoModal === 1 && (
          <Pdo2Component2Form
            title={pdoFormHeaders[openPdoModal].title}
            closeForm={() => setOpenPdoModal(null)}
          />
        )}
      </div>
    </div>
  );
};

export default Component2BeneficiariesPage;
