import React from "react";
import { Link } from "react-router-dom";
import Ideas from "../../Assets/ideas_logo.svg"
import { useSelector } from "react-redux";
import { selectCurrentTcName, selectCurrentUserRole } from "../../features/auth/authSlice";

const HomePage = () => {
  const tc_name = useSelector(selectCurrentTcName)
  const userRole = useSelector(selectCurrentUserRole)

  const getDynamicHomepagePath = (userRole) => {
    const formattedRoute = tc_name?.replace(/[ ,]/g, "_");
    
    switch (userRole) {
      case "M_E_OFFICE":
        return `/m_e_officer/analytics/${formattedRoute}`;
      case "Validator":
        return `/validator/analytics`;
      case "Reviewer":
        return `/reviewer/analytics`;
      case "National_Admin":
        return `/national_admin/analytics`;
      case "Super_Admin":
        return `/super_admin/analytics`;
      case "Pmu":
        return `/pmu/analytics`;
      default:
        return "/auth";
    }

    
  };
  return (
    <div className="bg-main_bg w-full h-screen flex justify-center items-center">
      <div className="flex flex-col justify-center items-center">
        <img className="w-1/2" src={Ideas} alt="Ideas Logo" />
        <br />
        <h1 className="text-white font-bold text-2xl">
          Dear User, Welcome to IDEAS MIS
        </h1>
        <br />
        <Link to={getDynamicHomepagePath(userRole)} className="bg-white text-main_bg px-3 py-2 rounded-sm shadow-md">
          {userRole.length === 0 ? "Sign in" : "Visit Dashboard"}
        </Link>
      </div>
    </div>
  );
};

export default HomePage;
