import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper/modules";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { Box } from "@mui/material";
import { motion } from "framer-motion";

import { BarChartDisplayTtiAndSacs } from "../../Analytics_Dashboard/chart_components/BarChartDisplays";

const IntermediateCardComponent4 = ({
  stateCount,
  federalCount,
  skillAcquisitionCenterCount,
}) => {
  return (
    <div className="">
      <h1
        className="font-bold text-xl mb-3 text-primary_text"
        style={{ fontFamily: "Poppins, sans-serif" }}
      >
        Intermediate Outcome Indicators
      </h1>
      <Swiper
        modules={[Navigation, Pagination]}
        spaceBetween={30}
        slidesPerView={2}
        navigation={{ nextEl: ".button-next1", prevEl: ".button-prev1" }}
      >
        <SwiperSlide>
          <h4 className="font-semibold">NSQF Assessors</h4>

          <BarChartDisplayTtiAndSacs
            stateCount={stateCount}
            federalCount={federalCount}
            skillAcquisitionCenterCount={skillAcquisitionCenterCount}
          />
          <p className="text-xs" style={{ fontFamily: "Poppins, sans-serif" }}>
            Number of TTIs in IDEAS Supported Federal & state Tcs, and skill
            acquisition center Trained & certified as NSQF Assessors
          </p>
        </SwiperSlide>
        <SwiperSlide>
          <h4 className="font-semibold">NSQF Verifiers</h4>

          <BarChartDisplayTtiAndSacs
            stateCount={stateCount}
            federalCount={federalCount}
            skillAcquisitionCenterCount={skillAcquisitionCenterCount}
          />
          <p className="text-xs" style={{ fontFamily: "Poppins, sans-serif" }}>
            Number of TTIs in IDEAS Supported Federal & state Tcs, and skill
            acquisition center Trained & certified as NSQF Verifiers
          </p>
        </SwiperSlide>
      </Swiper>
      <Box className="flex items-center justify-between text-primary_text_light mt-3">
        <motion.div whileTap={{ scale: 0.9 }} className="button-prev1">
          <KeyboardArrowLeftIcon
            sx={{ fontSize: "60px" }}
            className="cursor-pointer"
          />
        </motion.div>
        <motion.div whileTap={{ scale: 0.9 }} className="button-next1">
          <KeyboardArrowRightIcon
            sx={{ fontSize: "60px" }}
            className="cursor-pointer"
          />
        </motion.div>
      </Box>
    </div>
  );
};

export default IntermediateCardComponent4;
