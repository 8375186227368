import React, { useState, useRef, useEffect } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { motion } from "framer-motion";
import { useDispatch, useSelector } from "react-redux";
import {
  selectCurrentJurisdiction,
  selectCurrentToken,
} from "../../features/auth/authSlice";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import axiosInstance from "../../Services/axiosInstance";
import { toast } from "sonner";
import { getTcName } from "../../utils/getFormattedToken";
import { FormDropdown, FormInput } from "../form";
import { setCciPost } from "../../features/auth/isPostMade";
import { validatorStates } from "../Modal_Forms/Component1/states";

const ModalCrossCuttingIssuesForm = ({ open, handleClose }) => {
  const modalRef = useRef(null);
  const jurisdiction = useSelector(selectCurrentJurisdiction);
  const userTkn = useSelector(selectCurrentToken);
  const tc_name = getTcName(userTkn);
  const queryClient = useQueryClient();
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [state, setState] = useState("");
  const [grmReported, setGrmReported] = useState();
  const [grmInvestigated, setGrmInvestigated] = useState();
  const [grmUninvestigated, setGrmUninvestigated] = useState();
  const [grmResolved, setGrmResolved] = useState();
  const [grmUnresolved, setGrmUnresolved] = useState();
  const [gbvReported, setGbvReported] = useState();
  const [gbvInvestigated, setGbvInvestigated] = useState();
  const [gbvUninvestigated, setGbvUninvestigated] = useState();
  const [gbvResolved, setGbvResolved] = useState();
  const [gbvUnresolved, setGbvUnresolved] = useState();
  const [fcReported, setFcReported] = useState();
  const [fcInvestigated, setFcInvestigated] = useState();
  const [fcUninvestigated, setFcUninvestigated] = useState();
  const [fcResolved, setFcResolved] = useState();
  const [fcUnresolved, setFcUnresolved] = useState();
  const [ssiReported, setSsiReported] = useState();
  const [ssiInvestigated, setSsiInvestigated] = useState();
  const [ssiUninvestigated, setSsiUninvestigated] = useState();
  const [ssiResolved, setSsiResolved] = useState();
  const [ssiUnresolved, setSsiUnresolved] = useState();
  const [esiReported, setEsiReported] = useState();
  const [esiInvestigated, setEsiInvestigated] = useState();
  const [esiUninvestigated, setEsiUninvestigated] = useState();
  const [esiResolved, setEsiResolved] = useState();
  const [esiUnresolved, setEsiUnresolved] = useState();

  const dispatch = useDispatch();
  const validate = () => {
    const newErrors = {};
    const requiredFields = {};

    if (jurisdiction === "state") {
      requiredFields.state = state;
    }

    Object.keys(requiredFields).forEach((field) => {
      if (!requiredFields[field]) {
        newErrors[field] = "This field is required";
      }
    });

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };
  const mutation = useMutation({
    mutationFn: async (formData) => {
      setIsLoading(true);

      const response = await axiosInstance.post(
        `/ccs/c1/make-cross-cutting-issues`,
        formData
      );
      console.log(response.data);
      return response;
    },
    onSuccess: (response) => {
      toast.success("Successful");
      setIsLoading(false);
      queryClient.invalidateQueries(["tcCCI"]);
      const isCheck = response.data.new_ccs;
      const letSee = `${jurisdiction}_tc`;

      const isPostMade = isCheck[letSee].some(
        (post) => post.tc_name === tc_name
      );

      if (isPostMade) {
        dispatch(setCciPost(true));
      } else {
        dispatch(setCciPost(false));
      }
      handleClose();
    },
    onError: (err) => {
      if (err.response.status === 400) {
        toast.error("Post already exists");
      } else {
        toast.error("An Error Occurred");
      }
      setIsLoading(false);
      // handleClose();
    },
  });

  const handleInputChange = (e, setValue) => {
    setValue(e.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (!validate()) {
      toast.error("Required Fields can't be empty");
      return;
    }
    const newObj = {};
    newObj.jurisdiction = jurisdiction;
    newObj.tc_name = tc_name;
    newObj.state = state;
    newObj.gender_based_violence = {
      reported: gbvReported,
      investigated: gbvInvestigated,
      uninvestigated: gbvUninvestigated,
      resolved: gbvResolved,
      unresolved: gbvUnresolved,
    };
    newObj.greviance_redress_mechanisms = {
      reported: grmReported,
      investigated: grmInvestigated,
      uninvestigated: grmUninvestigated,
      resolved: grmResolved,
      unresolved: grmUnresolved,
    };
    newObj.fraud_corruption = {
      reported: fcReported,
      investigated: fcInvestigated,
      uninvestigated: fcUninvestigated,
      resolved: fcResolved,
      unresolved: fcUnresolved,
    };
    newObj.social_safeguard_issues = {
      reported: ssiReported,
      investigated: ssiInvestigated,
      uninvestigated: ssiUninvestigated,
      resolved: ssiResolved,
      unresolved: ssiUnresolved,
    };
    newObj.environmental_safeguard_issues = {
      reported: esiReported,
      investigated: esiInvestigated,
      uninvestigated: esiUninvestigated,
      resolved: esiResolved,
      unresolved: esiUnresolved,
    };

    mutation.mutate(newObj);
    // handleClose();
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        handleClose();
      }
    };

    // Add event listener when the component mounts
    document.addEventListener("mousedown", handleClickOutside);

    // Remove event listener on cleanup
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [handleClose]);

  const modalVariants = {
    hidden: {
      y: "-100vh",
      opacity: 0,
    },
    visible: {
      y: "0",
      opacity: 1,
      transition: { delay: 0.2, type: "spring", stiffness: 120 },
    },
  };

  return (
    <div className="fixed left-0 top-0 w-full h-full z-20 bg-black bg-opacity-50 flex justify-center items-center">
      <motion.div
        ref={modalRef}
        variants={modalVariants}
        initial="hidden"
        animate="visible"
        exit="hidden"
        className="scrollbar-hide overflow-auto w-[50%] h-[98vh] px-8 pt-8 pb-6 bg-white rounded-lg relative"
      >
        <div className="flex justify-between font-bold">
          <h1 className="text-2xl">Cross Cutting Issues</h1>

          <CloseIcon
            onClick={handleClose}
            style={{ fontSize: "2rem" }}
            className="text-red-500 cursor-pointer absolute top-2 right-4"
          />
        </div>
        <hr className="mt-2 mb-4" />
        <form onSubmit={handleSubmit}>
        {jurisdiction === "state" && (
            <FormDropdown
              name="state"
              label="Enter State Location"
              value={state}
              options={validatorStates}
              onChange={(e) => handleInputChange(e, setState)}
              errorMessage={errors.state}
              isRequired={true}
            />
          )}
          <div className="mb-4">
            <label className="block text-sm font-bold mb-2">
              Greivance Redress Mechanism
            </label>
            <div className="ml-2">
              <div className="flex gap-3 items-center mb-2">
                <label className="text-sm">Reported:</label>
                <input
                  type="number"
                  name="grieviance_redress_mechanism_reported"
                  value={grmReported}
                  onChange={(e) => handleInputChange(e, setGrmReported)}
                  className="appearance-none border rounded w-1/2 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                />
              </div>
              <div className="flex gap-3 items-center mb-2">
                <label className="text-sm">Investigated:</label>
                <input
                  type="number"
                  name="grieviance_redress_mechanism_investigated"
                  value={grmInvestigated}
                  onChange={(e) => handleInputChange(e, setGrmInvestigated)}
                  className="appearance-none border rounded w-1/2 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                />
              </div>
              <div className="flex gap-3 items-center mb-2">
                <label className="text-sm">Uninvestigated:</label>
                <input
                  type="number"
                  name="grieviance_redress_mechanism_uninvestigated"
                  value={grmUninvestigated}
                  onChange={(e) => handleInputChange(e, setGrmUninvestigated)}
                  className="appearance-none border rounded w-1/2 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                />
              </div>
              <div className="flex gap-3 items-center mb-2">
                <label className="text-sm">Resolved:</label>
                <input
                  type="number"
                  name="grieviance_redress_mechanism_resolved"
                  value={grmResolved}
                  onChange={(e) => handleInputChange(e, setGrmResolved)}
                  className="appearance-none border rounded w-1/2 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                />
              </div>
              <div className="flex gap-3 items-center mb-2">
                <label className="text-sm">Unresolved:</label>
                <input
                  type="number"
                  name="grieviance_redress_mechanism_unresolved"
                  value={grmUnresolved}
                  onChange={(e) => handleInputChange(e, setGrmUnresolved)}
                  className="appearance-none border rounded w-1/2 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                />
              </div>
            </div>
          </div>
          <div className="mb-4">
            <label className="block text-sm font-bold mb-2">
              Gender Based Violence
            </label>
            <div className="ml-2">
              <div className="flex gap-3 items-center mb-2">
                <label className="text-sm">Reported:</label>
                <input
                  type="number"
                  name="gender_based_violence_reported"
                  value={gbvReported}
                  onChange={(e) => handleInputChange(e, setGbvReported)}
                  className="appearance-none border rounded w-1/2 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                />
              </div>
              <div className="flex gap-3 items-center mb-2">
                <label className="text-sm">Investigated:</label>
                <input
                  type="number"
                  name="gender_based_violence_investigated"
                  value={gbvInvestigated}
                  onChange={(e) => handleInputChange(e, setGbvInvestigated)}
                  className="appearance-none border rounded w-1/2 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                />
              </div>
              <div className="flex gap-3 items-center mb-2">
                <label className="text-sm">Uninvestigated:</label>
                <input
                  type="number"
                  name="gender_based_violence_uninvestigated"
                  value={gbvUninvestigated}
                  onChange={(e) => handleInputChange(e, setGbvUninvestigated)}
                  className="appearance-none border rounded w-1/2 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                />
              </div>
              <div className="flex gap-3 items-center mb-2">
                <label className="text-sm">Resolved:</label>
                <input
                  type="number"
                  name="gender_based_violence_resolved"
                  value={gbvResolved}
                  onChange={(e) => handleInputChange(e, setGbvResolved)}
                  className="appearance-none border rounded w-1/2 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                />
              </div>
              <div className="flex gap-3 items-center mb-2">
                <label className="text-sm">Unresolved:</label>
                <input
                  type="number"
                  name="gender_based_violence_unresolved"
                  value={gbvUnresolved}
                  onChange={(e) => handleInputChange(e, setGbvUnresolved)}
                  className="appearance-none border rounded w-1/2 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                />
              </div>
            </div>
          </div>
          <div className="mb-4">
            <label className="block text-sm font-bold mb-2">
              Fraud and Corruption
            </label>
            <div className="ml-2">
              <div className="flex gap-3 items-center mb-2">
                <label className="text-sm">Reported:</label>
                <input
                  type="number"
                  name="fraud_and_corruption_reported"
                  value={fcReported}
                  onChange={(e) => handleInputChange(e, setFcReported)}
                  className="appearance-none border rounded w-1/2 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                />
              </div>
              <div className="flex gap-3 items-center mb-2">
                <label className="text-sm">Investigated:</label>
                <input
                  type="number"
                  name="fraud_and_corruption_investigated"
                  value={fcInvestigated}
                  onChange={(e) => handleInputChange(e, setFcInvestigated)}
                  className="appearance-none border rounded w-1/2 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                />
              </div>
              <div className="flex gap-3 items-center mb-2">
                <label className="text-sm">Uninvestigated:</label>
                <input
                  type="number"
                  name="fraud_and_corruption_uninvestigated"
                  value={fcUninvestigated}
                  onChange={(e) => handleInputChange(e, setFcUninvestigated)}
                  className="appearance-none border rounded w-1/2 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                />
              </div>
              <div className="flex gap-3 items-center mb-2">
                <label className="text-sm">Resolved:</label>
                <input
                  type="number"
                  name="fraud_and_corruption_resolved"
                  value={fcResolved}
                  onChange={(e) => handleInputChange(e, setFcResolved)}
                  className="appearance-none border rounded w-1/2 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                />
              </div>
              <div className="flex gap-3 items-center mb-2">
                <label className="text-sm">Unresolved:</label>
                <input
                  type="number"
                  name="fraud_and_corruption_unresolved"
                  value={fcUnresolved}
                  onChange={(e) => handleInputChange(e, setFcUnresolved)}
                  className="appearance-none border rounded w-1/2 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                />
              </div>
            </div>
          </div>
          <div className="mb-4">
            <label className="block text-sm font-bold mb-2">
              Social Safeguard Issues
            </label>
            <div className="ml-2">
              <div className="flex gap-3 items-center mb-2">
                <label className="text-sm">Reported:</label>
                <input
                  type="number"
                  name="social_safeguard_issues_reported"
                  value={ssiReported}
                  onChange={(e) => handleInputChange(e, setSsiReported)}
                  className="appearance-none border rounded w-1/2 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                />
              </div>
              <div className="flex gap-3 items-center mb-2">
                <label className="text-sm">Investigated:</label>
                <input
                  type="number"
                  name="social_safeguard_issues_investigated"
                  value={ssiInvestigated}
                  onChange={(e) => handleInputChange(e, setSsiInvestigated)}
                  className="appearance-none border rounded w-1/2 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                />
              </div>
              <div className="flex gap-3 items-center mb-2">
                <label className="text-sm">Uninvestigated:</label>
                <input
                  type="number"
                  name="social_safeguard_issues_uninvestigated"
                  value={ssiUninvestigated}
                  onChange={(e) => handleInputChange(e, setSsiUninvestigated)}
                  className="appearance-none border rounded w-1/2 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                />
              </div>
              <div className="flex gap-3 items-center mb-2">
                <label className="text-sm">Resolved:</label>
                <input
                  type="number"
                  name="social_safeguard_issues_resolved"
                  value={ssiResolved}
                  onChange={(e) => handleInputChange(e, setSsiResolved)}
                  className="appearance-none border rounded w-1/2 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                />
              </div>
              <div className="flex gap-3 items-center mb-2">
                <label className="text-sm">Unresolved:</label>
                <input
                  type="number"
                  name="social_safeguard_issues_unresolved"
                  value={ssiUnresolved}
                  onChange={(e) => handleInputChange(e, setSsiUnresolved)}
                  className="appearance-none border rounded w-1/2 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                />
              </div>
            </div>
          </div>
          <div className="mb-4">
            <label className="block text-sm font-bold mb-2">
              Environmental Safeguard Issues
            </label>
            <div className="ml-2">
              <div className="flex gap-3 items-center mb-2">
                <label className="text-sm">Reported:</label>
                <input
                  type="number"
                  name="environment_safeguard_issues_reported"
                  value={esiReported}
                  onChange={(e) => handleInputChange(e, setEsiReported)}
                  className="appearance-none border rounded w-1/2 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                />
              </div>
              <div className="flex gap-3 items-center mb-2">
                <label className="text-sm">Investigated:</label>
                <input
                  type="number"
                  name="environment_safeguard_issues_investigated"
                  value={esiInvestigated}
                  onChange={(e) => handleInputChange(e, setEsiInvestigated)}
                  className="appearance-none border rounded w-1/2 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                />
              </div>
              <div className="flex gap-3 items-center mb-2">
                <label className="text-sm">Uninvestigated:</label>
                <input
                  type="number"
                  name="environment_safeguard_issues_uninvestigated"
                  value={esiUninvestigated}
                  onChange={(e) => handleInputChange(e, setEsiUninvestigated)}
                  className="appearance-none border rounded w-1/2 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                />
              </div>
              <div className="flex gap-3 items-center mb-2">
                <label className="text-sm">Resolved:</label>
                <input
                  type="number"
                  name="environment_safeguard_issues_resolved"
                  value={esiResolved}
                  onChange={(e) => handleInputChange(e, setEsiResolved)}
                  className="appearance-none border rounded w-1/2 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                />
              </div>
              <div className="flex gap-3 items-center mb-2">
                <label className="text-sm">Unresolved:</label>
                <input
                  type="number"
                  name="environment_safeguard_issues_unresolved"
                  value={esiUnresolved}
                  onChange={(e) => handleInputChange(e, setEsiUnresolved)}
                  className="appearance-none border rounded w-1/2 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                />
              </div>
            </div>
          </div>
          <div className="flex justify-center">
            <button
              type="submit"
              className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-active_bg text-base font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:w-auto sm:text-sm"
              disabled={isLoading}
            >
              {isLoading ? "Saving..." : "Save"}
            </button>
          </div>
        </form>
      </motion.div>
    </div>
  );
};

export default ModalCrossCuttingIssuesForm;
