import React from "react";
import { TeachersDoughnutChartDisplay } from "../chart_components/DoughnutChart";
import { useSelector } from "react-redux";
import { getTcName } from "../../../../utils/getFormattedToken";
import {
  selectCurrentJurisdiction,
  selectCurrentToken,
  selectCurrentUserRole,
  selectValidatorState,
} from "../../../../features/auth/authSlice";
import axiosInstance from "../../../../Services/axiosInstance";
import { useQuery } from "@tanstack/react-query";
import ShowError from "../../../../utils/showError";
import ShowLoader from "../../../../utils/showLoader";
import {
  fetchAllTeachersByState,
  fetchAllTeachersPosts,
} from "../../../../utils/apiContext";

const tradeColorMap = {
  Construction: "#0E4F2F",
  "Mechanical Engineering": "#45BC5F",
  Hospitality: "#843C30",
  "Electrical Engineering": "#BC5645",
  ICT: "#BC5645",
  "Agricultural Science": "#C0AA54",
  "Garment Making": "#CFF0B5",
};

const TeachersAnalyticsDisplay = ({ Teachersdata, id }) => {
  const jurisdiction = useSelector(selectCurrentJurisdiction);
  const userTkn = useSelector(selectCurrentToken);
  const userRole = useSelector(selectCurrentUserRole);
  const tc_name = getTcName(userTkn);
  const isUser1 = tc_name;
  const validatorState = useSelector(selectValidatorState);

  const { data, isLoading, error } = useQuery({
    queryKey: ["teachersData"],
    queryFn: () => fetchAllTeachersPosts(jurisdiction),
  });

  const aggregateData = (data) => {
    // const filteredData =
    //   userRole === "M_E_OFFICE"
    //     ? data.filter((teacher) => teacher.tc_name === tc_name)
    //     : userRole === "Validator"
    //     ? teachersDataByState
    //     : Teachersdata;
    const filteredData =
      userRole === "M_E_OFFICE"
        ? data.filter((teacher) => teacher.tc_name === tc_name)
        : Teachersdata;

    const tradeCounts =
      tc_name || Teachersdata
        ? filteredData?.reduce((acc, teacher) => {
            const trade = teacher.area_of_specialization;
            if (!acc[trade]) {
              acc[trade] = 0;
            }
            acc[trade] += 1;
            return acc;
          }, {})
        : data?.reduce((acc, teacher) => {
            const trade = teacher.area_of_specialization;
            if (!acc[trade]) {
              acc[trade] = 0;
            }
            acc[trade] += 1;
            return acc;
          }, {});

    // if (!tradeCounts || Object.keys(tradeCounts).length === 0) {
    //   return [];
    // }

    return Object.keys(tradeCounts)?.map((trade) => ({
      id: trade,
      value: tradeCounts[trade],
      label: trade,
      color: tradeColorMap[trade] || "#000000", // Using the same tradeColorMap
    }));
  };

  const chartData = data ? aggregateData(data) : [];

  if (isLoading) {
    return <ShowLoader />;
  }
  if (error) {
    return <ShowError />;
  }
  // console.log("teacherData:", data);
  return (
    <div className="px-4 py-4">
      <h1
        className="text-primary_text font-bold text-[20px]"
        style={{ fontFamily: "Poppins, sans-serif" }}
      >
        Teachers in Occupational Trades
      </h1>
      <br />
      <div>
        <TeachersDoughnutChartDisplay data={chartData} />
      </div>
    </div>
  );
};

export default TeachersAnalyticsDisplay;
