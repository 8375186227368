import * as React from "react";
import { useState, useMemo, useEffect } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { crossCuttingIssuesDataHeader } from "./tc_data";
import { v4 as uuidv4 } from "uuid";
import { Paper } from "@mui/material";
import { useQueries, useQuery, useQueryClient } from "@tanstack/react-query";
import axiosInstance from "../../../../Services/axiosInstance";
import { useSelector } from "react-redux";
import {
  selectCurrentJurisdiction,
  selectCurrentToken,
  selectCurrentUserRole,
} from "../../../../features/auth/authSlice";
import { getTcName } from "../../../../utils/getFormattedToken";
import { toSentenceCase } from "../../../../utils/toSentenceCase";
import ShowLoader from "../../../../utils/showLoader";
import ShowError from "../../../../utils/showError";
import MoreOptionsMenu from "../../../../Components/MoreOptionsIcon";
import { toast } from "sonner";
import EditModalForm from "../../../../Components/Modal_Forms/EditModal";
import { fetchAllCCIPosts, fetchCCIById } from "../../../../utils/apiContext";

const categories = [
  "environmental_safeguard_issues",
  "fraud_corruption",
  "gender_based_violence",
  "greviance_redress_mechanisms",
  "social_safeguard_issues",
];

const fields = [
  { id: "reported", label: "Reported", type: "number" },
  { id: "investigated", label: "Investigated", type: "number" },
  { id: "uninvestigated", label: "Uninvestigated", type: "number" },
  { id: "resolved", label: "Resolved", type: "number" },
  { id: "unresolved", label: "Unresolved", type: "number" },
];

export default function CrossCuttingIssuesTable({
  schoolData,
  propsJurisdiction,
}) {
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState(null);
  const [filteredPosts, setFilteredPosts] = useState([]);

  const jurisdiction = useSelector(selectCurrentJurisdiction);
  const userTkn = useSelector(selectCurrentToken);
  const tc_name = getTcName(userTkn);
  const queryClient = useQueryClient();
  const userRole = useSelector(selectCurrentUserRole);
  const isNationalAdmin = userRole === "National_Admin";

  const combJurisdiction = isNationalAdmin ? propsJurisdiction : jurisdiction;
  
  const {
    data: allPosts,
    isLoading,
    error,
  } = useQuery({
    queryKey: combJurisdiction === "state" ? ["tcCCI"] : ["tcCCI2"],
    queryFn: () => fetchAllCCIPosts(combJurisdiction),
  });
  
  const postQueries = useQueries({
    queries:
      allPosts?.map((post) => ({
        queryKey: ["post", post._id],
        queryFn: () =>
          fetchCCIById(
            post._id,
            isNationalAdmin ? propsJurisdiction : jurisdiction
          ),
      })) || [],
  });

  const stablePostQueries = useMemo(() => postQueries, [postQueries]);

  useEffect(() => {
    setSelectedRowData(null);
    setIsEditModalOpen(false);
    if ((tc_name || schoolData) && stablePostQueries.length > 0) {
      const postsWithDetails = stablePostQueries.map((query) => query.data);
      const filtered = postsWithDetails.filter(
        (post) => post?.tc_name === (tc_name || schoolData)
      );
      setFilteredPosts(filtered);
    } else {
      setFilteredPosts([]);
    }
  }, [schoolData, stablePostQueries, tc_name]);

  const onEdit = (rowData, category) => {
    const categoryData = {
      ...rowData[category],
      category,
      tc_name: rowData.tc_name,
      _id: rowData._id,
    };
    setSelectedRowData(categoryData);
    setIsEditModalOpen(true);
  };

  const handleEditAndSave = async (updatedData) => {
    try {
      const { category, tc_name, _id, ...rest } = updatedData;
      const updatedPost = { [category]: rest };

      await axiosInstance.put(
        `/ccs/c1/update-specific-cross-cutting-issue/${_id}?jurisdiction=${jurisdiction}`,
        updatedPost
      );
      queryClient.invalidateQueries(["tcCCS"]);
    } catch (error) {
      console.error("Failed to save the updated data:", error);
    }
  };

  const onDelete = () => {
    toast.error("Can not delete Cross Cutting Issues");
  };

  if (isLoading) {
    return <ShowLoader />;
  }
  if (error?.response?.status === 400) {
    return <ShowError />;
  }

  const formatCategory = (category) => {
    if (category === "fraud_corruption") {
      return "Fraud and Corruption";
    }
    return toSentenceCase(category.replace(/_/g, " "));
  };

  return (
    <>
      <TableContainer
        component={Paper}
        className="scrollbar scrollbar-w-2 scrollbar-h-1.5 scrollbar-thumb-rounded-[50px] scrollbar-track-gray-300 scrollbar-track-rounded-[50px] scrollbar-thumb-[#A5A2A2] overflow-x-scroll h-full"
      >
        <Table aria-label="simple table" sx={{ backgroundColor: "#F2F2F2" }}>
          <TableHead sx={{ backgroundColor: "#FFFFFF" }}>
            <TableRow>
              <TableCell
                sx={{
                  whiteSpace: "nowrap",
                  width: "1%",
                  fontWeight: "bold",
                  color: "#2D3142",
                }}
              >
                Options
              </TableCell>
              {crossCuttingIssuesDataHeader.map((item) => (
                <TableCell
                  key={item.id}
                  sx={{
                    whiteSpace: "nowrap",
                    width: "1%",
                    fontWeight: "bold",
                    color: "#2D3142",
                  }}
                >
                  {item.title}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody className="border-2 mt-2">
            {filteredPosts?.map((post) =>
              categories.map((category) => (
                <TableRow
                  key={uuidv4()}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell align="center">
                    <MoreOptionsMenu
                      onEdit={() => onEdit(post, category)}
                      onDelete={onDelete}
                    />
                  </TableCell>

                  <TableCell align="center">
                    {formatCategory(category)}
                  </TableCell>
                  <TableCell align="center">
                    {post[category]?.reported}
                  </TableCell>
                  <TableCell align="center">
                    {post[category]?.investigated}
                  </TableCell>
                  <TableCell align="center">
                    {post[category]?.uninvestigated}
                  </TableCell>
                  <TableCell align="center">
                    {post[category]?.resolved}
                  </TableCell>
                  <TableCell align="center">
                    {post[category]?.unresolved}
                  </TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <EditModalForm
        open={isEditModalOpen}
        handleClose={() => setIsEditModalOpen(false)}
        rowData={selectedRowData}
        handleSave={handleEditAndSave}
        fields={fields}
        header="Edit Technical College Data"
      />
    </>
  );
}
