import { useState } from "react";
import { intermediateOutcomeHeader } from "../../../../Models/ComponentOne/IntermediateOIndicators/data";
import ArrowDropDownCircleIcon from "@mui/icons-material/ArrowDropDownCircle";
import {
  IoiPreview1,
  IoiPreview2,
  IoiPreview3,
  IoiPreview4,
} from "./models/intermediate-data";
import { Button } from "@mui/material";
import RejectDataEntry from "../../../../Components/Modal_Forms/RejectMessage/Reject";
import ViewPdf from "../../../../Components/PdfModalView/ViewPdf";
import { pdfjs } from "react-pdf";
import "react-pdf/dist/Page/TextLayer.css";


import Ioi1Display from "../displays/ioi/Ioi1";
import Ioi2Display from "../displays/ioi/Ioi2";
import Ioi3Display from "../displays/ioi/Ioi3";
import Ioi4Display from "../displays/ioi/Ioi4";


pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  "pdfjs-dist/build/pdf.worker.min.js",
  import.meta.url
).toString();

const IoiPreview = ({ data, propsJurisdiction }) => {
  const propsTc_name = data?.institution_name;
  // console.log(propsTc_name);
  const [showAnswers, setShowAnswers] = useState({});
  
  
  const displayAnswers = (index) => {
    setShowAnswers((prev) => ({
      ...prev,
      [index]: !prev[index],
    }));
    // console.log("ioi data:", ioi_2);
  };

  return (
    <div>
      {intermediateOutcomeHeader?.map((item, index) => (
        <div key={item?.id} className=" text-primary_text cursor-pointer">
          <div
            onClick={() => {
              displayAnswers(index);
            }}
            className="flex justify-between items-center border-2 border-indicator_border mb-2 rounded-md px-4 h-16 relative"
            style={{ fontFamily: "Poppins, sans-serif" }}
          >
            <div className="w-[85%] text-md font-bold">{item?.header}</div>
            {!showAnswers[index] ? (
              <ArrowDropDownCircleIcon
                sx={{ fontSize: "30px", color: "#0E4F2F" }}
              />
            ) : (
              <ArrowDropDownCircleIcon
                sx={{
                  fontSize: "30px",
                  color: "#0E4F2F",
                  transform: "rotate(180deg)",
                }}
              />
            )}
          </div>

          {showAnswers[index] && (
            <div
              className={`relative ${index === 0 && "h-[55vh]"} ${
                index === 1 && "h-[60vh]"
              } ${index === 2 && "h-[70vh]"} ${index === 3 && "h-[80vh]"}`}
            >
              {index === 0 && (
                <Ioi1Display
                  questions={IoiPreview1}
                  propsTc_name={propsTc_name}
                  propsJurisdiction={propsJurisdiction}
                />
              )}
              {index === 1 && (
                <Ioi2Display
                  questions={IoiPreview2}
                  propsTc_name={propsTc_name}
                  propsJurisdiction={propsJurisdiction}
                />
              )}
              {index === 2 && (
                <Ioi3Display
                  questions={IoiPreview3}
                  propsTc_name={propsTc_name}
                  propsJurisdiction={propsJurisdiction}
                />
              )}
              {index === 3 && (
                <Ioi4Display
                  questions={IoiPreview4}
                  propsTc_name={propsTc_name}
                  propsJurisdiction={propsJurisdiction}
                />
              )}
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default IoiPreview;
