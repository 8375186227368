import React from 'react'

export const TraineesGraduatedFedTCCard = ({totalTraineesGraduatedFedTC}) => {
  return (
    <div className="custom-shadow bg-white p-2 rounded-lg">
      <h1
        className="font-bold text-primary_text"
        style={{ fontFamily: "Poppins, sans-serif" }}
      >
        <span className='text-red-500'>Trainees Graduated</span> in private-sector programs at <span className='text-red-500'>Federal Technical Colleges</span>.
      </h1>
      <h1
        className="text-active_bg text-2xl font-bold mt-2"
        style={{ fontFamily: "Poppins, sans-serif" }}
      >
       {totalTraineesGraduatedFedTC}
      </h1>
    </div>
  )
}
export const TraineesGraduatedStateTCCard = ({totalTraineesGraduatedStateTC}) => {
  return (
    <div className="custom-shadow bg-white p-2 rounded-lg">
      <h1
        className="font-bold text-primary_text"
        style={{ fontFamily: "Poppins, sans-serif" }}
      >
        <span className='text-red-500'>Trainees Graduated</span> in private-sector programs at <span className='text-red-500'>State Technical Colleges</span>.
      </h1>
      <h1
        className="text-active_bg text-2xl font-bold mt-2"
        style={{ fontFamily: "Poppins, sans-serif" }}
      >
       {totalTraineesGraduatedStateTC}
      </h1>
    </div>
  )
}

