import React, { useEffect, useRef, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { motion } from "framer-motion";
import { FormInput } from "../../../Components/form";
import CustomButton from "../../../Components/button";
import { toast } from "sonner";
import axiosInstance from "../../../Services/axiosInstance";
import { useMutation } from "react-query";

const Pdo2Component2Form = ({ closeForm, title }) => {
  const modalRef = useRef(null);

  // State Variables
  const [nameOfBusiness, setNameOfBusiness] = useState("");
  const [enrolledFederalMale, setEnrolledFederalMale] = useState("");
  const [enrolledFederalFemale, setEnrolledFederalFemale] = useState("");
  const [enrolledStateMale, setEnrolledStateMale] = useState("");
  const [enrolledStateFemale, setEnrolledStateFemale] = useState("");
  const [graduatedFederalMale, setGraduatedFederalMale] = useState("");
  const [graduatedFederalFemale, setGraduatedFederalFemale] = useState("");
  const [graduatedStateMale, setGraduatedStateMale] = useState("");
  const [graduatedStateFemale, setGraduatedStateFemale] = useState("");
  const [assessorMale, setAssessorMale] = useState("");
  const [assessorFemale, setAssessorFemale] = useState("");
  const [verifierMale, setVerifierMale] = useState("");
  const [verifierFemale, setVerifierFemale] = useState("");

  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const mutation = useMutation({
    mutationFn: async (formData) => {
      setIsLoading(true);
      const response = await axiosInstance.post(
        `/pdo/c2/make-direct-project-beneficiaries`,
        formData
      );
      console.log(response.data);
      return response;
    },
    onSuccess: (response) => {
      toast.success("Successful");
      setIsLoading(false);
      closeForm();
    },
    onError: (err) => {
      setIsLoading(false);
      closeForm();
    },
  });

  const validate = () => {
    const newErrors = {};
    const requiredFields = {
      name_of_business: nameOfBusiness,
    };

    Object.keys(requiredFields).forEach((field) => {
      if (!requiredFields[field]) {
        newErrors[field] = "This field is required";
      }
    });

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  // Handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();
    if (!validate()) {
      toast.error("Required Fields can't be empty");
      return;
    }

    const formData = {
      name_of_business: nameOfBusiness,
      trainees_in_technical_colleges: {
        enrolled: {
          state: {
            male: enrolledStateMale,
            female: enrolledStateFemale,
          },
          federal: {
            male: enrolledFederalMale,
            female: enrolledFederalFemale,
          },
        },
        graduated: {
          state: {
            male: graduatedStateMale,
            female: graduatedStateFemale,
          },
          federal: {
            male: graduatedFederalMale,
            female: graduatedFederalFemale,
          },
        },
      },
      master_craft_person: {
        assessor: {
          male: assessorMale,
          female: assessorFemale,
        },
        verifiers: {
          male: verifierMale,
          female: verifierFemale,
        },
      },
    };
    mutation.mutate(formData);
    console.log("FormData:", formData);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        closeForm();
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [closeForm]);

  const modalVariants = {
    hidden: { y: "-100vh", opacity: 0 },
    visible: {
      y: "0",
      opacity: 1,
      transition: { delay: 0.2, type: "spring", stiffness: 120 },
    },
  };

  return (
    <div className="fixed left-0 top-0 w-full h-full bg-black bg-opacity-50 flex justify-center items-center z-20">
      <motion.div
        ref={modalRef}
        variants={modalVariants}
        initial="hidden"
        animate="visible"
        exit="hidden"
        className="scrollbar-hide overflow-auto w-[50%] h-[98vh] px-5 pt-8 pb-6 bg-white rounded-lg relative"
      >
        <div className="flex justify-between items-center font-bold">
          <h1 className="text-2xl">{title}</h1>
          <CloseIcon
            onClick={closeForm}
            sx={{ fontSize: "2rem" }}
            fontWeight={"30"}
            className="text-red-500 cursor-pointer absolute top-2 right-4"
          />
        </div>
        <hr className="mt-2 mb-4" />
        <form onSubmit={handleSubmit}>
          {errors.name_of_business && (
            <p className="text-red-500 text-xs">{errors.name_of_business}</p>
          )}
          <FormInput
            type="text"
            name="name_of_business"
            label="Business Name"
            value={nameOfBusiness}
            isRequired={true}
            onChange={(e) => {
              setNameOfBusiness(e.target.value);
              setErrors({ ...errors, [e.target.name]: "" });
            }}
          />

          <div className="mb-4">
            <h1 className="font-bold">
              <span className=" text-red-600">Enrolled Trainees </span> in
              programs in supported{" "}
              <span className=" text-red-600">Federal Technical Colleges</span>{" "}
              developed and delivered in partnership with the private sector
            </h1>
            <FormInput
              type="number"
              name="enrolled_federal_male"
              placeholder="Number of Male"
              value={enrolledFederalMale}
              onChange={(e) => setEnrolledFederalMale(e.target.value)}
            />
            <FormInput
              type="number"
              name="enrolled_federal_female"
              placeholder="Number of Female"
              value={enrolledFederalFemale}
              onChange={(e) => setEnrolledFederalFemale(e.target.value)}
            />
          </div>

          <div className="mb-4">
            <h1 className="font-bold">
              <span className=" text-red-600">Enrolled Trainees </span> in
              programs in supported{" "}
              <span className=" text-red-600">State Technical Colleges</span>{" "}
              developed and delivered in partnership with the private sector
            </h1>
            <FormInput
              type="number"
              name="enrolled_state_male"
              placeholder="Number of Male"
              value={enrolledStateMale}
              onChange={(e) => setEnrolledStateMale(e.target.value)}
            />
            <FormInput
              type="number"
              name="enrolled_state_female"
              placeholder="Number of Female"
              value={enrolledStateFemale}
              onChange={(e) => setEnrolledStateFemale(e.target.value)}
            />
          </div>

          <div className="mb-4">
            <h1 className="font-bold">
              <span className=" text-red-600">Graduated Trainees </span> in
              programs in supported{" "}
              <span className=" text-red-600">Federal Technical Colleges</span>{" "}
              developed and delivered in partnership with the private sector
            </h1>
            <FormInput
              type="number"
              name="graduated_federal_male"
              placeholder="Number of Male"
              value={graduatedFederalMale}
              onChange={(e) => setGraduatedFederalMale(e.target.value)}
            />
            <FormInput
              type="number"
              name="graduated_federal_female"
              placeholder="Number of Female"
              value={graduatedFederalFemale}
              onChange={(e) => setGraduatedFederalFemale(e.target.value)}
            />
          </div>

          <div className="mb-4">
            <h1 className="font-bold">
              <span className=" text-red-600">Graduated Trainees </span> in
              programs in supported{" "}
              <span className=" text-red-600">State Technical Colleges</span>{" "}
              developed and delivered in partnership with the private sector
            </h1>
            <FormInput
              type="number"
              name="graduated_state_male"
              placeholder="Number of Male"
              value={graduatedStateMale}
              onChange={(e) => setGraduatedStateMale(e.target.value)}
            />
            <FormInput
              type="number"
              name="graduated_state_female"
              placeholder="Number of Female"
              value={graduatedStateFemale}
              onChange={(e) => setGraduatedStateFemale(e.target.value)}
            />
          </div>

          <div className="mb-4">
            <h1 className="font-bold">
              <span className=" text-red-600">Master Crafts Persons</span>{" "}
              supported by the project certified as{" "}
              <span className=" text-red-600">Assessors</span>
            </h1>
            <FormInput
              type="number"
              name="assessor_male"
              placeholder="Number of Male"
              value={assessorMale}
              onChange={(e) => setAssessorMale(e.target.value)}
            />
            <FormInput
              type="number"
              name="assessor_female"
              placeholder="Number of Female"
              value={assessorFemale}
              onChange={(e) => setAssessorFemale(e.target.value)}
            />
          </div>

          <div className="mb-4">
            <h1 className="font-bold">
              <span className=" text-red-600">Master Crafts Persons</span>{" "}
              supported by the project certified as{" "}
              <span className=" text-red-600">Verifiers</span>
            </h1>
            <FormInput
              type="number"
              name="verifier_male"
              placeholder="Number of Male"
              value={verifierMale}
              onChange={(e) => setVerifierMale(e.target.value)}
            />
            <FormInput
              type="number"
              name="verifier_female"
              placeholder="Number of Female"
              value={verifierFemale}
              onChange={(e) => setVerifierFemale(e.target.value)}
            />
          </div>

          <div className="flex justify-center">
            <CustomButton className="bg-active_bg" isLoading={isLoading}>
              {isLoading ? "Submitting..." : "Submit"}
            </CustomButton>
          </div>
        </form>
      </motion.div>
    </div>
  );
};

export default Pdo2Component2Form;
