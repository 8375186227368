import { Link } from "react-router-dom";

const Unauthorized = () => {
  return (
    <div className="h-screen flex flex-col items-center justify-center">
      <h1 className="text-2xl text-red-500 font-bold">Unauthorized Access!</h1>
      <p>You do not have the necessary permissions to access this page.</p>
    </div>
  );
};

export default Unauthorized;
