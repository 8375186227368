import React, { useState } from "react";
import StudentsCard from "../card_components/Students";
import {
  ValidatorTechnicalCollegeDataDisplay,
  ValidatorTechnicalCollegeDataDisplayList,
} from "../card_components/TC";
import TeachersAnalyticsDisplay from "../card_components/Teachers";
import { useSelector } from "react-redux";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import ValidateSchool from "../../Validate/ValidateSchools";
import IntermediateCard from "../../Analytics_user3/Cards_component/IntermediateCard";
import PdoCard from "../../Analytics_user3/Cards_component/PdoCard";
import StudentsDonutAnalyticsDisplay from "../card_components/StudentsDonut";
import {
  selectCurrentJurisdiction,
  selectCurrentToken,
  selectCurrentUserRole,
  selectValidatorState,
} from "../../../../features/auth/authSlice";
import PageHeader from "../../../../Components/page_header";
import { useQuery } from "@tanstack/react-query";
import {
  fetchAllStudents,
  fetchAllTeachersByState,
  fetchAllTeachersPosts,
} from "../../../../utils/apiContext";
import { jwtDecode } from "jwt-decode";
import ShowLoader from "../../../../utils/showLoader";
import { toast } from "sonner";

const ValidatorDashboard = () => {
  // const userRoles = useSelector((state) => state.userRole.roles);

  const [isModalOpen, setModalOpen] = useState(false);

  const userRoles = useSelector(selectCurrentUserRole);
  const jurisdiction = useSelector(selectCurrentJurisdiction);
  const userTkn = useSelector(selectCurrentToken);
  const decodedTkn = jwtDecode(userTkn);
  const { tc_name } = decodedTkn;
  const validatorState = useSelector(selectValidatorState);

  const filterAndAggregateData = (data, validatorState) => {
    let maleCount = 0;
    let femaleCount = 0;
    let maleGraduates = 0;
    let femaleGraduates = 0;

    const filteredData = data.filter(
      (obj) => obj.school_state_location === validatorState
    );

    const tradeCounts = filteredData.reduce((acc, obj) => {
      if (!acc[obj.occupational_trade]) {
        acc[obj.occupational_trade] = { male: 0, female: 0 };
      }
      return acc;
    }, {});

    filteredData.forEach((obj) => {
      if (obj.sex === "male") {
        maleCount += 1;
        if (obj.grad_status === "yes") {
          maleGraduates += 1;
        }
        if (tradeCounts[obj.occupational_trade]) {
          tradeCounts[obj.occupational_trade].male += 1;
        }
      } else if (obj.sex === "female") {
        femaleCount += 1;
        if (obj.grad_status === "yes") {
          femaleGraduates += 1;
        }
        if (tradeCounts[obj.occupational_trade]) {
          tradeCounts[obj.occupational_trade].female += 1;
        }
      }
    });

    return {
      maleCount,
      femaleCount,
      maleGraduates,
      femaleGraduates,
      totalStudents: data.length,
      tradeCounts,
    };
  };

  const {
    data: studentsData,
    isLoading,
    error,
  } = useQuery({
    queryKey: ["studentData"],
    queryFn: () => fetchAllStudents(jurisdiction),
  });

  
  
  const {
    data: teachersDataByState,
    isLoading: teachersIsLoadingByState,
    error: teachersErrorByState,
  } = useQuery({
    queryKey: ["teachersDataByState"],
    queryFn: () => fetchAllTeachersByState(jurisdiction, validatorState),
  });

  const aggregatedData = studentsData
    ? filterAndAggregateData(studentsData, validatorState)
    : {
        maleCount: 0,
        femaleCount: 0,
        maleGraduates: 0,
        femaleGraduates: 0,
        totalStudents: 0,
        tradeCounts: {},
      };

  const totalStudentsInState = studentsData?.filter(
    (students) => students.school_state_location === validatorState
  );

  

  const handleOpenModal = () => {
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };
  if (teachersErrorByState || error) {
    toast.error(error || teachersErrorByState);
  }
  return (
    <div>
      <PageHeader title="Analytics" />

      <div className="flex gap-3 mt-3 px-3">
        {isLoading ? (
          <ShowLoader />
        ) : (
          <div className="w-[350px] h-auto">
            <div className="custom-shadow bg-white px-6 py-6 rounded-lg">
              <h1
                className="text-[20px] font-bold text-primary_text"
                style={{ fontFamily: "Poppins, sans-serif" }}
              >
                Number of Students in TVET
              </h1>
              <h1
                className="text-active_bg text-3xl font-bold mt-7"
                style={{ fontFamily: "Poppins, sans-serif" }}
              >
                {totalStudentsInState?.length}
              </h1>
            </div>
            <br />
            <div className="custom-shadow bg-white px-4 py-4 rounded-lg ">
              <StudentsCard aggregatedData={aggregatedData} />
            </div>
          </div>
        )}

        <div className="w-[807px] flex flex-col gap-5">
          {isLoading ? (
            <ShowLoader />
          ) : (
            <div className="custom-shadow h-auto bg-white rounded-lg">
              <ValidatorTechnicalCollegeDataDisplayList
                onOpenModal={handleOpenModal}
              />
            </div>
          )}
          {isLoading ? (
            <ShowLoader />
          ) : (
            <div className="custom-shadow h-auto bg-white rounded-lg">
              <StudentsDonutAnalyticsDisplay aggregatedData={aggregatedData} />
            </div>
          )}
          {teachersIsLoadingByState ? (
            <ShowLoader />
          ) : (
            <div className="custom-shadow h-auto bg-white rounded-lg">
              <TeachersAnalyticsDisplay Teachersdata={teachersDataByState} />
            </div>
          )}
        </div>
      </div>
      <div>
        <br />
        <div className="custom-shadow h-auto bg-white rounded-lg px-16 py-6 mx-3">
          <IntermediateCard />
        </div>
        <br />
        <div className="custom-shadow h-auto bg-white rounded-lg px-16 py-6 mx-3">
          <PdoCard />
        </div>
      </div>
      <Modal
        open={isModalOpen}
        onClose={handleCloseModal}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box
          className="modal-box"
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 800,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            borderRadius: "8px",
            overflow: "auto",
          }}
        >
          <ValidateSchool />
        </Box>
      </Modal>
    </div>
  );
};

export default ValidatorDashboard;
