import { useEffect, useRef, useState } from "react";
import { motion } from "framer-motion";
import CloseIcon from "@mui/icons-material/Close";
import { FormDropdown, FormInput, FormMediaUpload, FormRadioInput } from "../../../form";
import CustomButton from "../../../button";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import axiosInstance from "../../../../Services/axiosInstance";
import { useDispatch, useSelector } from "react-redux";
import { jwtDecode } from "jwt-decode";
import { toast } from "sonner";
import {
  selectCurrentJurisdiction,
  selectCurrentUser,
} from "../../../../features/auth/authSlice";
import { setPdo2Post } from "../../../../features/auth/isPostMade";
import { validatorStates } from "../states";

const PDO2 = ({ title, closeForm }) => {
  const modalRef = useRef(null);
  const authToken = useSelector((state) => state.auth.token);
  const decodedTkn = jwtDecode(authToken);

  const jurisdiction = useSelector(selectCurrentJurisdiction);
  const email = useSelector(selectCurrentUser);
  const { tc_name } = decodedTkn;
  const dispatch = useDispatch();

  // console.log(jurisdiction, tc_name, email);
  const [state, setState] = useState("");
  const [
    tvetSensitizationConductedInHostCommunityBySchool,
    setTvetSensitizationConductedInHostCommunityBySchool,
  ] = useState("");
  const [
    noOfTvetSensitizationsConductedBySchool,
    setNoOfTvetSensitizationsConductedBySchool,
  ] = useState();

  const [noOfToiletFacilitiesInSchool, setNoOfToiletFacilitiesInSchool] =
    useState();
  const [
    noOfToiletFacilitiesRenovatedByIdeasProject,
    setNoOfToiletFacilitiesRenovatedByIdeasProject,
  ] = useState();
  const [
    noOfToiletFacilitiesProvidedWithWashFacilitiesByIdeasProject,
    setNoOfToiletFacilitiesProvidedWithWashFacilitiesByIdeasProject,
  ] = useState();
  const [
    noOfFemaleStudentsEnrolledInPriorityTrade,
    setNoOfFemaleStudentsEnrolledInPriorityTrade,
  ] = useState();
  const [
    totalNoOfStudentsEnrolledInPriorityTrades,
    setTotalNoOfStudentsEnrolledInPriorityTrades,
  ] = useState();
  const [
    noOfTvetSensitizationsConductedBySchoolTcReport,
    setNoOfTvetSensitizationsConductedBySchoolTcReport,
  ] = useState(null);
  const [studentEnrollmentDataDoc, setStudentEnrollmentDataDoc] =
    useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState({});

  const mutation = useMutation({
    mutationFn: async (formData) => {
      setIsLoading(true);
      const response = await axiosInstance.post(
        `/pdo/c1/make-post-female-enrollment-rate-in-project-supportedTc/?jurisdiction=${jurisdiction}`,
        formData
      );
      console.log(response.data);
      return response;
    },
    onSuccess: (response) => {
      toast.success("Successful");
      setIsLoading(false);
      const isCheck = response.data.matchedEntry;

      const isPostMade = isCheck.tc_name === tc_name;
      if (isPostMade) {
        dispatch(setPdo2Post(true));
      } else {
        dispatch(setPdo2Post(false));
      }

      closeForm();
    },
    onError: (err) => {
      if (err.response.status === 400) {
        toast.error("Post already exists");
      } else {
        toast.error("An Error Occurred");
      }
      setIsLoading(false);
      closeForm();
    },
  });

  const validate = () => {
    const newErrors = {};
    const requiredFields = {};

    if (jurisdiction === "state") {
      requiredFields.state = state;
    }

    Object.keys(requiredFields).forEach((field) => {
      if (!requiredFields[field]) {
        newErrors[field] = "This field is required";
      }
    });

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const handleRadioChange = (e) => {
    setTvetSensitizationConductedInHostCommunityBySchool(e.target.value);
  };

  const handleFileChange = (e, setFile) => {
    setFile(e.target.files[0]); // Store a single file for each FormMediaUpload
  };

  const handleInputChange = (e, setValue) => {
    setValue(e.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (!validate()) {
      toast.error("Required Fields can't be empty");
      return;
    }

    // Check if all fields are empty
    if (
      !state &&
      !tvetSensitizationConductedInHostCommunityBySchool &&
      !noOfTvetSensitizationsConductedBySchool &&
      !noOfToiletFacilitiesInSchool &&
      !noOfToiletFacilitiesRenovatedByIdeasProject &&
      !noOfToiletFacilitiesProvidedWithWashFacilitiesByIdeasProject &&
      !noOfFemaleStudentsEnrolledInPriorityTrade &&
      !totalNoOfStudentsEnrolledInPriorityTrades &&
      !studentEnrollmentDataDoc &&
      !noOfTvetSensitizationsConductedBySchoolTcReport
    ) {
      toast.error("Cannot submit an empty form");
      return;
    }

    const formData = new FormData();
    formData.append("state", state);
    formData.append("tc_name", tc_name);
    formData.append("jurisdiction", jurisdiction);
    formData.append("email", email);
    formData.append(
      "tvet_sensitization_consucted_in_host_community_by_school",
      tvetSensitizationConductedInHostCommunityBySchool
    );
    formData.append(
      "no_of_tvet_sensitizations_conducted_by_school",
      noOfTvetSensitizationsConductedBySchool
    );
    formData.append(
      "no_of_toilet_facilities_in_school",
      noOfToiletFacilitiesInSchool
    );
    formData.append(
      "no_of_toilet_facilities_renovated_by_ideas_project",
      noOfToiletFacilitiesRenovatedByIdeasProject
    );
    formData.append(
      "no_of_toilet_facilities_provided_with_wash_facilities_by_ideas_project",
      noOfToiletFacilitiesProvidedWithWashFacilitiesByIdeasProject
    );
    formData.append(
      "no_of_female_students_enrolled_in_priority_trade",
      noOfFemaleStudentsEnrolledInPriorityTrade
    );
    formData.append(
      "total_no_of_students_enrolled_in_priority_trades",
      totalNoOfStudentsEnrolledInPriorityTrades
    );
    if (studentEnrollmentDataDoc)
      formData.append("tc_report_pdf", studentEnrollmentDataDoc);
    if (noOfTvetSensitizationsConductedBySchoolTcReport)
      formData.append("student_enrollment_data_doc_pdf", noOfTvetSensitizationsConductedBySchoolTcReport);

    mutation.mutate(formData);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        closeForm(); // Assuming closeForm sets the state that controls the modal visibility
      }
    };
    document.addEventListener("mousedown", handleClickOutside);

    // Remove event listener on cleanup
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [closeForm]);

  const modalVariants = {
    hidden: {
      y: "-100vh",
      opacity: 0,
    },
    visible: {
      y: "0",
      opacity: 1,
      transition: { delay: 0.2, type: "spring", stiffness: 120 },
    },
  };
  return (
    <div className="fixed left-0 top-0 w-full h-full bg-black bg-opacity-50 flex justify-center items-center z-20">
      <motion.div
        ref={modalRef}
        variants={modalVariants}
        initial="hidden"
        animate="visible"
        exit="hidden"
        className="scrollbar-hide overflow-auto w-[50%] h-[98vh] px-5 pt-8 pb-6 bg-white rounded-lg relative"
      >
        <div className="flex justify-between items-center font-bold">
          <h1 className="text-2xl">{title}</h1>

          <CloseIcon
            onClick={closeForm}
            sx={{ fontSize: "2rem" }}
            fontWeight={"30"}
            className="text-red-500 cursor-pointer absolute top-2 right-4"
          />
        </div>

        <hr className="mt-2 mb-4" />
        <form onSubmit={handleSubmit}>
        {jurisdiction === "state" && (
            <FormDropdown
              name="state"
              label="Enter State Location"
              value={state}
              options={validatorStates}
              onChange={(e) => handleInputChange(e, setState)}
              errorMessage={errors.state}
            />
          )}
          {errors.state && (
            <p className="text-red-500 text-xs mt-1">{errors.state}</p>
          )}
          <FormRadioInput
            type="radio"
            name="tvet_sensitization_consucted_in_host_community_by_school"
            label="TVET sensitization conducted in the host community by the school"
            checkedValue={tvetSensitizationConductedInHostCommunityBySchool}
            onChange={handleRadioChange}
          />
          <FormInput
            type="number"
            name="no_of_tvet_sensitizations_conducted_by_school_tc"
            label="Number of TVET sensitizations conducted by the school"
            Value={noOfTvetSensitizationsConductedBySchool}
            onChange={(e) =>
              handleInputChange(e, setNoOfTvetSensitizationsConductedBySchool)
            }
          />
          <FormMediaUpload
            type="file"
            id="tc_report_pdf"
            name="tc_report_pdf"
            label="Upload TC report showing the Sensitization Activity including pictures all in one PDF"
            onChange={(e) =>
              handleFileChange(
                e,
                setNoOfTvetSensitizationsConductedBySchoolTcReport
              )
            }
            fileName={noOfTvetSensitizationsConductedBySchoolTcReport?.name}
          />
          <FormInput
            type="number"
            name="no_of_toilet_facilities_in_school"
            label="Number of toilet facilities in the school"
            Value={noOfToiletFacilitiesInSchool}
            onChange={(e) =>
              handleInputChange(e, setNoOfToiletFacilitiesInSchool)
            }
          />
          <FormInput
            type="number"
            name="no_of_toilet_facilities_renovated_by_ideas_project"
            label="Number of toilet facilities renovated by IDEAS project"
            Value={noOfToiletFacilitiesRenovatedByIdeasProject}
            onChange={(e) =>
              handleInputChange(
                e,
                setNoOfToiletFacilitiesRenovatedByIdeasProject
              )
            }
          />
          <FormInput
            type="number"
            name="no_of_toilet_facilities_provided_with_wash_facilities_by_ideas_project"
            label="Number of toilet facilities provided with WASH facilities by IDEAS project"
            Value={noOfToiletFacilitiesProvidedWithWashFacilitiesByIdeasProject}
            onChange={(e) =>
              handleInputChange(
                e,
                setNoOfToiletFacilitiesProvidedWithWashFacilitiesByIdeasProject
              )
            }
          />
          <FormInput
            type="number"
            name="no_of_female_students_enrolled_in_priority_trade"
            label="Number of female students enrolled in Priority Trades"
            Value={noOfFemaleStudentsEnrolledInPriorityTrade}
            onChange={(e) =>
              handleInputChange(e, setNoOfFemaleStudentsEnrolledInPriorityTrade)
            }
          />
          <FormInput
            type="number"
            name="total_no_of_students_enrolled_in_priority_trades"
            label="Total number of students (both male and   female) enrolled in Priority Trades"
            Value={totalNoOfStudentsEnrolledInPriorityTrades}
            onChange={(e) =>
              handleInputChange(e, setTotalNoOfStudentsEnrolledInPriorityTrades)
            }
          />
          <FormMediaUpload
            type="file"
            id="student_enrollment_data_doc_pdf"
            name="student_enrollment_data_doc_pdf"
            label="Fill and upload the template on Student Enrollment data in one pdf"
            onChange={(e) => handleFileChange(e, setStudentEnrollmentDataDoc)}
            fileName={studentEnrollmentDataDoc?.name}
          />

          <div className="flex justify-center">
            <CustomButton className="bg-active_bg" isLoading={isLoading}>
              {isLoading ? "Submiting..." : "Submit"}
            </CustomButton>
          </div>
        </form>
      </motion.div>
    </div>
  );
};

export default PDO2;
