export function toSentenceCase(sentence) {
  if (!sentence || typeof sentence !== "string") {
   return "";
  }

  // List of minor words that should not be capitalized unless they are the first word
  const minorWords = [
    "a",
    "an",
    "and",
    "as",
    "at",
    "but",
    "by",
    "for",
    "if",
    "in",
    "nor",
    "of",
    "on",
    "or",
    "so",
    "the",
    "to",
    "up",
    "yet",
  ];

  // Trim the sentence to remove any leading or trailing whitespace
  const trimmedSentence = sentence.trim();

  // Split the sentence into words
  const words = trimmedSentence.split(" ");

  // Capitalize the first letter of each word except for minor words (unless it's the first word)
  const titleCasedWords = words.map((word, index) => {
    if (index === 0 || !minorWords.includes(word.toLowerCase())) {
      return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    }
    return word.toLowerCase();
  });

  // Join the words back into a sentence
  const titleCasedSentence = titleCasedWords.join(" ");

  return titleCasedSentence;
}
