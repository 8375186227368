import { BarChart } from "@mui/x-charts/BarChart";

export const FirstBarChartDisplayIoi = ({ data }) => {
  const count = Array.isArray(data)
    ? data.map((item) => Number(item.count) || 0).reduce((a, b) => a + b, 0)
    : 0;

  return (
    <BarChart
      series={[
        {
          data: [740],
          color: "#CFF0B5",
          label: "Baseline",
          barWidth: 0.2,
        },
        {
          data: [count],
          color: "#BC5645",
          label: "Actual",
          barWidth: 0.2,
        },
        {
          data: [430],
          color: "#C0AA54",
          label: "Target",
          barWidth: 0.2,
        },
      ]}
      height={300}
      width={350}
      xAxis={[{ data: [""], scaleType: "band" }]}
      margin={{ top: 70, bottom: 30, left: 30, right: 10 }}
      barLabel="value"
      borderRadius={8}
    />
  );
};

export const SecondBarChartDisplayIoi = ({ data }) => {
  const count = Array.isArray(data)
    ? data.map((item) => Number(item.count) || 0).reduce((a, b) => a + b, 0)
    : 0;

  return (
    <BarChart
      series={[
        {
          data: [650],
          color: "#CFF0B5",
          label: "Baseline",
        },
        {
          data: [count],
          color: "#BC5645",
          label: "Actual",
        },
        {
          data: [620],
          color: "#C0AA54",
          label: "Target",
        },
      ]}
      height={300}
      width={350}
      xAxis={[{ data: [""], scaleType: "band" }]}
      margin={{ top: 70, bottom: 30, left: 30, right: 10 }}
      barLabel="value"
      borderRadius={8}
    />
  );
};

export const ThirdBarChartDisplayIoi = ({ data }) => {
  const count = Array.isArray(data)
    ? data.map((item) => Number(item.count) || 0).reduce((a, b) => a + b, 0)
    : 0;
  // console.log("ioi3Count", count);
  return (
    <BarChart
      series={[
        {
          data: [600],
          color: "#CFF0B5",
          label: "Baseline",
        },
        {
          data: [count],
          color: "#BC5645",
          label: "Actual",
        },
        {
          data: [700],
          color: "#C0AA54",
          label: "Target",
        },
      ]}
      height={300}
      width={350}
      xAxis={[{ data: [""], scaleType: "band" }]}
      margin={{ top: 70, bottom: 30, left: 30, right: 10 }}
      barLabel="value"
      borderRadius={8}
    />
  );
};

export const FourthBarChartDisplayIoi = ({ data }) => {
  const count = Array.isArray(data)
    ? data.map((item) => Number(item.count) || 0).reduce((a, b) => a + b, 0)
    : 0;

  return (
    <BarChart
      series={[
        {
          data: [680],
          color: "#CFF0B5",
          label: "Baseline",
        },
        {
          data: [count],
          color: "#BC5645",
          label: "Actual",
        },
        {
          data: [670],
          color: "#C0AA54",
          label: "Target",
        },
      ]}
      height={300}
      width={350}
      xAxis={[{ data: [""], scaleType: "band" }]}
      margin={{ top: 70, bottom: 30, left: 30, right: 10 }}
      barLabel="value"
      borderRadius={8}
    />
  );
};
