import { createSlice } from "@reduxjs/toolkit";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

const postMadeSlice = createSlice({
  name: "postsmade",
  initialState: {
    tcPost: false,
    studentsPost: false,
    teachersPost: false,
    cciPost: false,
    projectSummaryPost: false,
    genActivitiesPost: false,
    ioi1Post: false,
    ioi2Post: false,
    ioi3Post: false,
    ioi4Post: false,
    pdo2Post: false,
    pdo3Post: false,
  },
  reducers: {
    setTcPost: (state, action) => {
      state.tcPost = action.payload;
    },
    setStudentsPost: (state, action) => {
      state.studentsPost = action.payload;
    },
    setTeachersPost: (state, action) => {
      state.teachersPost = action.payload;
    },
    setCciPost: (state, action) => {
      state.cciPost = action.payload;
    },
    setProjectSummaryPost: (state, action) => {
      state.projectSummaryPost = action.payload;
    },
    setGenActivitiesPost: (state, action) => {
      state.genActivitiesPost = action.payload;
    },
    setIoi1Post: (state, action) => {
      state.ioi1Post = action.payload;
    },
    setIoi2Post: (state, action) => {
      state.ioi2Post = action.payload;
    },
    setIoi3Post: (state, action) => {
      state.ioi3Post = action.payload;
    },
    setIoi4Post: (state, action) => {
      state.ioi4Post = action.payload;
    },
    setPdo2Post: (state, action) => {
      state.pdo2Post = action.payload;
    },
    setPdo3Post: (state, action) => {
      state.pdo3Post = action.payload;
    },

    clearPosts: (state) => {
      state.tcPost = false;
      state.studentsPost = false;
      state.teachersPost = false;
      state.cciPost = false;
      state.projectSummaryPost = false;
      state.genActivitiesPost = false;
      state.ioi1Post = false;
      state.ioi2Post = false;
      state.ioi3Post = false;
      state.ioi4Post = false;
      state.pdo2Post = false;
      state.pdo3Post = false;
    },
  },
});

export const {
  setTcPost,
  setStudentsPost,
  setTeachersPost,
  setCciPost,
  setProjectSummaryPost,
  setGenActivitiesPost,
  setIoi1Post,
  setIoi2Post,
  setIoi3Post,
  setIoi4Post,
  setPdo2Post,
  setPdo3Post,

  clearPosts,
} = postMadeSlice.actions;

export const selectTcPost = (state) => state.postsmade.tcPost;
export const selectStudentsPost = (state) => state.postsmade.studentsPost;
export const selectTeachersPost = (state) => state.postsmade.teachersPost;
export const selectCciPost = (state) => state.postsmade.cciPost;
export const selectProjectSummaryPost = (state) =>
  state.postsmade.projectSummaryPost;
export const selectGenActivitiesPost = (state) =>
  state.postsmade.genActivitiesPost;
export const selectIoi1Post = (state) => state.postsmade.ioi1Post;
export const selectIoi2Post = (state) => state.postsmade.ioi2Post;
export const selectIoi3Post = (state) => state.postsmade.ioi3Post;
export const selectIoi4Post = (state) => state.postsmade.ioi4Post;
export const selectPdo2Post = (state) => state.postsmade.pdo2Post;
export const selectPdo3Post = (state) => state.postsmade.pdo3Post;

const persistConfig = {
  key: "postsmade",
  storage,
};

const persistedPostMadeReducer = persistReducer(
  persistConfig,
  postMadeSlice.reducer
);

export default persistedPostMadeReducer;
