import React, { useEffect, useRef, useState } from "react";
import IconButton from "@mui/material/IconButton";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

const MoreOptionsMenu = ({ rowId, onEdit, onDelete, hideDelete }) => {
  const [isOpen, setIsOpen] = useState(false);
  const menuRef = useRef(null);

  const handleClick = (event, id) => {
    // event.stopPropagation();
    setIsOpen(!isOpen);
  };

  const handleClose = (event) => {
    event.stopPropagation();
    setIsOpen(false);
  };

  const handleEdit = (event) => {
    // event.stopPropagation();
    onEdit(rowId);
    setIsOpen(false);
  };

  const handleDelete = (event) => {
    // event.stopPropagation();
    onDelete(rowId);
    setIsOpen(false);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="relative" ref={menuRef}>
      <button
        aria-label="more"
        aria-haspopup="true"
        onClick={(event) => handleClick(event, rowId)}
        className="p-2 font-bold"
      >
        ⋮
      </button>
      {isOpen && (
        <div
          className="absolute -top-6 left-1 w-auto bg-white border border-gray-200 rounded shadow-lg z-50"
          onClick={(e) => e.stopPropagation()}
          style={{ zIndex: 1000 }}
        >
          <button
            onClick={handleEdit}
            className="block px-4 py-2 hover:bg-gray-200 w-full text-left"
          >
            Edit
          </button>
          {!hideDelete && (
            <button
              onClick={handleDelete}
              className="block px-4 py-2  hover:bg-gray-200 w-full text-left"
            >
              Delete
            </button>
          )}
        </div>
      )}
    </div>
  );
};

export default MoreOptionsMenu;
