import axios from "axios";

const axiosFetchProfile = axios.create({
  baseURL: "https://c2v386fr-8000.euw.devtunnels.ms/api/v1", // Base URL for your API
});




export default axiosFetchProfile;
