import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import axiosInstance from "../../../../../Services/axiosInstance";
import { useDispatch, useSelector } from "react-redux";
import {
  selectCurrentJurisdiction,
  selectCurrentToken,
  selectCurrentUserRole,
} from "../../../../../features/auth/authSlice";
import { getTcName } from "../../../../../utils/getFormattedToken";
import { useQueries, useQuery, useQueryClient } from "@tanstack/react-query";
import ShowLoader from "../../../../../utils/showLoader";
import ShowError from "../../../../../utils/showError";
import { useState } from "react";
import RejectDataEntry from "../../../../../Components/Modal_Forms/RejectMessage/Reject";
import ViewPdf from "../../../../../Components/PdfModalView/ViewPdf";
import { Button } from "@mui/material";
import { IndicatorEditModalFormIoi2 } from "../../../../../Components/Modal_Forms/IndicatorEditModal";
import { useEffect } from "react";
import {
  fetchAllIOI2Posts,
  fetchIOI2PostById,
} from "../../../../../utils/apiContext";

import { toast } from "sonner";

const fields = [
  {
    id: "internship_arrangements",
    label: "Internship Arrangements",
    type: "radio",
    options: [
      { value: "yes", label: "Yes" },
      { value: "no", label: "No" },
    ],
  },
  {
    id: "no_of_industry_partners",
    label: "Number of Industry Partners",
    type: "number",
  },
  {
    id: "no_of_internship_arrangements",
    label: "Number of Internship Arrangements",
    type: "number",
  },
  {
    id: "development_of_short_term_skills_upgrading_courses",
    label: "Development of Short Term Skills Upgrading Courses",
    type: "radio",
    options: [
      { value: "yes", label: "Yes" },
      { value: "no", label: "No" },
    ],
  },
  {
    id: "no_of_newly_developed_short_term_skills_upgrading_courses_on_partnership_with_industry",
    label:
      "Number of Newly Developed Short Term Skills Upgrading Courses on Partnership with Industry",
    type: "number",
  },
  {
    id: "latest_tc_status_report_pdf",
    label: "Upload the Latest TC Status Report (file max size 5MB)",
    type: "file",
  },
  {
    id: "attendance_sheet_pdf",
    label: "Upload the Attendance Sheet (file max size 5MB)",
    type: "file",
  },
];

const Ioi2Display = ({ questions, propsTc_name, propsJurisdiction }) => {
  const [openRejectModal1, setOpenRejectModal1] = useState(false);
  const [rejectionReason1, setRejectionReason1] = useState("");
  const [openPdfModal, setOpenPdfModal] = useState(false);
  const [editValues, setEditValues] = useState({});
  const [modalOpen, setModalOpen] = useState(false);
  const jurisdiction = useSelector(selectCurrentJurisdiction);
  const userTkn = useSelector(selectCurrentToken);

  const tc_name = getTcName(userTkn);
  const userRoles = useSelector(selectCurrentUserRole);
  const isUser1 = userRoles === "M_E_OFFICE";
  const queryClient = useQueryClient();
  const combJurisdiction =
    userRoles !== "National_Admin" ? jurisdiction : propsJurisdiction;

  const handleReject1 = () => {
    setOpenRejectModal1(true);
  };

  const handleCloseRejectModal1 = () => {
    setOpenRejectModal1(false);
    setRejectionReason1("");
  };

  const handleRejectReasonChange1 = (event) => {
    setRejectionReason1(event.target.value);
  };

  const handleSubmit1 = () => {
    // Handle submit action here
    console.log("Rejection Reason:", rejectionReason1);
    setOpenRejectModal1(false);
    setRejectionReason1("");
  };

  const closeForm = () => {
    setOpenPdfModal(false);
  };

  const {
    data: allPosts,
    isLoading,
    error,
  } = useQuery({
    queryKey: combJurisdiction === "state" ? ["ioi2"] : ["ioi2b"],
    queryFn: () => fetchAllIOI2Posts(combJurisdiction),
  });

  const postQueries = useQueries({
    queries:
      allPosts?.map((post) => ({
        queryKey:
          combJurisdiction === "state"
            ? ["ioi2", post._id]
            : ["ioi2b", post._id],

        queryFn: () => fetchIOI2PostById(post?._id, combJurisdiction),
      })) || [],
  });

  const openEditModal = (data) => {
    setEditValues({
      internship_arrangements: data?.internship_arrangements || "",
      no_of_industry_partners: data?.no_of_industry_partners || "",
      no_of_internship_arrangements: data?.no_of_internship_arrangements || "",
      development_of_short_term_skills_upgrading_courses:
        data?.development_of_short_term_skills_upgrading_courses || "",
      no_of_newly_developed_short_term_skills_upgrading_courses_on_partnership_with_industry:
        data?.no_of_newly_developed_short_term_skills_upgrading_courses_on_partnership_with_industry ||
        "",
      latest_tc_status_report_pdf: data?.latest_tc_status_report_pdf || "",
      attendance_sheet_pdf: data?.attendance_sheet_pdf || "",
      _id: data?._id || "",
    });
    setModalOpen(true);
  };

  const saveEdits = async (formData) => {
    try {
      await axiosInstance.put(
        `/ioi/c1/update-post-no-of-training-programs-delivered-monitored/${formData.get(
          "_id"
        )}/?jurisdiction=${jurisdiction}`,
        formData
      );
      queryClient.invalidateQueries(["ioipost2"]);
      toast.success("Updated Successfully")
      setModalOpen(false);
    } catch (error) {
      console.error("Error saving edits:", error);
    }
  };

  const postsWithDetails = postQueries.map((query) => query.data);

  const filteredPosts = postsWithDetails.filter((post) => {
    if (isUser1) {
      // console.log("fetching using user1", post?.tc_name, propsTc_name);
      return post?.tc_name === tc_name;
    } else {
      // console.log("fetching user reviewer", post?.tc_name, propsTc_name);
      return post?.tc_name === propsTc_name;
    }
  });
  if (isLoading) {
    return <ShowLoader />;
  }
  if (error?.response?.status === 400) {
    return <ShowError />;
  }
  return questions.map((list) => {
    const responseValue = list.responseKey
      .split(".")
      .reduce((o, i) => o?.[i], filteredPosts[0]);
    return (
      <div
        key={list.id}
        className="border-b-2 border-b-indicator_border ml-6 text-sm py-2"
      >
        <div className="w-[80%] flex justify-between items-center">
          <div>{list.question}</div>
          <div className="text-active_bg font-bold">
            {list.responseKey.includes("pdf") ? (
              <PictureAsPdfIcon />
            ) : (
              responseValue
            )}
          </div>
        </div>
        {userRoles === "Reviewer" && (
          <div className="text-active_bg rounded-md text-sm absolute bottom-0 right-6 flex gap-3">
            <Button
              onClick={handleReject1}
              variant="outlined"
              color="error"
              sx={{
                bgcolor: "#F44336",
                color: "white",
                textTransform: "capitalize",
                "&:hover": { bgcolor: "#F44336" },
              }}
            >
              Reject
            </Button>
            <Button
              variant="contained"
              color="success"
              sx={{
                bgcolor: "#0E4F2F",
                color: "#FFFFFF",
                textTransform: "capitalize",
              }}
            >
              Approve
            </Button>
            <Button
              onClick={() => setOpenPdfModal(true)}
              variant="outline"
              sx={{ textTransform: "capitalize" }}
            >
              View Documents
            </Button>
          </div>
        )}{" "}
        {userRoles === "Validator" && (
          <div className="text-active_bg rounded-md text-sm absolute bottom-6 right-6 flex gap-3">
            <Button
              onClick={handleReject1}
              variant="outlined"
              color="error"
              sx={{
                bgcolor: "#F44336",
                color: "white",
                textTransform: "capitalize",
                "&:hover": { bgcolor: "#F44336" },
              }}
            >
              Reject
            </Button>
            <Button
              variant="contained"
              color="success"
              sx={{
                bgcolor: "#0E4F2F",
                color: "#FFFFFF",
                textTransform: "capitalize",
              }}
            >
              Validate
            </Button>
            <Button
              onClick={() => setOpenPdfModal(true)}
              variant="outline"
              sx={{ textTransform: "capitalize" }}
            >
              View Documents
            </Button>
          </div>
        )}{" "}
        {userRoles === "M_E_OFFICE" && (
          <div className="flex gap-3 absolute bottom-6 right-6">
            <Button
              variant="contained"
              sx={{
                bgcolor: "#0E4F2F",
                color: "#FFFFFF",
                textTransform: "capitalize",
                "&:hover": { bgcolor: "#0E4F2F" },
              }}
              onClick={() => openEditModal(filteredPosts[0])}
            >
              Edit
            </Button>
            <Button
              onClick={() => setOpenPdfModal(true)}
              variant="outline"
              sx={{
                textTransform: "capitalize",
                "&:hover": { bgcolor: "#0E4F2F", color: "#ffffff" },
              }}
            >
              View Documents
            </Button>
          </div>
        )}
        <RejectDataEntry
          openRejectModal1={openRejectModal1}
          handleCloseRejectModal1={handleCloseRejectModal1}
          rejectionReason1={rejectionReason1}
          handleRejectReasonChange1={handleRejectReasonChange1}
          handleSubmit1={handleSubmit1}
        />
        {openPdfModal && <ViewPdf closeForm={closeForm} />}
        <IndicatorEditModalFormIoi2
          open={modalOpen}
          handleClose={() => setModalOpen(false)}
          rowData={editValues}
          handleSave={saveEdits}
          fields={fields}
          header="Training Program Partnership"
        />
      </div>
    );
  });
};

export default Ioi2Display;
