import React, { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper/modules";
import { BarChart } from "@mui/x-charts/BarChart";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { Box, Button } from "@mui/material";
import { motion } from "framer-motion";

import { BarChartDisplayComponent4 } from "../../Analytics_Dashboard/chart_components/BarChartDisplays";
import { axisClasses } from "@mui/x-charts/ChartsAxis";

const PdoCardComponent4 = ({
  maleCountPreService,
  femaleCountPreService,
  maleCountInService,
  femaleCountInService,
  //   IgfData,
}) => {
  const [activeSection, setActiveSection] = useState("TTIs");
  const chartSetting = {
    yAxis: [
      {
        label: "Count",
      },
    ],
    width: 1020,
    height: 450,
    sx: {
      [`.${axisClasses.left} .${axisClasses.label}`]: {
        transform: "translate(-5px, 0)",
        fontWeight: "bold",
      },
      [`.${axisClasses.bottom} .${axisClasses.label}`]: {
        fontWeight: "bold",
      },
    },
  };

  const customColors = ["#CFF0B5", "#843C30"];
  //   const customColors = ["#0E4F2F", "#45BC5F", "#843C30", "#C0AA54", "#CFF0B5"];

  const aggregatedData = React.useMemo(() => {
    const data = {
      NPCUs: { male: maleCountPreService, female: femaleCountPreService },
      PMUs: { male: maleCountInService, female: femaleCountInService },
      Abia_State: { male: maleCountInService, female: femaleCountInService },
      Benue_State: { male: maleCountInService, female: femaleCountInService },
      Edo_State: { male: maleCountInService, female: femaleCountInService },
      Ekiti_State: { male: maleCountInService, female: femaleCountInService },
      Kano_State: { male: maleCountInService, female: femaleCountInService },
      Gombe_State: { male: maleCountInService, female: femaleCountInService },
    };

    const IgfData = [
      {
       NPCU: {
          male: 10,
          female: 15,
        },
        PMU: {
          male: 10,
          female: 15,
        },
        Abia_State: {
          male: 10,
          female: 15,
        },
        Benue_State: {
          male: 20,
          female: 25,
        },
        Edo_State: {
          male: 10,
          female: 20,
        },
        Ekiti_State: {
          male: 5,
          female: 10,
        },
        Kano_State: {
          male: 15,
          female: 20,
        },
        Gombe_State: {
          male: 10,
          female: 15,
        },
      },
    ];

    IgfData.forEach((post) => {
      for (const key in data) {
        if (post[key]) {
          data[key].male += post[key].male;
          data[key].female += post[key].female;
        }
      }
    });

    return data;
  }, [
    maleCountPreService,
    femaleCountPreService,
    maleCountInService,
    femaleCountInService,
  ]);

  // const dataset = React.useMemo(() => {
  //   return Object.keys(aggregatedData).map((key) => ({
  //     issue: key,
  //     male: aggregatedData[key].male,
  //     female: aggregatedData[key].female,
  //   }));
  // }, [aggregatedData]);
  const dataset = [
    { group: "NPCU", male: 10, female: 15 },
    { group: "PMU", male: 10, female: 15 },
    { group: "Abia_State", male: 10, female: 15 },
    { group: "Benue_State", male: 20, female: 25 },
    { group: "Edo_State", male: 10, female: 20 },
    { group: "Ekiti_State", male: 5, female: 10 },
    { group: "Kano_State", male: 15, female: 20 },
    { group: "Gombe_State", male: 10, female: 15 },
  ];
  // console.log("dataSet:", dataset);
  return (
    <>
      <div className="flex justify-center mb-4">
        <Button
          variant={activeSection === "TTIs" ? "contained" : "outlined"}
          onClick={() => setActiveSection("TTIs")}
          sx={{
            borderRadius: 0,
            borderLeft: activeSection === "TTIs" ? "1px solid" : "none",
            borderRight: activeSection === "TTIs" ? "1px solid" : "none",
            color: activeSection === "TTIs" ? "#ffffff" : "#45BC5F",
            backgroundColor:
              activeSection === "TTIs" ? "#45BC5F" : "transparent",
            borderColor: "#45BC5F",
            "&:hover": {
              borderColor: "#45BC5F",
              color: activeSection === "TTIs" ? "#ffffff" : "#45BC5F",
              backgroundColor:
                activeSection === "TTIs" ? "#45BC5F" : "transparent",
            },
          }}
        >
          TTIs
        </Button>
        <Button
          variant={activeSection === "IGF" ? "contained" : "outlined"}
          onClick={() => setActiveSection("IGF")}
          sx={{
            borderRadius: 0,

            borderLeft: activeSection === "IGF" ? "1px solid" : "none",
            borderRight: activeSection === "IGF" ? "1px solid" : "none",
            color: activeSection === "IGF" ? "#ffffff" : "#45BC5F",
            backgroundColor:
              activeSection === "IGF" ? "#45BC5F" : "transparent",
            borderColor: "#45BC5F",
            "&:hover": {
              borderColor: "#45BC5F",
              color: activeSection === "IGF" ? "#ffffff" : "#45BC5F",
              backgroundColor:
                activeSection === "IGF" ? "#45BC5F" : "transparent",
            },
          }}
        >
          IGF
        </Button>
        <Button
          variant={activeSection === "Skill" ? "contained" : "outlined"}
          onClick={() => setActiveSection("Skill")}
          sx={{
            borderRadius: 0,

            borderLeft: activeSection === "Skill" ? "1px solid" : "none",
            borderRight: activeSection === "Skill" ? "1px solid" : "none",
            color: activeSection === "Skill" ? "#ffffff" : "#45BC5F",
            backgroundColor:
              activeSection === "Skill" ? "#45BC5F" : "transparent",
            borderColor: "#45BC5F",
            "&:hover": {
              borderColor: "#45BC5F",
              color: activeSection === "Skill" ? "#ffffff" : "#45BC5F",
              backgroundColor:
                activeSection === "Skill" ? "#45BC5F" : "transparent",
            },
          }}
        >
          NSQF Certification
        </Button>
      </div>

      {activeSection === "TTIs" && (
        <div>
          <h1
            className="font-bold text-primary_text text-xl mb-3"
            style={{ fontFamily: "Poppins, sans-serif" }}
          >
            PDO TTIs
          </h1>
          <Swiper
            modules={[Navigation, Pagination]}
            spaceBetween={30}
            slidesPerView={2}
            navigation={{ nextEl: ".button-next1", prevEl: ".button-prev1" }}
          >
            <SwiperSlide>
              <h4 className="font-semibold">NSQF Assessors</h4>
              <BarChartDisplayComponent4
                maleCount={maleCountPreService}
                femaleCount={femaleCountPreService}
              />
              <p
                className="text-xs"
                style={{ fontFamily: "Poppins, sans-serif" }}
              >
                Number of Male and Female technical teachers and instructors in
                TCs certified as NSQF assessors
              </p>
            </SwiperSlide>
            <SwiperSlide>
              <h4 className=" font-semibold">NSQF Verifiers</h4>
              <BarChartDisplayComponent4
                maleCount={maleCountInService}
                femaleCount={femaleCountInService}
              />
              <p
                className="text-xs"
                style={{ fontFamily: "Poppins, sans-serif" }}
              >
                Number of Male and Female technical teachers and instructors in
                TCs certified as NSQF Verifiers
              </p>
            </SwiperSlide>
          </Swiper>
          <Box className="flex items-center justify-between text-primary_text_light mt-3">
            <motion.div whileTap={{ scale: 0.9 }} className="button-prev1">
              <KeyboardArrowLeftIcon
                sx={{ fontSize: "60px" }}
                className="cursor-pointer"
              />
            </motion.div>
            <motion.div whileTap={{ scale: 0.9 }} className="button-next1">
              <KeyboardArrowRightIcon
                sx={{ fontSize: "60px" }}
                className="cursor-pointer"
              />
            </motion.div>
          </Box>
        </div>
      )}

      {activeSection === "IGF" && (
        <div>
          <h1
            className="font-bold text-xl mb-3 text-primary_text"
            style={{ fontFamily: "Poppins, sans-serif" }}
          >
            PDO IGF
          </h1>
          <Swiper
            modules={[Navigation, Pagination]}
            spaceBetween={30}
            slidesPerView={2}
            navigation={{ nextEl: ".button-next2", prevEl: ".button-prev2" }}
          >
            <SwiperSlide>
              <h4 className=" font-semibold">IGF Data</h4>
              <BarChart
                data={dataset}
                xAxis={[
                  {
                    scaleType: "band",
                    data: dataset.map((item) => item.group),
                  },
                ]}
                barLabel="value"
                series={[
                  { data: dataset.map((item) => item.male), label: "Male", color: customColors[0] },
                  { data: dataset.map((item) => item.female), label: "Female", color: customColors[1] },
                ]}
                {...chartSetting}
              />
            </SwiperSlide>
          </Swiper>
          <Box className="flex items-center justify-between text-primary_text_light mt-3">
            <motion.div whileTap={{ scale: 0.9 }} className="button-prev2">
              <KeyboardArrowLeftIcon
                sx={{ fontSize: "60px" }}
                className="cursor-pointer"
              />
            </motion.div>
            <motion.div whileTap={{ scale: 0.9 }} className="button-next2">
              <KeyboardArrowRightIcon
                sx={{ fontSize: "60px" }}
                className="cursor-pointer"
              />
            </motion.div>
          </Box>
        </div>
      )}

      {activeSection === "Skill" && (
        <div>
          <h1
            className="font-bold text-xl mb-3 text-primary_text"
            style={{ fontFamily: "Poppins, sans-serif" }}
          >
            PDO Skill Acquisition Center
          </h1>
          <Swiper
            modules={[Navigation, Pagination]}
            spaceBetween={30}
            slidesPerView={2}
            navigation={{ nextEl: ".button-next3", prevEl: ".button-prev3" }}
          >
            <SwiperSlide>
              <h4 className=" font-semibold">NSQF Assessors</h4>
              <BarChartDisplayComponent4
                maleCount={maleCountPreService}
                femaleCount={femaleCountPreService}
              />
              <p
                className="text-xs"
                style={{ fontFamily: "Poppins, sans-serif" }}
              >
                Number of Male and Female technical teachers and instructors in
                skills acquisition centers certified as NSQF assessors
              </p>
            </SwiperSlide>
            <SwiperSlide>
              <h4 className=" font-semibold">NSQF Verifiers</h4>
              <BarChartDisplayComponent4
                maleCount={maleCountInService}
                femaleCount={femaleCountInService}
              />
              <p
                className="text-xs"
                style={{ fontFamily: "Poppins, sans-serif" }}
              >
                Number of Male t and Femaleechnical teachers and instructors in
                skills acquisition centers certified as NSQF Verifiers
              </p>
            </SwiperSlide>
          </Swiper>
          <Box className="flex items-center justify-between text-primary_text_light mt-3">
            <motion.div whileTap={{ scale: 0.9 }} className="button-prev3">
              <KeyboardArrowLeftIcon
                sx={{ fontSize: "60px" }}
                className="cursor-pointer"
              />
            </motion.div>
            <motion.div whileTap={{ scale: 0.9 }} className="button-next3">
              <KeyboardArrowRightIcon
                sx={{ fontSize: "60px" }}
                className="cursor-pointer"
              />
            </motion.div>
          </Box>
        </div>
      )}
    </>
  );
};

export default PdoCardComponent4;
