import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper/modules";
import {
  FirstBarChartDisplayIoi,
  FourthBarChartDisplayIoi,
  SecondBarChartDisplayIoi,
  ThirdBarChartDisplayIoi,
} from "../Charts/BarChartIoi";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { Box } from "@mui/material";
import { motion } from "framer-motion";
import {
  selectCurrentJurisdiction,
  selectCurrentToken,
  selectCurrentUserRole,
  selectValidatorState,
} from "../../../../features/auth/authSlice";
import { useSelector } from "react-redux";
import { jwtDecode } from "jwt-decode";
import { useQuery } from "@tanstack/react-query";
import {
  fetchAllIOI1Posts,
  fetchAllIOI1PostsForNA,
  fetchAllIOI2Posts,
  fetchAllIOI2PostsForNA,
  fetchAllIOI3Posts,
  fetchAllIOI3PostsForNA,
  fetchAllIOI4Posts,
  fetchAllIOI4PostsForNA,
  fetchAllStudents,
  fetchIOIPostForValidator,
} from "../../../../utils/apiContext";
import ShowLoader from "../../../../utils/showLoader";

const IntermediateCard = () => {
  const userRole = useSelector(selectCurrentUserRole);
  const jurisdiction = useSelector(selectCurrentJurisdiction);
  const userTkn = useSelector(selectCurrentToken);
  const decodedTkn = jwtDecode(userTkn);
  const validatorState = useSelector(selectValidatorState);
  const { tc_name } = decodedTkn;

  const {
    data: ioi_1_Na,
    isLoading: ioi_1_NaIsLoading,
    error: ioi_1_Na_Error,
  } = useQuery({
    queryKey: ["fetchAllIOI1PostsForNA"],
    queryFn: userRole === "National_Admin" && fetchAllIOI1PostsForNA,
    enabled: userRole === "National_Admin",
  });

  const {
    data: ioi_2_Na,
    isLoading: ioi_2_NaIsLoading,
    error: ioi_2_Na_Error,
  } = useQuery({
    queryKey: "fetchAllIOI2PostsForNA",
    queryFn: userRole === "National_Admin" && fetchAllIOI2PostsForNA,
    enabled: userRole === "National_Admin",
  });

  const {
    data: ioi_3_Na,
    isLoading: ioi_3_NaIsLoading,
    error: ioi_3_Na_Error,
  } = useQuery({
    queryKey: ["fetchAllIOI3PostsForNA"],
    queryFn: userRole === "National_Admin" && fetchAllIOI3PostsForNA,
    enabled: userRole === "National_Admin",
  });

  const {
    data: ioi_4_Na,
    isLoading: ioi_4_NaIsLoading,
    error: ioi_4_Na_Error,
  } = useQuery({
    queryKey: ["fetchAllIOI4PostsForNA"],
    queryFn: userRole === "National_Admin" && fetchAllIOI4PostsForNA,
    enabled: userRole === "National_Admin",
  });

  // fetch ioi1
  const {
    data: ioi_1,
    isLoading: ioi1isLoading,
    error: ioi1error,
  } = useQuery({
    queryKey: ["ioi1"],
    queryFn: () =>
      userRole !== "National_Admin" && fetchAllIOI1Posts(jurisdiction),
    enabled: userRole !== "National_Admin" && !!jurisdiction,
  });
  const filteredIoi1 =
    userRole === "Validator"
      ? ioi_1?.filter((ioi1) => ioi1?.state === validatorState)
      : ioi_1;

  // fetch ioi2
  const {
    data: ioi_2,
    isLoading: ioi2isLoading,
    error: ioi2error,
  } = useQuery({
    queryKey: ["ioi2"],
    queryFn: () =>
      userRole !== "National_Admin" && fetchAllIOI2Posts(jurisdiction),
    enabled: userRole !== "National_Admin" && !!jurisdiction,
  });
  const filteredIoi2 =
    userRole === "Validator"
      ? ioi_2?.filter((ioi2) => ioi2?.state === validatorState)
      : ioi_2;

  // fetch ioi3
  const {
    data: ioi_3,
    isLoading: ioi3isLoading,
    error: ioi3error,
  } = useQuery({
    queryKey: ["ioi3"],
    queryFn: () =>
      userRole !== "National_Admin" && fetchAllIOI3Posts(jurisdiction),
    enabled: userRole !== "National_Admin" && !!jurisdiction,
  });
  const filteredIoi3 =
    userRole === "Validator"
      ? ioi_3?.filter((ioi3) => ioi3?.state === validatorState)
      : ioi_3;

  // fetch ioi4
  const {
    data: ioi_4,
    isLoading: ioi4isLoading,
    error: ioi4error,
  } = useQuery({
    queryKey: ["ioi4"],
    queryFn: () =>
      userRole !== "National_Admin" && fetchAllIOI4Posts(jurisdiction),
    enabled: userRole !== "National_Admin" && !!jurisdiction,
  });

  const filteredIoi4 =
    userRole === "Validator"
      ? ioi_4?.filter((ioi4) => ioi4?.state === validatorState)
      : ioi_4;

  let ioi_1_array = [];
  if (ioi_1_Na && Array.isArray(ioi_1_Na)) {
    ioi_1_array = [
      ...(ioi_1_Na[0]?.found_posts || []),
      ...(ioi_1_Na[1]?.found_posts || []),
    ];
  }

  let ioi_2_array = [];
  if (ioi_2_Na && Array.isArray(ioi_2_Na)) {
    ioi_2_array = [
      ...(ioi_2_Na[0]?.found_posts || []),
      ...(ioi_2_Na[1]?.found_posts || []),
    ];
  }

  let ioi_3_array = [];
  if (ioi_3_Na && Array.isArray(ioi_3_Na)) {
    ioi_3_array = [
      ...(ioi_3_Na[0]?.found_posts || []),
      ...(ioi_3_Na[1]?.found_posts || []),
    ];
  }

  let ioi_4_array = [];
  if (ioi_4_Na && Array.isArray(ioi_4_Na)) {
    ioi_4_array = [
      ...(ioi_4_Na[0]?.found_posts || []),
      ...(ioi_4_Na[1]?.found_posts || []),
    ];
  }

  return (
    <div className="">
      <h1
        className="font-bold text-xl mb-3 text-primary_text"
        style={{ fontFamily: "Poppins, sans-serif" }}
      >
        Intermediate Outcome Indicators
      </h1>
      <Swiper
        modules={[Navigation, Pagination]}
        spaceBetween={30}
        slidesPerView={2}
        navigation={{ nextEl: ".button-next1", prevEl: ".button-prev1" }}
      >
        {ioi1isLoading || ioi_1_NaIsLoading ? (
          <ShowLoader />
        ) : (
          <SwiperSlide>
            <FirstBarChartDisplayIoi
              data={userRole === "National_Admin" ? ioi_1_array : filteredIoi1}
            />
            <p
              className="text-xs"
              style={{ fontFamily: "Poppins, sans-serif" }}
            >
              Number of supported Technical Colleges with functioning modernized
              Governing Board with industry partnership
            </p>
          </SwiperSlide>
        )}
        {ioi2isLoading || ioi_2_NaIsLoading ? (
          <ShowLoader />
        ) : (
          <SwiperSlide>
            <SecondBarChartDisplayIoi
              data={userRole === "National_Admin" ? ioi_2_array : filteredIoi2}
            />
            <p
              className="text-xs"
              style={{ fontFamily: "Poppins, sans-serif" }}
            >
              Number of training programs in supported Technical Colleges
              delivered and monitored in partnership with the private sector
            </p>
          </SwiperSlide>
        )}
        {ioi3isLoading || ioi_3_NaIsLoading ? (
          <ShowLoader />
        ) : (
          <SwiperSlide>
            <ThirdBarChartDisplayIoi
              data={userRole === "National_Admin" ? ioi_3_array : filteredIoi3}
            />
            <p
              className="text-xs"
              style={{ fontFamily: "Poppins, sans-serif" }}
            >
              Number of supported TCs with reporting and referral mechanisms for
              GBV affected youth
            </p>
          </SwiperSlide>
        )}
        {ioi4isLoading || ioi_4_NaIsLoading ? (
          <ShowLoader />
        ) : (
          <SwiperSlide>
            <FourthBarChartDisplayIoi
              data={userRole === "National_Admin" ? ioi_4_array : filteredIoi4}
            />
            <p
              className="text-xs"
              style={{ fontFamily: "Poppins, sans-serif" }}
            >
              Number of fully functioning upgraded workshops/labs in supported
              TCs
            </p>
          </SwiperSlide>
        )}
      </Swiper>
      <Box className="flex items-center justify-between text-primary_text_light mt-3">
        <motion.div whileTap={{ scale: 0.9 }} className="button-prev1">
          <KeyboardArrowLeftIcon
            sx={{ fontSize: "60px" }}
            className="cursor-pointer"
          />
        </motion.div>
        <motion.div whileTap={{ scale: 0.9 }} className="button-next1">
          <KeyboardArrowRightIcon
            sx={{ fontSize: "60px" }}
            className="cursor-pointer"
          />
        </motion.div>
      </Box>
    </div>
  );
};

export default IntermediateCard;
