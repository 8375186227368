import React from "react";

const CertifiedYouthsCard = ({totalYouths}) => {
  return (
    <div className="custom-shadow bg-white p-2 rounded-lg">
      <h1
        className="font-bold text-primary_text"
        style={{ fontFamily: "Poppins, sans-serif" }}
      >
        Certified Youths after Informal Apprenticeship Training
      </h1>
      <h1
        className="text-active_bg text-2xl font-bold mt-2"
        style={{ fontFamily: "Poppins, sans-serif" }}
      >
       {totalYouths}
      </h1>
    </div>
  );
};

export default CertifiedYouthsCard;
