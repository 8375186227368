import React from "react";

export const MasterCraftPersonAssessorCard = ({ totalAssessors }) => {
  return (
    <div className="custom-shadow bg-white p-2 rounded-lg">
      <h1
        className="font-bold text-primary_text"
        style={{ fontFamily: "Poppins, sans-serif" }}
      >
        <span className="text-red-500">Master Craft Persons</span> in
        certified as{" "}
        <span className="text-red-500">Assessors</span>.
      </h1>
      <h1
        className="text-active_bg text-2xl font-bold mt-2"
        style={{ fontFamily: "Poppins, sans-serif" }}
      >
        {totalAssessors}
      </h1>
    </div>
  );
};

export const MasterCraftPersonVerifierCard = ({ totalVerifiers }) => {
  return (
    <div className="custom-shadow bg-white p-2 rounded-lg">
      <h1
        className="font-bold text-primary_text"
        style={{ fontFamily: "Poppins, sans-serif" }}
      >
        <span className="text-red-500">Master Craft Persons</span> in
        certified as{" "}
        <span className="text-red-500">Verifiers</span>.
      </h1>
      <h1
        className="text-active_bg text-2xl font-bold mt-2"
        style={{ fontFamily: "Poppins, sans-serif" }}
      >
        {totalVerifiers}
      </h1>
    </div>
  );
};
