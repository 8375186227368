import React from "react";
import {
  FirstBarChartDisplay,
  SecondBarChartDisplay,
} from "../chart_components/BarChartDisplays";

const StudentsCard = ({ aggregatedData }) => {
  return (
    <div className="">
      <div>
        <h1
          className="text-primary_text font-bold mb-8 text-[20px]"
          style={{ fontFamily: "Poppins, sans-serif" }}
        >
          Total Number of enrolled students
        </h1>
        <SecondBarChartDisplay
          maleCount={aggregatedData?.maleCount}
          femaleCount={aggregatedData?.femaleCount}
        />
      </div>
      <div className="">
        <h1
          className="text-primary_text font-bold mt-8 mb-4 text-[20px]"
          style={{ fontFamily: "Poppins, sans-serif" }}
        >
          Total Number of graduates{" "}
        </h1>
        <FirstBarChartDisplay
          maleCount={aggregatedData?.maleGraduates}
          femaleCount={aggregatedData?.femaleGraduates}
        />
      </div>
    </div>
  );
};

export default StudentsCard;
