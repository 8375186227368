import { PieChart, pieArcLabelClasses } from "@mui/x-charts";
import ShowLoader from "../../../../utils/showLoader";

const tradeColorMap = {
  Construction: "#0E4F2F",
  "Mechanical Engineering": "#45BC5F",
  Hospitality: "#843C30",
  "Electrical Engineering": "#BC5645",
  ICT: "#BC5645",
  "Agricultural Science": "#C0AA54",
  "Garment Making": "#CFF0B5",
};

export const TeachersDoughnutChartDisplayComponent3 = ({ data }) => {
  if (!data) {
    return <ShowLoader />;
  }

  // Ensure the data is in the expected format
  const formattedData = [
    { label: "Male", value: data[0].malePercentage },
    { label: "Female", value: data[1].femalePercentage },
  ];

  return (
    <PieChart
      series={[
        {
          data: formattedData.map((item) => ({
            ...item,
            label: `${item.label} (${item.value}%)`,
          })),
          arcLabel: (item) => `${item.label}`,
          arcLabelMinAngle: 1,
          innerRadius: 30,
          cornerRadius: 5,
          cx: 300,
          cy: 175,
        },
      ]}
      width={800}
      height={350}
      sx={{
        [`& .${pieArcLabelClasses.root}`]: {
          fill: "white",
          fontWeight: "bold",
          fontSize: "10px",
        },
      }}
    />
  );
};
