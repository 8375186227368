import React, { useEffect, useRef, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { motion } from "framer-motion";
import {
  FormCheckbox,
  FormDropdown,
  FormInput,
} from "../../../Components/form";
import CustomButton from "../../../Components/button";
import { toast } from "sonner";
import { useMutation } from "react-query";
import axiosInstance from "../../../Services/axiosInstance";
import { stateOptions } from "./stateOptions";

const certificationLabels = ["Assessor", "Verifier"];

const McpComponent2Form = ({ closeForm, title }) => {
  const modalRef = useRef(null);

  const [nameOfBusiness, setNameOfBusiness] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [address, setAddress] = useState("");
  const [certification, setCertification] = useState([]);
  const [lga, setLga] = useState("");
  const [state, setState] = useState("");
  const [stateOfService, setStateOfService] = useState("");
  const [serviceProvider, setServiceProvider] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const [errors, setErrors] = useState({});

  const mutation = useMutation({
    mutationFn: async (formData) => {
      setIsLoading(true);
      const response = await axiosInstance.post(`/mcp/c2/make-mcp`, formData);
      console.log(response.data);
      return response;
    },
    onSuccess: (response) => {
      toast.success("Successful");
      setIsLoading(false);
      closeForm();
    },
    onError: (err) => {
      setIsLoading(false);
      closeForm();
    },
  });

  const handleInputChange = (e, setValue) => {
    setValue(e.target.value);
    setErrors({ ...errors, [e.target.name]: "" });
  };
  const handleCheckboxChange = (event) => {
    const { value, checked } = event.target;
    setCertification((prevCertification) => {
      if (checked) {
        // Add the selected certification
        return [...prevCertification, value];
      } else {
        // Remove the deselected certification
        return prevCertification.filter((cert) => cert !== value);
      }
    });
  };

  const validate = () => {
    const newErrors = {};
    const requiredFields = {
      nameOfBusiness,
      firstName,
      lastName,
      certification,
      serviceProvider,
    };

    Object.keys(requiredFields).forEach((field) => {
      if (!requiredFields[field]) {
        newErrors[field] = "This field is required";
      }
    });

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!validate()) {
      toast.error("Required Fields can't be empty");
      return;
    }

    const newObj = {
      name_of_business: nameOfBusiness,
      first_name: firstName,
      last_name: lastName,
      email: email,
      address: address,
      certification: certification,
      state: state,
      lga: lga,
      state_of_service: stateOfService,
      service_provider: serviceProvider,
    };
    mutation.mutate(newObj);
    console.log("formObject:", newObj);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        closeForm(); // Assuming closeForm sets the state that controls the modal visibility
      }
    };
    document.addEventListener("mousedown", handleClickOutside);

    // Remove event listener on cleanup
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [closeForm]);
  const modalVariants = {
    hidden: {
      y: "-100vh",
      opacity: 0,
    },
    visible: {
      y: "0",
      opacity: 1,
      transition: { delay: 0.2, type: "spring", stiffness: 120 },
    },
  };
  return (
    <div className="fixed left-0 top-0 w-full h-full bg-black bg-opacity-50 flex justify-center items-center z-20">
      <motion.div
        ref={modalRef}
        variants={modalVariants}
        initial="hidden"
        animate="visible"
        exit="hidden"
        className="scrollbar-hide overflow-auto w-[50%] h-[98vh] px-5 pt-8 pb-6 bg-white rounded-lg relative"
      >
        <div className="flex justify-between items-center font-bold">
          <h1 className="text-2xl">{title}</h1>

          <CloseIcon
            onClick={closeForm}
            sx={{ fontSize: "2rem" }}
            fontWeight={"30"}
            className="text-red-500 cursor-pointer absolute top-2 right-4"
          />
        </div>
        <hr className="mt-2 mb-4" />
        <form onSubmit={handleSubmit}>
          <FormInput
            type="text"
            name="name_of_business"
            label="Business Name"
            isRequired={true}
            value={nameOfBusiness}
            onChange={(e) => handleInputChange(e, setNameOfBusiness)}
          />
          {errors.nameOfBusiness && (
            <p className="text-red-500 text-xs mt-1">{errors.nameOfBusiness}</p>
          )}
          <div className="flex justify-between gap-2">
            <div className="w-full">
              <FormInput
                type="text"
                name="first_name"
                label="First Name"
                isRequired={true}
                value={firstName}
                onChange={(e) => handleInputChange(e, setFirstName)}
              />
              {errors.firstName && (
                <p className="text-red-500 text-xs mt-1">{errors.firstName}</p>
              )}
            </div>
            <div className="w-full">
              <FormInput
                type="text"
                name="last_name"
                label="Last Name"
                value={lastName}
                isRequired={true}
                onChange={(e) => handleInputChange(e, setLastName)}
              />
              {errors.lastName && (
                <p className="text-red-500 text-xs mt-1">{errors.lastName}</p>
              )}
            </div>
          </div>
          <div className="flex justify-between gap-2">
            <div className="w-full">
              <FormInput
                type="email"
                name="email"
                label="Email"
                value={email}
                onChange={(e) => handleInputChange(e, setEmail)}
              />
            </div>
            <div className="w-full">
              <FormInput
                type="text"
                name="address"
                label="Address"
                value={address}
                onChange={(e) => handleInputChange(e, setAddress)}
              />
            </div>
          </div>

          <div className="mb-8">
            <p className="font-bold text-sm">Select Certification Type</p>
            {certificationLabels.map((label, index) => (
              <FormCheckbox
                key={index}
                id={`checkbox-${index}`}
                label={label}
                value={label}
                isRequired={true}
                checked={certification.includes(label)}
                onChange={handleCheckboxChange}
              />
            ))}
            {errors.certification && (
              <p className="text-red-500 text-xs mt-1">
                {errors.certification}
              </p>
            )}
          </div>

          <FormDropdown
            name="state"
            label="State of Origin"
            value={state}
            onChange={(e) => handleInputChange(e, setState)}
            options={stateOptions}
          />
          <FormInput
            type="text"
            name="lga"
            label="LGA"
            value={lga}
            onChange={(e) => handleInputChange(e, setLga)}
          />
          <FormInput
            type="text"
            name="state_of_service"
            label="MCP Residing State"
            value={stateOfService}
            onChange={(e) => handleInputChange(e, setStateOfService)}
          />
          <FormDropdown
            name="service_provider"
            value={serviceProvider}
            label="Service Provider Type"
            isRequired={true}
            options={[
              { value: "Business Association", label: "Business Association" },
              { value: "Consultancy Firm", label: "Consultancy Firm" },
              { value: "NGO", label: "NGO" },
            ]}
            onChange={(e) => handleInputChange(e, setServiceProvider)}
            placeholder="Select an option"
          />
          {errors.serviceProvider && (
            <p className="text-red-500 text-xs mt-1">
              {errors.serviceProvider}
            </p>
          )}
          <br />
          <div className="flex justify-center">
            <CustomButton className="bg-active_bg" isLoading={isLoading}>
              {isLoading ? "Submiting..." : "Submit"}
            </CustomButton>
          </div>
        </form>
      </motion.div>
    </div>
  );
};

export default McpComponent2Form;
