import CloseIcon from "@mui/icons-material/Close";
import CustomButton from "../../../button";
import { FormDropdown, FormInput, FormMediaUpload, FormRadioInput } from "../../../form";
import { motion } from "framer-motion";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { jwtDecode } from "jwt-decode";
import { useMutation } from "@tanstack/react-query";
import axiosInstance from "../../../../Services/axiosInstance";
import { toast } from "sonner";
import {
  selectCurrentJurisdiction,
  selectCurrentUser,
} from "../../../../features/auth/authSlice";
import { setIoi3Post } from "../../../../features/auth/isPostMade";
import { validatorStates } from "../states";

const IntermediateOutcome3 = ({ title, closeForm }) => {
  const modalRef = useRef(null);
  const authToken = useSelector((state) => state.auth.token);
  const decodedTkn = jwtDecode(authToken);

  const email = useSelector(selectCurrentUser);
  const jurisdiction = useSelector(selectCurrentJurisdiction);

  const { tc_name } = decodedTkn;
  const [errors, setErrors] = useState({});

  const [state, setState] = useState("");
  const [gbvSensitizationConducted, setGbvSensitizationConducted] =
    useState("");
  const [gbvPolicyDeveloped, setGbvPolicyDeveloped] = useState("");
  const [gbvPolicyPublished, setGbvPolicyPublished] = useState("");
  const [gbvReportingAndReferral, setGbvReportingAndReferral] = useState("");
  const [grievanceRedressMechanism, setGrievanceRedressMechanism] =
    useState("");
  const [sensitizationPdf, setSensitizationPdf] = useState([]);
  const [schoolGbvPdf, setSchoolGbvPdf] = useState(null);
  const [complaintsBoxPdf, setComplaintsBoxPdf] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useDispatch();
  const validate = () => {
    const newErrors = {};
    const requiredFields = {};

    if (jurisdiction === "state") {
      requiredFields.state = state;
    }

    Object.keys(requiredFields).forEach((field) => {
      if (!requiredFields[field]) {
        newErrors[field] = "This field is required";
      }
    });

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const mutation = useMutation({
    mutationFn: async (formData) => {
      setIsLoading(true);
      const response = await axiosInstance.post(
        "/ioi/c1/make-post-no-of-supported-tc-with-reporting-and-referral-mechanisms-for-gbv-affected-youth",
        formData
      );
      return response;
    },
    onSuccess: (response) => {
      toast.success("Successful");
      setIsLoading(false);
      const isCheck = response.data.newDoc;
      const letSee = `${jurisdiction}_tc`;
      const isPostMade = isCheck[letSee].some(
        (post) => post.tc_name === tc_name
      );
      console.log(isPostMade);
      if (isPostMade) {
        dispatch(setIoi3Post(true));
      } else {
        dispatch(setIoi3Post(false));
      }
      closeForm();
    },
    onError: (err) => {
      if (err.response.status === 400) {
        toast.error("Post already exists");
      } else {
        toast.error("An Error Occurred");
      }
      setIsLoading(false);
      closeForm();
    },
  });

  const handleRadioChange = (event) => {
    const { name, value } = event.target;

    switch (name) {
      case "gbv_sensitization_conducted_by_the_school":
        setGbvSensitizationConducted(value);
        break;
      case "gbv_policy_developed_by_school":
        setGbvPolicyDeveloped(value);
        break;
      case "gbv_policy_published_by_school":
        setGbvPolicyPublished(value);
        break;
      case "gbv_reporting_and_referral_system_for_youths_in_place_at_the_school":
        setGbvReportingAndReferral(value);
        break;
      case "presence_of_grievance_redress_mechanism_at_the_school":
        setGrievanceRedressMechanism(value);
        break;
      default:
        break;
    }
  };

  const handleFileChange = (e, setFile) => {
    const files = Array.from(e.target.files);
    if (files.length > 4) {
      toast.error("You can only upload a maximum of 4 PDFs.");
      return;
    }
    setFile((prevFiles) => [...prevFiles, ...files]);
  };

  const handleInputChange = (e, setValue) => {
    setValue(e.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    // Validation check to ensure no fields are empty
    if (!validate()) {
      toast.error("Required Fields can't be empty");
      return;
    }

    setIsLoading(true);

    const formData = new FormData();
    formData.append("state", state);
    formData.append("tc_name", tc_name);
    formData.append("jurisdiction", jurisdiction);
    formData.append("email", email);
    formData.append(
      "gbv_sensitization_conducted_by_the_school",
      gbvSensitizationConducted
    );
    formData.append("gbv_policy_developed_by_school", gbvPolicyDeveloped);
    formData.append("gbv_policy_published_by_school", gbvPolicyPublished);
    formData.append(
      "gbv_reporting_and_referral_system_for_youths_in_place_at_the_school",
      gbvReportingAndReferral
    );
    formData.append(
      "presence_of_grievance_redress_mechanism_at_the_school",
      grievanceRedressMechanism
    );
    // handle pdfs
    if (sensitizationPdf)
      formData.append("sensitization_pdf", sensitizationPdf);
    if (schoolGbvPdf) formData.append("school_gbv_policy_pdf", schoolGbvPdf);
    if (complaintsBoxPdf)
      formData.append(
        "reports_showing_addressed_complaints_box_pdf",
        complaintsBoxPdf
      );

    mutation.mutate(formData);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        closeForm();
      }
    };

    // Add event listener when the component mounts
    document.addEventListener("mousedown", handleClickOutside);

    // Remove event listener on cleanup
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [closeForm]);

  const modalVariants = {
    hidden: {
      y: "-100vh",
      opacity: 0,
    },
    visible: {
      y: "0",
      opacity: 1,
      transition: { delay: 0.2, type: "spring", stiffness: 120 },
    },
  };
  // console.log(sensitizationPdf);
  return (
    <div className="fixed left-0 top-0 w-full h-full bg-black bg-opacity-50 flex justify-center items-center z-20">
      <motion.div
        ref={modalRef}
        variants={modalVariants}
        initial="hidden"
        animate="visible"
        exit="hidden"
        className="scrollbar-hide overflow-auto w-[50%] h-[98vh] px-5 pt-8 pb-6 bg-white rounded-lg relative"
      >
        <div className="flex justify-between items-center font-bold">
          <h1 className="text-2xl">{title}</h1>

          <CloseIcon
            onClick={closeForm}
            sx={{ fontSize: "2rem" }}
            fontWeight={"30"}
            className="text-red-500 cursor-pointer absolute top-2 right-4"
          />
        </div>

        <hr className="mt-2 mb-4" />
        <form onSubmit={handleSubmit}>
        {jurisdiction === "state" && (
            <FormDropdown
              name="state"
              label="Enter State Location"
              value={state}
              options={validatorStates}
              onChange={(e) => handleInputChange(e, setState)}
              errorMessage={errors.state}
            />
          )}
          {errors.state && (
            <p className="text-red-500 text-xs mt-1">{errors.state}</p>
          )}
          <FormRadioInput
            type="radio"
            name="gbv_sensitization_conducted_by_the_school"
            label="Gender Based Violence (GBV) Sensitization conducted by the school"
            checkedValue={gbvSensitizationConducted}
            onChange={handleRadioChange}
          />

          <FormMediaUpload
            type="file"
            id="sensitization_pdf"
            name="sensitization_pdf"
            label="Upload updated Sensitization report(s) in one PDF Document(file max size 5MB)"
            onChange={(e) => handleFileChange(e, setSensitizationPdf)}
            fileNames={sensitizationPdf?.map((file) => file.name)}
          />

          <FormRadioInput
            type="radio"
            name="gbv_policy_developed_by_school"
            label="GBV Policy developed by school"
            checkedValue={gbvPolicyDeveloped}
            onChange={handleRadioChange}
          />

          <FormRadioInput
            type="radio"
            name="gbv_policy_published_by_school"
            label="GBV Policy published  by school"
            checkedValue={gbvPolicyPublished}
            onChange={handleRadioChange}
          />

          <FormMediaUpload
            type="file"
            id="school_gbv_policy_pdf"
            name="school_gbv_policy_pdf"
            label="Upload a copy of your school’s GBV Policy(file max size 5MB)"
            onChange={(e) => handleFileChange(e, setSchoolGbvPdf)}
            fileName={schoolGbvPdf?.name}
          />

          <FormRadioInput
            type="radio"
            name="gbv_reporting_and_referral_system_for_youths_in_place_at_the_school"
            label="GBV reporting and referral system for affected youths in place at the school"
            checkedValue={gbvReportingAndReferral}
            onChange={handleRadioChange}
          />
          <FormRadioInput
            type="radio"
            name="presence_of_grievance_redress_mechanism_at_the_school"
            label="Presence of grievance redress mechanism (GRM) at the school."
            checkedValue={grievanceRedressMechanism}
            onChange={handleRadioChange}
          />
          <FormMediaUpload
            type="file"
            id="reports_showing_addressed_complaints_box_pdf"
            name="reports_showing_addressed_complaints_box_pdf"
            label="Upload Updated Reports Showing Cases Received & Addressed, and Pictures of   Complains box all in one PDF document(file max size 5MB)"
            onChange={(e) => handleFileChange(e, setComplaintsBoxPdf)}
            fileNames={complaintsBoxPdf?.map((file) => file.name)}
          />
          <div className="flex justify-center">
            <CustomButton className="bg-active_bg" isLoading={isLoading}>
              {isLoading ? "Submitting..." : "Submit"}
            </CustomButton>
          </div>
        </form>
      </motion.div>
    </div>
  );
};

export default IntermediateOutcome3;
