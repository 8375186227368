import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
} from "@mui/material";
import PageHeader from "../../Components/page_header";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { fetchComponent2MCPData } from "../../utils/apiContext";
import MoreOptionsMenu from "../../Components/MoreOptionsIcon";
import EditModalForm from "../../Components/Modal_Forms/EditModal";
import axiosInstance from "../../Services/axiosInstance";
import { stateOptions } from "./FormComponents/stateOptions";
import ConfirmationModal from "../../Components/Modal_Forms/ConfirmDelete";

const columns = [
  { id: 1, label: "", type: "disabled" },
  { id: 2, label: "S/N", type: "disabled" },
  { id: "business_name", label: "Business Name", type: "text" },
  { id: "first_name", label: "First Name", type: "text" },
  { id: "last_name", label: "Last Name", type: "text" },

  {
    id: "gender",
    label: "Gender",
    type: "dropdown",
    options: [
      { value: "male", label: "Male" },
      { value: "female", label: "Female" },
    ],
  },
  { id: "age", label: "Age", type: "number" },
  { id: "email", label: "Email", type: "email" },
  { id: "address", label: "Address", type: "text" },

  { id: "phone_number", label: "Phone Number", type: "number" },
  {
    id: "state_of_origin",
    label: "State of Origin",
    options: { stateOptions },
  },

  { id: "skill", label: "Skill", type: "text" },
];

const TraineeDataTable = ({ traineesData }) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState(null);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [rowToDelete, setRowToDelete] = useState(null);

  const rows = traineesData;

  const queryClient = useQueryClient()

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const onEdit = (rowData) => {
    setSelectedRowData(rowData);
    setIsEditModalOpen(true);
  };
  const handleEditAndSave = async (updatedData) => {
    try {
      await axiosInstance.put(
        `/trainees/c2/edit-trainee/${updatedData._id}`,
        updatedData
      );
      queryClient.invalidateQueries(["traineeData"]);
      setIsEditModalOpen(false);
    } catch (error) {
      console.error("Failed to save the updated data:", error);
    }
  };
  const onDelete = (rowId) => {
    setRowToDelete(rowId);
    setIsConfirmationModalOpen(true);
  };
  const handleConfirmDelete = async () => {
    try {
      await axiosInstance.delete(
        `/trainees/c2/delete-trainee/${rowToDelete}`
      );
      setIsConfirmationModalOpen(false);
      queryClient.invalidateQueries(["traineeData"]);
    } catch (error) {
      console.error("Failed to delete the data:", error);
    }
  };

  return (
    <div>
      <Paper>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    sx={{
                      whiteSpace: "nowrap",
                      width: "1%",
                      fontWeight: "bold",
                      color: "#ffffff",
                      backgroundColor: "#2B8056",
                    }}
                    align="center"
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows
                ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => (
                  <TableRow key={index}>
                    <TableCell>
                      <MoreOptionsMenu
                        onEdit={() => onEdit(row)}
                        onDelete={() => onDelete(row._id)}
                        hideDelete={false}
                      />
                    </TableCell>
                    <TableCell align="center">
                      {page * rowsPerPage + index + 1}
                    </TableCell>
                    <TableCell align="center">{row?.business_name}</TableCell>
                    <TableCell align="center" sx={{ whiteSpace: "nowrap" }}>
                      {row?.first_name}
                    </TableCell>
                    <TableCell align="center" sx={{ whiteSpace: "nowrap" }}>
                      {row?.last_name}
                    </TableCell>
                    <TableCell align="center" sx={{ whiteSpace: "nowrap" }}>
                      {row?.gender}
                    </TableCell>
                    <TableCell align="center" sx={{ whiteSpace: "nowrap" }}>
                      {row?.age}
                    </TableCell>
                    <TableCell align="center" sx={{ whiteSpace: "nowrap" }}>
                      {row?.email}
                    </TableCell>
                    <TableCell align="center" sx={{ whiteSpace: "nowrap" }}>
                      {row?.address}
                    </TableCell>
                    <TableCell align="center" sx={{ whiteSpace: "nowrap" }}>
                      {row?.phone_number}
                    </TableCell>
                    <TableCell align="center" sx={{ whiteSpace: "nowrap" }}>
                      {row?.state_of_origin}
                    </TableCell>
                    <TableCell align="center" sx={{ whiteSpace: "nowrap" }}>
                      {row?.skill}
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          component="div"
          count={rows?.length}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          rowsPerPageOptions={[5, 10, 25]}
        />
      </Paper>
      <EditModalForm
        open={isEditModalOpen}
        handleClose={() => setIsEditModalOpen(false)}
        rowData={selectedRowData}
        handleSave={handleEditAndSave}
        fields={columns}
        header="Edit Student Data"
      />
      <ConfirmationModal
        open={isConfirmationModalOpen}
        handleClose={() => setIsConfirmationModalOpen(false)}
        handleConfirm={handleConfirmDelete}
        message="Are you sure you want to delete this item?"
      />
    </div>
  );
};

export default TraineeDataTable;
